import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from './Header/Header'
import Footer from './Footer/Footer'
// import WebsiteDisclaimer from './HomeSubPage/WebsiteDisclaimer'

const Layout = () => {
    return (
        <>
            <Header />
            <main className='md:min-h-screen min-h-[60vh]'><Outlet /></main>
            {/* <WebsiteDisclaimer/> */}
            <Footer />
        </>
    )
}

export default Layout