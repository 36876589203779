import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackToTop from "../../Components/BackToTop/backToTop";
import ReactPaginate from "react-paginate";
import LongAd from "../../Components/ads/LongAd";
import Base_Url from "../../api/baseUrl";
import axios from "axios";
import LeftSideAd from "../../Components/ads/LeftSideAd";
import RightSideAd from "../../Components/ads/RightSideAd";

const CelebrityDetails = () => {
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [blogDetailsData, setBlogDetailsData] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const queryName = new URLSearchParams(location.search).get("id");
  const detailid = new URLSearchParams(location.search).get("detailid");
  useEffect(() => {
    const getAllItemsById = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}api/celebrity/${queryName}`
        );
        setBlogDetailsData(response?.data?.data);
      } catch (error) {}
    };
    getAllItemsById();
  }, [queryName]);

  useEffect(() => {
    const getDetailsById = async () => {
      try {
        const response = await axios.get(
          `${Base_Url}api/celebrity-blog/${detailid}`
        );
        setData(response?.data?.data);
      } catch (error) {}
    };
    getDetailsById();
  }, [detailid]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    if (blogDetailsData && detailid) {
      const pageIndex = blogDetailsData?.blogDetails?.findIndex(
        (x) => x.id === parseInt(detailid)
      );
      if (pageIndex !== -1) {
        setCurrentPage(pageIndex);
      }
    }
  }, [detailid, blogDetailsData]);
  const pageCount = Math.ceil((blogDetailsData?.blogDetails?.length || 0) / 1);
  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    if (blogDetailsData?.blogDetails?.[selected]?.id) {
      const newSubId = blogDetailsData?.blogDetails[selected]?.id;
      navigate(`?id=${queryName}&detailid=${newSubId}`);
    }
  };

  const reloadAds = () => {
    if (window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(function () {
        window.googletag.display("div-gpt-ad-1726468773337-0");
      });
    }
    if (window.googletag && window.googletag.pubads) {
      try {
        window.googletag.pubads().refresh();
      } catch (error) {
        console.error("Failed to refresh ads:", error);
      }
    }
  };

  useEffect(() => {
    reloadAds();
  }, [currentPage]);

  return (
    <>
      <LeftSideAd />
      <RightSideAd />
      <div className="px-5">
        <div className="container max-w-7xl mx-auto pt-[3rem] mb-[20px] max-[768px]:pt-[2rem]">
          <div className="flex border-2 p-2 rounded-lg flex-col lg:flex-row">
            <img src={data?.image} alt="" width={400} height={500} />
            <div className="flex justify-center flex-col px-[20px] gap-4 my-4 lg:my-0">
              <h1 className="text-[#000]  text-5xl leading-snug font-semibold text-[42px]">
                {data?.name}
              </h1>
              <p className="text-gray-600 text-lg">{data?.work}</p>
              <h1 className="text-[#000]  text-5xl leading-snug font-semibold text-[42px]">
                {data?.netWorth}
              </h1>
              <p>{data?.description}</p>
            </div>
          </div>
          <LongAd />
          <div className="flex lg:gap-4 flex-col lg:flex-row">
            <div className="my-5 p-4 border-2 lg:w-[50%]">
              <h2 className="text-xl font-semibold pb-4">Other Details</h2>
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: data?.other_details }}
              ></div>
            </div>

            <div className="my-5 p-4 border-2 lg:w-[50%]">
              <h2 className="text-xl font-semibold pb-6">Personal Stats</h2>
              <div className="flex flex-col gap-6">
                <div className="flex gap-4 border-b-2">
                  <p className="font-semibold">Age</p>
                  <p>{data?.age}</p>
                </div>
                <div className="flex gap-4 border-b-2">
                  <p className="font-semibold">Source of Wealth</p>
                  <p>{data?.source_of_wealth}</p>
                </div>
                <div className="flex gap-4 border-b-2">
                  <p className="font-semibold">Residence</p>
                  <p>{data?.residence}</p>
                </div>
                <div className="flex gap-4 border-b-2">
                  <p className="font-semibold">Citizenship</p>
                  <p>{data?.citizenship}</p>
                </div>
                <div className="flex gap-4 border-b-2">
                  <p className="font-semibold">Marital Status</p>
                  <p>{data?.marital_status}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="my-5 p-4 border-2">
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageClick}
              containerClassName={"pagination flex justify-center mt-4"}
              activeClassName={"active"}
              pageClassName={"page-item px-2"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              forcePage={currentPage}
            />
          </div>
        </div>
        <BackToTop />
      </div>
    </>
  );
};

export default CelebrityDetails;
