import React, { useEffect, useState } from "react";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import ReactPaginate from "react-paginate";
import AdResponsive02 from "../Components/ads/AdResponsive02";
import Modal from "../Components/Modal";
import { useNavigate } from "react-router-dom";
import { FaArrowRightLong } from "react-icons/fa6";
import BackToTop from "../Components/BackToTop/backToTop";
import LongAdCard from "../Components/ads/LongAdCard";

const BlogPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const limit = 6;
  const [blogData, setBlogData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const updateScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 640);
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);
    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const fetchBlogData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-blog?limit=${limit}&page=${page + 1}`
        );
        setLoading(false);
        setBlogData(response?.data?.data);
        setPageCount(response?.data?.totalPage);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog data:", error);
      }
    };
    fetchBlogData();
  }, [page]);
  
  useEffect(() => {
    const handleReturn = () => {
      const isReturning = localStorage.getItem("returningFromBlog");
      if (isReturning === "true") {
        localStorage.setItem("returningFromBlog", "false");
        setTimeout(() => {
          setIsModalOpen(true);
        }, 1500);
      }
    };
    handleReturn();
  }, [navigate]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const reloadAds = () => {
    if (window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(function () {
        window.googletag.display("div-gpt-ad-1726468021688-0");
      });
    }
    if (window.googletag && window.googletag.pubads) {
      try {
        window.googletag.pubads().refresh();
      } catch (error) {
        console.error("Failed to refresh ads:", error);
      }
    }
  };

  useEffect(() => {
    reloadAds();
  }, [page]);

  return (
    <div className="px-5">
      <div className="container max-w-7xl mx-auto pt-[3rem] mb-[20px] max-[768px]:pt-[2rem]">
        <div className="flex justify-center text-center">
          <div className="w-[65%] max-[1024px]:w-[90%]">
            <h1 className="text-[#000] font-bold md:text-[40px] text-[30px] mb-0">
              Recent Blog Post
            </h1>
          </div>
        </div>
        <div className="blog-div mt-[0.5rem] max-[768px]:mt-0">
          {loading ? (
            <div className="flex justify-center">
              <div className="loader my-5 !w-[40px]"></div>
            </div>
          ) : (
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 lg:px-0">
              {blogData?.map((blog, index) => (
                <React.Fragment key={index}>
                  <div
                    className="col-lg-4 col-md-6 col-sm-12 mt-4 h-full"
                    key={index}
                    onClick={() => {
                      let clickCount = localStorage.getItem("clickCount") || 0;
                      let click = Number(clickCount);
                      localStorage.setItem("clickCount", click + 1);
                    }}
                  >
                    <a
                      href={`/blog?blog=${blog?.slug}`}
                      className="no-underline"
                    >
                      <div
                        className="bg-[#fff] cursor-pointer p-3 rounded-lg border border-[#C8C8C8] h-full"
                        style={{ boxShadow: "0px 3.72px 3.72px 0px #00000040" }}
                      >
                        <div className="h-[240px] max-[768px]:h-[250px]">
                          <img
                            src={blog?.image}
                            alt="bg_img"
                            className="!h-full w-full object-cover rounded-lg"
                          />
                        </div>
                        <div
                          className="mt-4 px-2"
                          style={{
                            maxHeight: "calc(100% - 295px)",
                            overflowY: "clip",
                          }}
                        >
                          <h4 className="font-bold mt-3 text-[22px] max-[1024px]:text-[18px] line-clamp-2 leading-[30px]">
                            {blog?.title}
                          </h4>
                          <p
                            className="mt-3 lg:text-[18px] text-[16px] line-clamp-2"
                            dangerouslySetInnerHTML={{
                              __html: blog?.description,
                            }}
                          ></p>
                        </div>
                        <div className="flex justify-between items-center mt-4 px-2">
                          <p className="mb-0 mt-2 me-3">
                            {new Date(blog?.created_at).toLocaleDateString(
                              "en-US",
                              {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              }
                            )}
                          </p>
                          <div className="flex justify-center items-center h-12 w-12 rounded-full bg-[#F06939]">
                            <FaArrowRightLong className="text-[#fff]" />
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                  {index === 2 && (
                    <div className="lg:col-span-3 md:col-span-2 col-span-1 mt-4">
                      <LongAdCard />
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
      {!loading && blogData.length > 0 && (
        <div className="flex justify-center mt-4 my-3  max-[768px]:mt-0">
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={isSmallScreen ? 1 : 2}
            pageRangeDisplayed={isSmallScreen ? 1 : 3}
            onPageChange={(e) => {
              setPage(e.selected);
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={page}
          />
          <Modal isOpen={isModalOpen} onClose={handleCloseModal} />
        </div>
      )}
      {/* <AdResponsive02 /> */}
      <BackToTop />
    </div>
  );
};

export default BlogPage;
