import React, { useEffect } from "react";

const LongAdCard02 = () => {
  useEffect(() => {
    const loadGoogleAdsScript = () => {
      const script = document.createElement("script");
      script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      script.async = true;
      script.onload = initializeGoogleAds;
      document.head.appendChild(script);
    };

    const initializeGoogleAds = () => {
      if (window.googletag && typeof window.googletag === "object") {
        window.googletag.cmd.push(() => {
          // Define size mapping for responsive ads
          const mapping = window.googletag
            .sizeMapping()
            .addSize([1024, 768], [930, 180]) // Large screens (desktop)
            .addSize([768, 600], [728, 90]) // Medium screens (tablet)
            .addSize([320, 400], [320, 50]) // Small screens (mobile)
            .build();

          // Define the ad slot with the size mapping
          const adSlot = window.googletag
            .defineSlot(
              "/23055822891/Big_banner_1_930_180",
              [930, 180],
              "div-gpt-ad-1726569872287-0"
            )
            .defineSizeMapping(mapping)
            .addService(window.googletag.pubads());

          window.googletag.pubads().enableSingleRequest();
          window.googletag.enableServices();
          window.googletag.display("div-gpt-ad-1726569872287-0");
        });
      } else {
        console.error(
          "googletag is not defined. Google Ad Manager library might not be loaded."
        );
      }
    };

    loadGoogleAdsScript();
  }, []);

  return (
    <>
      <p style={{ textAlign: "center", fontSize: "10px", paddingTop: "20px" }}>
        ADVERTISEMENT
      </p>
      <div
        style={{ display: "flex", justifyContent: "center", padding: "2px" }}
      >
        <div
          id="div-gpt-ad-1726569872287-0"
          style={{
            minWidth: "320px",
            minHeight: "50px",
            margin: "0 auto",
            overflow: "hidden",
            backgroundColor: "#E9EAEC"
          }}
        ></div>
      </div>
      <p
        style={{ textAlign: "center", fontSize: "10px", paddingBottom: "20px" }}
      >
        ADVERTISEMENT
      </p>
    </>
  );
};

export default LongAdCard02;
