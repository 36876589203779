import React, { useState, useEffect } from "react";

const TopStickyAd = ({ isVisible, setIsVisible }) => {
  useEffect(() => {
    const loadGoogleAdsScript = () => {
      const script = document.createElement("script");
      script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      script.async = true;
      script.onload = initializeGoogleAds;
      document.head.appendChild(script);
    };

    const initializeGoogleAds = () => {
      if (window.googletag && typeof window.googletag === "object") {
        window.googletag.cmd.push(() => {
          // Create size mapping for different screen sizes
          const mapping = window.googletag
            .sizeMapping()
            .addSize([1024, 768], [728, 90]) // Large screens
            .addSize([768, 600], [728, 90]) // Medium screens
            .addSize([320, 400], [320, 50]) // Small screens
            .build();

          // Define the ad slot with the size mapping
          const adSlot = window.googletag
            .defineSlot(
              "/23055822891/mill_sticky",
              [[728, 90]],
              "div-gpt-ad-1726053586752-0"
            )
            .defineSizeMapping(mapping)
            .addService(window.googletag.pubads());

          window.googletag.pubads().enableSingleRequest();
          window.googletag.enableServices();
          window.googletag.display("div-gpt-ad-1726053586752-0");
        });
      } else {
        console.error(
          "googletag is not defined. Google Ad Manager library might not be loaded."
        );
      }
    };

    loadGoogleAdsScript();
  }, []);

  const hideAd = () => {
    setIsVisible(false);
  };

  return isVisible ? (
    <div
      style={{
        marginBottom: "30px",
        position: "fixed",
        top: "0",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        backgroundColor: "white",
        borderTop: "1px solid #ddd",
        padding: "10px 0",
      }}
    >
      <button
        onClick={hideAd}
        style={{
          position: "absolute",
          left: "10px",
          top: "0px",
          cursor: "pointer",
          color: "#000",
          border: "none",
          borderRadius: "3px",
          zIndex: 1001,
        }}
      >
        X
      </button>
      <div
        id="div-gpt-ad-1726053586752-0"
        style={{
          minWidth: "320px",
          minHeight: "50px",
          margin: "0 10px",
          overflow: "hidden",
          backgroundColor: "#E9EAEC"
        }}
      ></div>
    </div>
  ) : null;
};

export default TopStickyAd;
