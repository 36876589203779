import React from 'react'

const FinanceSteps = ({stepData}) => {
  return (
    <div className='p-6 pb-10 h-full rounded-lg'>
    <div className='flex mb-8'>
        <h3 className='font-semibold text-[24px] border-b border-[#000]'>Roadmap to Getting Rich</h3>
    </div>
    {stepData.slice(3)?.map((data, index) => {
        return <div key={index}>
            <a href={`/success-step?${data?.link}`} className='no-underline' >
                <div className='grid grid-cols-3 mt-7 gap-4 cursor-pointer items-center' >
                    <div className=''>
                    <h5 className='flex justify-center text-[#6c757d] text-[45px]'>{data?.icon}</h5>
                    </div>
                    <div className='col-span-2 '>
                        <h4 className='line-clamp-2 tracking-[0.3px] font-medium hover:text-blue-800 text-[17px]'>{data?.title}</h4>
                    </div>
                </div>
            </a>
        </div>
    })}


</div>
  )
}

export default FinanceSteps