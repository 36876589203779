import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BackToTop from "../Components/BackToTop/backToTop";
import LongAdCard from "../Components/ads/LongAdCard";
import LongAd from "../Components/ads/LongAd";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";

const CelebrityData = () => {
  const [celebrityData,setCelebrityData] = useState()
  const [celebrities,setcelebrities] = useState()
  const [loading,setLoading] = useState()

  const location = useLocation();
  const queryName = new URLSearchParams(location.search).get("id");
 
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  useEffect(() => {
    const getAllItemsById = async () => {
      try {
        const response = await axios.get(`${Base_Url}api/celebrity/${queryName}`);
        setCelebrityData(response?.data?.data);
      } catch (error) {}
    };
    getAllItemsById();
  }, [queryName]);

  useEffect(() => {
    const fetchOtherCelebrityData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-celebrity?page=1&limit=50`
        );
        setLoading(false);
        setcelebrities(response?.data?.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog data:", error);
      }
    };
    fetchOtherCelebrityData();
  }, []);

  return (
    <div>
      <LeftSideAd />
      <RightSideAd />
      <div className="px-4">
        <div className="container max-w-7xl mx-auto pt-[2rem] lg:px-0">
          <h1 className="flex justify-center text-[#000] md:text-[33px] font-semibold text-[20px] text-center md:mb-5 mb-3">
            {celebrityData?.title}
          </h1>
          <div className="">
            <table className="min-w-full border-2 border-gray-400  md:table ">
              <thead className=" table-header-group">
                <tr className="border-2 border-black md:table-row">
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium sm:text-[18px] text-[11px]">
                    ID
                  </th>
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium sm:text-[18px] text-[11px]">
                    Image
                  </th>
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium sm:text-[18px] text-[11px]">
                    Name
                  </th>
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium sm:text-[18px] text-[11px]">
                    Age
                  </th>
                  <th className="bg-[#1A1A1A] text-white text-start md:p-3 p-2 font-medium sm:text-[18px] text-[11px]">
                    Net Worth
                  </th>
                </tr>
              </thead>
              <tbody className="table-row-group">
                {celebrityData?.blogDetails?.map((item, index) => (
                  <tr
                    key={item?.id}
                    className="bg-white border-b-2 border-gray-400 cursor-pointer"
                    onClick={() =>
                      (window.location.href = `/celebrities-details?id=${queryName}&detailid=${item?.id}`)
                    }
                  >
                    <td className="md:p-3 px-1 py-2 text-[#F06939] font-bold md:text-[20px] text-[13px]">
                      #{index + 1}
                    </td>

                    <td className="md:p-2 md:px-3 px-1 py-2 text-gray-700">
                      <div className="md:h-[110px] md:w-[125px] sm:h-[40px] sm:w-[50px] h-[25px] w-[35px]">
                        <img
                          src={item?.image}
                          alt=""
                          className="h-full w-full object-cover object-top"
                        />
                      </div>
                    </td>
                    <td className="md:p-3 px-1 py-2 text-gray-700 md:text-[20px] sm:text-[13px] text-[10px]">
                      {item.name}
                    </td>

                    <td className="md:p-3 px-1 py-2 text-gray-700 md:text-[20px] sm:text-[13px] text-[10px]">
                      {item.age}
                    </td>
                    <td className="md:p-3 px-1 py-2 text-gray-700 md:text-[20px] sm:text-[13px] text-[10px]">
                      {item.net_worth}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <LongAd />
          <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:mt-16 mt-10 gap-9">
            {celebrities
              ?.filter((cel) => cel?.id !== parseInt(queryName))
              ?.map((celebrity, index) => (
                <React.Fragment key={index}>
                 <div className="cursor-pointer md:mt-4">
                  <a href={`/celebrities-data?id=${celebrity?.id}`}>
                    <div className="w-full h-[350px] max-[500px]:h-[300px]">
                      <img src={celebrity?.image} alt="" className="w-full h-full" />
                    </div>
                    <h3 className="pt-2 text-[17px] font-semibold line-clamp-2">
                      {celebrity?.title}
                    </h3>
                  </a>
                </div>
                  {index === 5 && (
                    <div className="lg:col-span-3 sm:col-span-2 col-span-1 mt-4">
                      <LongAdCard />
                    </div>
                  )}
                </React.Fragment>
              ))}
          </div>
        </div>
        <BackToTop />
      </div>
    </div>
  );
};

export default CelebrityData;
