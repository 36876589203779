import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import * as Yup from "yup"
import axios from "axios"
import Base_Url from '../../api/baseUrl'
import { FaTimes } from 'react-icons/fa'

const IdeaForm = ({ isOpen, onClose }) => {
  const [loading, setLoading] = useState(false)
  // useEffect(() => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: 'smooth'
  //   })
  // }, [])
  const uploadImage = async (e) => {
    const files = e.target.files;
    setFieldValue("images", files);
  }
  const uploadfiles = async (event) => {
    const allowedFileTypes = ['application/pdf', 'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation'];
    const file = event.target.files[0];
    if (file && allowedFileTypes.includes(file.type)) {
      setFieldValue("files", file);
    } else {
      alert('Please select a valid PDF or PowerPoint file.');
    }
  };
  const initialValues = {
    topic: "",
    description: "",
    files: [],
    images: [],
    name: "",
    email: ""
  }
  const validationSchema = Yup.object().shape({
    topic: Yup.string().required("Topic Name is required"),
    description: Yup.string().required("Description is Required"),
    name: Yup.string().required("Name is Required"),
    email: Yup.string().email('Invalid email').required('Email is Required')
  })
  const { values, errors, handleChange, handleSubmit, handleBlur, touched, setFieldValue } = useFormik({
    initialValues, validationSchema, onSubmit: async () => {
      const formData = new FormData()
      formData.append("topic", values.topic)
      formData.append("description", values?.description)
      formData.append('files', values?.files);
      for (let i = 0; i < values?.images?.length; i++) {
        formData.append("images", values?.images[i]);
      }
      formData.append("name", values?.name)
      formData.append("email", values?.email)
      setLoading(true)
      try {
        const res = await axios.post(`${Base_Url}api/ideabillionaire`, formData)
        setLoading(false)
        if (res) {
          window.location.reload("/")
        }
      } catch (error) {
        setLoading(false)

      }
    }
  })

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    <div>
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white md:p-8 p-4 rounded-lg w-full max-w-xl relative max-h-[95vh] overflow-y-auto m-2">
          <button
            className="absolute md:top-7 top-5 right-5 text-gray-600 hover:text-gray-900"
            onClick={onClose}
          >
            <FaTimes className="w-6 h-6" />
          </button>
          <form onSubmit={handleSubmit} className=' rounded-md bg-[#fefefe] max-[1024px]:w-full cc]' style={{
            padding: "10px",
          }}>
            <h1 className='flex justify-center md:text-[30px] text-[23px] mb-3 font-semibold max-[768px]:mt-4 text-center text-[#000]' style={{ fontFamily: "math, cursive" }}>Share Your Investment Idea</h1>
            <div className="form-group mb-4 ">
              <label className='text-[#000] label-name'>Topic Name<span style={{ color: "red" }}>*</span></label>
              <input
                name="topic"
                type="text"
                className='form-input w-full p-2 rounded-sm bg-gray-200'
                errors={errors.topic}
                value={values.topic}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {touched?.topic && errors?.topic && <h6 className='error text-[#ff0000] font-semibold'>{errors?.topic}</h6>}
            </div>
            <div className="form-group mb-3">
              <label className='text-[#000] label-name '>Description<span style={{ color: "red" }}>*</span></label>
              <textarea
                name="description"
                type="text"
                className='form-input w-full p-2 rounded-sm bg-gray-200'
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}

              />
              {touched?.description && errors?.description && <h6 className='error text-[#ff0000] font-semibold'>{errors?.description}</h6>}
            </div>
            <div className="form-group mb-4">
              <div className=''>
                <label className='text-[#000] label-name me-3 '>Upload Files (pdf,ppt)<span style={{ color: "red" }}>*</span></label>
                <input
                  name="files"
                  type="file"
                  multiple
                  accept='.pdf,.ppt,.pptx'
                  className='form-input w-full p-2 rounded-sm bg-gray-200 text-[#000]'
                  onChange={uploadfiles}

                />
              </div>
            </div>
            <div className="form-group mb-4">
              <div className=''>
                <label className='text-[#000] label-name me-3 '>Upload Image<span style={{ color: "red" }}>*</span></label>
                <input
                  name="files"
                  type="file"
                  multiple
                  className='form-input w-full p-2 rounded-sm bg-gray-200 text-[#000]'
                  onChange={uploadImage}

                />
              </div>

            </div>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-4'>
                <div className="form-group mb-4">
                  <label className='text-[#000] label-name '>Your Name<span style={{ color: "red" }}>*</span></label>
                  <input
                    name="name"
                    type="text"
                    className='form-input w-full p-2 rounded-sm bg-gray-200'
                    errors={errors.name}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}

                  />
                  {touched?.name && errors?.name && <h6 className='error text-[#ff0000] font-semibold'>{errors?.name}</h6>}

                </div>
                <div className="form-group mb-4">
                  <label className='text-[#000] label-name '>Your Email<span style={{ color: "red" }}>*</span></label>
                  <input
                    name="email"
                    type="email"
                    className='form-input w-full p-2 rounded-sm bg-gray-200'
                    errors={errors?.email}
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}

                  />
                  {touched?.email && errors?.email && <h6 className='error text-[#ff0000] font-semibold'>{errors?.email}</h6>}
                </div>
            </div>
            <div className='flex justify-center'>
              <button className='submit-button bg-[#F06939] px-20 py-2 text-[#fff] border-none font-medium rounded-lg ' type="submit">{loading ? <div className='loader'></div> : "Submit"}</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default IdeaForm