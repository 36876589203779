import React from 'react'

const Banner = () => {
    return (
        <div>
            <a href='/blogs'>
            <div className='bg-cover h-[40vh] bg-center w-full banner-main-div max-[768px]:h-[40vh]'>
                <div className='bg-[#0003] h-[40vh] text-white flex justify-center items-center flex-col max-[768px]:h-[40vh]'>
                    <h1 className='lg:text-[55px] font-bold md:text-[45px] text-[30px] px-2 text-center lg:leading-[78px] md:leading-[55px] leading-[40px]'>The Ultimate Guide to Becoming a Rich</h1>
                    <p className='w-[60%] text-center mt-2 font-normal leading-snug lg:text-[21px] md:text-[20px] max-[1024px]:w-[70%] max-[768px]:w-[90%] text-[16px]'>Unlocking Wealth with Insider Strategies, Wealth-Building Tips, and Success Stories from the Financial Elite.</p>
                </div>
            </div>
            </a>
        </div>
    )
}

export default Banner