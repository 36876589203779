import React from 'react'
const IdeaView = () => {
  return (
    <div className='px-5'>
      <a href="/view-financial-idea" className='no-underline'>
        <div className='container max-w-7xl mx-auto p-7 bg-[#F4F4F4] mt-14 my-8'>
          <div className='grid lg:grid-cols-5 grid-cols-1 items-center'>
            <div className='md:px-5 col-span-3'>
              <div className='flex justify-center'>
                <p className=' text-[#000] font-semibold text-[33px] max-[768px]:text-[24px] max-[768px]:pt-1 lg:text-start text-center' >You Have Money but No business idea, we can help you</p>
              </div>
              <p className='text-[18px] mt-3 max-[1024px]:px-3 max-[768px]:text-[16px] hidden lg:flex'>Have the money but need direction? At Rich, we transform your financial assets into powerful ventures and impactful opportunities. Our experts offer personalized advice, strategic investment planning, and access to exclusive deals. Let us help you maximize your wealth's potential and achieve visionary success. Wealth meets wisdom with rich people.</p>
              <div className='hidden lg:flex'>
                <button className='bg-[#F06939] px-14 py-3 mt-4 text-white rounded-lg font-semibold'>Financial Idea</button>
              </div>
            </div>
            <div className='col-span-2'>
              <div className='flex justify-center my-5 lg:my-0'>
                <img src="https://billionaireweb.s3.eu-north-1.amazonaws.com/share_idea.webp" alt='your_idea' className='border-2 border-black' />
              </div>
              <p className='text-[18px] mt-3  max-[768px]:text-[15px] flex lg:hidden'>Have the money but need direction? At Rich, we transform your financial assets into powerful ventures and impactful opportunities. Our experts offer personalized advice, strategic investment planning, and access to exclusive deals. Let us help you maximize your wealth's potential and achieve visionary success. Wealth meets wisdom with rich people.</p>
              <div className='flex lg:hidden justify-center'>
                <button className='bg-[#F06939] px-14 py-3 mt-4 text-white rounded-lg'>Financial Idea</button>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  )
}

export default IdeaView