import React from "react";
import AdResponsive05 from "../ads/AdResponsive05";
import LongAd from "../ads/LongAd";

const CelebrityEvents = ({celebrityData}) => {
  return (
    <div>
      <div className="flex flex-col gap-4 my-5">
        {celebrityData?.slice(0, 2).map((data, index) => {
          return (
            <div key={index}>
              {index === 1 && <LongAd />}
              <div
                className="cursor-pointer"
                onClick={() =>
                  (window.location.href = `/single-blog?blogId=${data.id}`)
                }
              >
                <h1 className="text-xl md:text-4xl font-bold pb-5 text-center hidden md:block">
                  {data?.title}
                </h1>
                <img
                  src={data?.image}
                  alt=""
                  className="w-[500px] h-[350px] md:w-[100%] md:h-full"
                />
                <h1 className="text-xl md:text-4xl font-bold pt-2 md:hidden">
                  {data?.title}
                </h1>
              </div>
            </div>
          );
        })}
      </div>
      <AdResponsive05 />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4 lg:px-[200px] md:mt-28">
        {celebrityData?.slice(2)?.map((data, index) => {
          return (
            <div
              key={index}
              className="cursor-pointer"
              onClick={() =>
                (window.location.href = `/single-blog?blogId=${data.id}`)
              }
            >
              <img src={data.image} alt="" width={500} height={500} />
              <h1 className="text-xl font-bold pt-2">{data?.title}</h1>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CelebrityEvents;
