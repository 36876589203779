import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import BackToTop from '../BackToTop/backToTop';

const SuccessAllStep = () => {
    const location = useLocation();
    const stepName = location.search?.split("?")
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [])
    const stepData = [
        {
            title: "Stay away from debt",
            link: "stay-away-from-debt",
            description: [
                "The best way to build your savings is to start saving early in life. Doing so allows you to take advantage of the incredible power of compounding over the years.",
                "Say you're 20 years old. If you contribute $6,000 to an individual retirement account (IRA) every year ($500 a month) for 40 years, your total investment would be $240,000.",
                "But because of the power of compounding interest, your nest egg would be worth much more. Assuming a 7% return, it would total more than $1.37 million.",
                "You'd be rich by age 57, just by saving $500 a month. Granted, you'd rather be rich by age 30. If that's your goal, try to put more money away each month. And consider the other steps below.",
                "There’s this idea floating around our culture that you have to take big risks to become wealthy. People think you have to take out business loans and open up lines of credit to get ahead, and they justify it by calling it “leverage”—which is just a fancy word for borrowing money and getting into debt.",
                "But here’s the thing: Debt is quicksand to your financial dreams. Every time you buy something on credit or take out a loan, you dig a deeper hole for yourself to climb out of. That money (plus interest) you’re sending to lenders is money you could be putting toward your future!",
                "Folks who went on to become rich figured this out a long time ago. They didn’t want their most valuable wealth-building tool (their income) tied up in stupid payments every month.",
                "Here are the cold, hard facts: 9 out of 10 rich people have never taken out a business loan, and 73% of rich people have never carried a credit card balance in their entire life. They’ll be the first to tell you that one of the main ways to reach the rich mark is to avoid debt like the plague.",
                "The bottom line is this: If you want to become rich, avoid debt at all costs. And if you already have some, get rid of it and pay it off (Baby Step 2) as soon as possible. The only “good debt” is no debt!"
            ]
        },
        {
            title: "Invest Early And Consistently",
            link: "invest-early",
            description: [
                "Starting at age 25, investing $300 a month with an 11% annual return could potentially make you rich by age 57. This showcases the incredible power of compound interest over time. If you continue investing until age 67, you could be sitting on a comfortable $3.2 million nest egg. This amount, generated from just $300 a month, illustrates how even modest contributions can lead to significant wealth when started early.",
                "Starting at 25: Investing $300 a month could grow to approximately $1.97 million by age 65, and if you extend your investment horizon to age 72, that same $300 a month could compound into over $3.7 million. The key takeaway here is that the longer you stay invested, the more exponential your growth becomes, thanks to the compound interest that keeps building upon itself.",
                "Starting at 35: If you begin investing $300 a month at age 35, with the same 11% annual return, your investment could grow to around $683,000 by age 65. This is still a significant amount, but it pales in comparison to starting 10 years earlier at age 25. By delaying your start, you could miss out on an additional $1.3 million by retirement, underscoring the importance of starting as soon as possible.",
                "The earlier you start investing, the more you benefit from the power of compound interest. A 10-year head start can more than double your wealth by the time you reach retirement age. This early start not only maximizes your returns but also gives you greater financial security and flexibility as you age.",
                "Begin investing as soon as you’re debt-free, excluding your mortgage, and have a fully funded emergency fund in place. These financial safeguards are crucial because they allow you to invest without the risk of having to sell your investments during a market downturn to cover unexpected expenses. No exceptions—this is the foundation of a sound financial strategy.",
                "Starting at 45: If you start investing $500 a month at age 45, with an 11% annual return, you could still grow your investments to around $500,000 by age 65. Even with a shorter time frame, consistent investing can yield impressive results, proving that it's never too late to start. While you might need to contribute more each month compared to starting at a younger age, the potential returns are still substantial.",
                "Starting at 55: Even if you begin at age 55, investing $1,000 a month could still accumulate over $200,000 by the time you reach 65. This amount, while lower than starting earlier, can still significantly enhance your retirement security. It demonstrates that starting later still offers meaningful financial benefits, and it’s better to start late than never.",
                "Whether you start at 25, 45, or 55, consistent investing is the key to building and securing your financial future. The sooner you start, the greater the potential rewards. Regular, disciplined investing, no matter the amount, sets the foundation for a solid financial future, allowing you to enjoy financial freedom and peace of mind in your later years."
            ]
        },
        {
            title: "Save 15% of Your Income",
            link: "save-income",
            description: [
                "The personal savings rate is the percentage of income left over after people spend money and pay taxes. As of September 2023, the average savings rate for Americans was just 3.4%, according to the Bureau of Economic Analysis (BEA). While it’s encouraging that people are saving, experts warn that this rate is far too low for a comfortable retirement, let alone for those aiming to become rich.",
                "So, exactly how much should you save? Although there’s no one-size-fits-all answer, most financial planners agree that you should save at least 15% of your annual gross income for retirement, depending on your age. This might seem ambitious, but it’s not necessarily unattainable, especially when you consider potential employer contributions. For example, if your employer matches contributions of up to 6% of your salary in your 401(k) plan, you only need to save 9% on your own to reach that 15% target.",
                "If you’ve already started investing (such as in a 401(k) or Roth IRA), that’s a great step! When saving for retirement, the goal is clear: aim to save 15% of your income in tax-advantaged retirement accounts. Not 5%, not 10%, but 15%. Why? Because the amount you save and invest is just as crucial as the act of investing itself. Research shows that it took Baby Steps Rich people, who consistently invested 15% of their income toward retirement, about 20 years or less to achieve rich status from the start of their journey.",
                "Consider this example: The median household income in America is around $68,000. If you invest 15% of that income toward retirement, that’s $10,200 a year or approximately $850 a month. Over 30 years, assuming an 11% rate of return, this could grow to around $2.3 million. And that’s without considering any employer match or salary increases, which would likely boost your savings even more.",
                "Our research found that 70% of rich people saved more than 10% of their income throughout their working years. This consistent and disciplined saving was key to their financial success. They didn’t just save sporadically; they saved consistently and in significant amounts. How were they able to save so much? This is where smart financial habits and strategic planning come into play, allowing them to prioritize their future financial security.",
                "Saving 15% of your income might seem like a daunting task at first, but it’s achievable with careful planning and discipline. Start by creating a budget that tracks your income and expenses, and identify areas where you can cut back to free up more money for savings. Automating your savings can also make it easier to stay on track—set up automatic transfers to your retirement accounts so that the money is invested before you even have a chance to spend it.",
                "Remember, the earlier you start saving 15% of your income, the more time your money has to grow, thanks to compound interest. Even if you can’t reach the full 15% right away, start with whatever percentage you can and gradually increase it over time. Every little bit helps, and the sooner you start, the better off you’ll be.",
                "Finally, keep in mind that saving for retirement is a long-term commitment. Life can be unpredictable, and there may be times when saving 15% seems impossible due to unexpected expenses or changes in income. However, staying focused on your goal and making saving a priority whenever possible will pay off in the long run, helping you achieve financial independence and a secure retirement."
            ]
        },
        {
            title: "Make More Money",
            link: "make-more-money",
            description: [
                "Granted, this may be easier said than done. But if you don't make enough to stash 15% of your income, it will be impossible to grow rich. And even if you do make that much, having a larger salary will help you save more for the future.",
                "The good news is that there are plenty of ways to boost your earnings. For example, you could:",
                "• Ask for a raise at work.",
                "• Apply for a promotion.",
                "• Switch to a higher-paying job.",
                "• Start a side hustle.",
                "• Acquire new skills or certifications to increase your marketability.",
                "Once you've established a way to earn more, funnel the extra money into your savings and investments. Every bit helps!",
                "Whether you choose to focus on career advancement, starting a business, or investing in real estate, increasing your income is crucial for wealth building.",
                "However, it’s important to note that while making more money is essential, how you manage that money is equally vital. Many people increase their income but also increase their spending, a phenomenon known as lifestyle inflation. To become rich, you must avoid lifestyle inflation and instead focus on saving and investing your additional income.",
                "Ultimately, building wealth is a combination of increasing your earnings and controlling your spending. The more money you can save and invest, the faster you’ll be able to achieve your financial goals.",
                "So, whether you're earning $30,000 a year or $300,000 a year, the principles of wealth building remain the same: live below your means, save and invest consistently, and focus on increasing your income whenever possible.",
                "In summary, to grow rich, focus on boosting your income while managing your expenses wisely. This will enable you to save more, invest more, and ultimately achieve financial independence."
            ]
        },
        {
            title: "Cut Unnecessary Expenses",
            link: "cut-unnecessary-expenses",
            description: [
                "Cutting unnecessary expenses is a vital step for anyone aspiring to become a millionaire. While increasing your income is important, managing and minimizing your outflow is equally crucial. Many people have hidden expenses that drain their finances without adding significant value to their lives. These can include small, recurring charges or sporadic, impulsive purchases that quickly add up. The first step in addressing these issues is to track every expense meticulously. By recording your daily spending using budgeting apps or a spreadsheet, you gain a clear view of where your money is going. This clarity is essential for identifying and addressing areas where you might be overspending.",
                "Once you have a clear picture of your spending habits, it's time to create a realistic budget. One popular method is the 50/30/20 rule: allocate 50% of your income to needs (such as rent, groceries, and utilities), 30% to wants (such as dining out and entertainment), and 20% to savings and debt repayment. This method offers a balanced approach to budgeting but can be adjusted according to your financial goals. For those aiming for early financial independence or millionaire status, you might consider tightening your budget further. This could involve reducing discretionary spending to allocate more towards savings and investments, thus accelerating your path to wealth accumulation.",
                "Another area to scrutinize is subscriptions and recurring expenses. In today’s digital world, it’s easy to accumulate multiple subscriptions for streaming services, software, gyms, and more. These small, recurring payments can add up to a significant amount over time. Take the time to review all your subscriptions and cancel those you don’t use regularly or that are not essential. Additionally, consider sharing subscriptions with family or friends to split costs. Opting for annual payments instead of monthly ones can also provide discounts, further reducing your overall expenses.",
                "Dining out and takeout represent another major expense where costs can spiral out of control. While enjoying a meal out occasionally is fine, making it a frequent habit can significantly impact your finances. Preparing meals at home is not only healthier but also far cheaper. By planning your meals, shopping with a list, and cooking in batches, you can save both time and money. This simple yet effective change can lead to substantial savings over time, which can then be redirected toward your savings or investment accounts, enhancing your financial stability and growth potential.",
                "Beyond these specific categories, it's important to maintain a broader mindset of frugality and mindful spending. This involves consistently seeking ways to cut costs and avoid unnecessary expenditures in all areas of life. Evaluate your spending habits regularly and look for opportunities to save, whether through negotiating bills, finding cheaper alternatives, or eliminating wasteful spending. Adopting this mindset helps reinforce the discipline needed to stick to your budget and make informed financial decisions.",
                "By cutting unnecessary expenses and living below your means, you free up more money to invest and grow your wealth. Investing consistently, even in small amounts, can lead to substantial growth over time, thanks to the power of compound interest. The discipline of managing your spending effectively today will pay off significantly in the future. This approach not only helps you accumulate wealth more quickly but also ensures that you are making the most of your financial resources, thereby bringing you closer to achieving millionaire status.",
                "Ultimately, the process of cutting unnecessary expenses is about aligning your spending with your long-term financial goals. It requires a combination of awareness, discipline, and strategic planning. By continuously evaluating and adjusting your expenses, you create a solid foundation for building wealth. This ongoing effort will help you stay on track with your financial plan, leading to greater savings, more effective investments, and a clearer path to achieving the financial independence and success you desire."
            ]
            
        },
        {
            title: "Get Help If You Need It",
            link: "get-help",
            description: [
                "Seeking help when you need it is a crucial step toward becoming a millionaire. While personal discipline and financial literacy are essential, the journey to wealth is rarely achieved in isolation. Successful individuals understand the importance of leveraging the expertise of professionals who can provide guidance on complex financial decisions. When faced with uncertainty about managing your finances, investing, or planning for taxes, consulting with a financial advisor or certified financial planner (CFP) can be invaluable. These experts can help you create a personalized financial plan that aligns with your unique goals and risk tolerance, avoiding common mistakes that could hinder your path to becoming a millionaire.",
                "Financial advisors, especially those who are fiduciaries, are required to act in your best interest and can offer advice that goes beyond basic budgeting. They help in diversifying your investment portfolio, recommending tax-efficient strategies, and guiding you through market volatility. A well-structured financial plan considers both short-term and long-term goals, helping you to balance immediate needs with future aspirations. If you're serious about growing your wealth, the right financial advisor can help you optimize your investments, manage risk effectively, and ensure you are taking advantage of all available opportunities for financial growth.",
                "In addition to professional advice, it's also essential to build a network of like-minded individuals who are focused on wealth-building. Networking with peers who share similar financial goals can provide motivation, accountability, and valuable insights. Consider joining investment clubs, attending seminars, or participating in online financial communities where you can learn from others' experiences and mistakes. Engaging with people who have successfully achieved financial independence can give you new perspectives on strategies that work, as well as innovative ideas to accelerate your path to becoming a millionaire.",
                "Finding a mentor who has already navigated the road to financial success can be especially beneficial. A mentor can offer practical advice based on real-world experience, helping you avoid common pitfalls and understand the nuances of building wealth. They can provide guidance on everything from choosing the right investment vehicles to managing debt, optimizing taxes, or even starting and scaling a business. Learning from someone who has been in your shoes can give you a significant advantage and a clearer roadmap to follow in your journey to financial independence.",
                "Continuous learning should also be a key component of your wealth-building strategy. The financial landscape is always evolving, and staying updated on the latest trends, tools, and strategies is vital for long-term success. Invest time in reading books by financial experts, subscribing to relevant podcasts, taking online courses, and attending workshops. These resources can deepen your understanding of topics like investing, real estate, entrepreneurship, and retirement planning, helping you make more informed and confident decisions. The more you learn, the better equipped you'll be to navigate the complexities of financial growth.",
                "Remember, becoming a millionaire is not just about saving and investing wisely; it’s also about building a robust support system that includes knowledgeable professionals, mentors, and peers. Don't view seeking help as a weakness; see it as a strategic move to ensure you are making the best decisions for your financial future. By actively seeking out resources and building a network of support, you create a safety net that can help you overcome challenges, stay motivated, and keep you focused on your financial goals.",
                "Finally, keep in mind that the journey to becoming a millionaire is a marathon, not a sprint. Life can be unpredictable, and there will be times when even the best-laid plans face unexpected hurdles. During these moments, having access to the right support—whether it’s professional advisors, mentors, or a community of like-minded individuals—can make all the difference. Stay committed to your learning, remain open to guidance, and be willing to adapt your strategies as needed. With persistence, smart planning, and the right help, you can steadily progress toward achieving financial freedom and the millionaire status you desire."
            ] 
        },
        {
            title: "Goal Front And Center",
            link: "goal-front-and-center",
            description: [
                "Keeping your goals front and center is a crucial step in becoming a millionaire. The journey to financial success requires clarity, focus, and a strong commitment to your objectives. Without a clear goal, it’s easy to get sidetracked by day-to-day distractions and lose sight of your bigger financial picture. To avoid this, start by defining what becoming a millionaire means to you. Is it about reaching a specific net worth, achieving financial freedom, or creating generational wealth? Knowing your 'why' will provide the motivation you need to stay on track and make disciplined decisions that align with your goals.",
                "Once you have clarity on your financial goal, break it down into smaller, actionable steps. Instead of focusing solely on the end result, set short-term and mid-term milestones that serve as stepping stones to your ultimate objective. For instance, if your goal is to save a million dollars in 20 years, break it down to how much you need to save or invest monthly or annually to reach that target. These smaller, measurable goals will not only make the process feel less overwhelming but will also provide a sense of achievement as you progress. Celebrating these small wins will keep you motivated and reinforce the behaviors that are driving you towards your larger goal.",
                "Visualization is a powerful tool that can help keep your goals front and center. Create a vision board, use financial planning apps, or even write down your goals daily to keep them at the forefront of your mind. Seeing your goals regularly can help maintain focus and remind you why you’re working hard to achieve them. Some people find it helpful to place visual reminders, such as pictures of their dream house, a vacation destination, or even a retirement fund target, in places where they can see them frequently. These visual cues can serve as a constant reminder of what you’re working towards and help reinforce your commitment.",
                "Additionally, incorporate your financial goals into your daily routines. Make it a habit to review your progress regularly, whether it's weekly, monthly, or quarterly. This can involve reviewing your savings, tracking your investment growth, or analyzing your spending habits to ensure they align with your goals. Regular check-ins will help you stay accountable and allow you to make necessary adjustments if you find yourself drifting away from your financial objectives. It’s also an opportunity to revisit your goals and ensure they remain relevant and realistic based on any changes in your personal or financial situation.",
                "It is equally important to communicate your goals to those who are part of your financial journey, such as your partner, family, or even close friends. When the people around you are aware of your goals, they can offer support, encouragement, and even hold you accountable. Moreover, having open conversations about your financial aspirations can lead to valuable advice or resources that you may not have considered. Surrounding yourself with a network that understands and supports your financial goals can be incredibly empowering and keep you on the right path.",
                "Sometimes, life will throw unexpected challenges that can cause you to lose focus on your goals. During such times, having a solid 'why' behind your goal will help you stay the course. Reflect on your reasons for wanting to become a millionaire—whether it's for financial security, freedom, family, or philanthropy. These deep-rooted motivations will provide the resilience needed to navigate obstacles and keep pushing forward. Remember, the path to becoming a millionaire is rarely linear, and staying adaptable yet focused is key to overcoming setbacks.",
                "Finally, keeping your goals front and center is not just about setting them but also about living them. Align your daily actions, decisions, and habits with your financial aspirations. From mindful spending and regular investing to continuous learning and seeking opportunities for growth, every step should be a reflection of your commitment to achieving millionaire status. By consistently prioritizing your financial goals, you create a clear roadmap to follow, increasing the likelihood of reaching your destination and achieving the financial success you desire."
            ]
            
        },
        {
            title: "Investment Professional",
            link: "investment-professional",
            description: [
                "Engaging an investment professional is a smart move for anyone serious about becoming a millionaire. While self-education is crucial, the world of investing is complex and ever-evolving, and having a knowledgeable guide can make a significant difference in your financial journey. Investment professionals, such as financial advisors, wealth managers, or certified financial planners (CFPs), bring a level of expertise and experience that can help you navigate the intricacies of the market. They provide tailored advice based on your risk tolerance, financial goals, and time horizon, helping you make informed decisions that align with your path to wealth.",
                "One of the key benefits of working with an investment professional is the ability to create a customized investment strategy. Rather than following generic advice, a professional will assess your unique financial situation, including your income, expenses, debts, and existing assets, to design a plan that is specifically suited to your needs. They can help you diversify your portfolio to balance risk and reward, ensuring that your investments are well-positioned to grow over time. This personalized approach can significantly enhance your ability to build wealth consistently and sustainably.",
                "Another advantage of consulting with an investment professional is their ability to help you stay disciplined and avoid emotional decision-making. In times of market volatility, it can be tempting to make hasty decisions, such as selling off investments during a downturn. However, these impulsive reactions can often lead to losses or missed opportunities. A seasoned advisor can provide perspective, reminding you of your long-term goals and helping you stay the course. They can also assist in rebalancing your portfolio periodically to maintain the desired level of risk, keeping you on track toward becoming a millionaire.",
                "Investment professionals are also well-versed in tax-efficient investing strategies, which can save you a considerable amount of money over time. They understand the nuances of tax laws and can guide you on how to optimize your investment returns by minimizing tax liabilities. For example, they can advise on the most tax-efficient accounts to invest in, such as Roth IRAs, 401(k)s, or Health Savings Accounts (HSAs), and suggest strategies like tax-loss harvesting to offset gains. By making your portfolio more tax-efficient, they help you maximize your returns and accelerate your journey to financial independence.",
                "Moreover, investment professionals have access to a wider range of investment opportunities that may not be available to the general public. These can include private equity, hedge funds, or specialized investment funds that require a higher level of knowledge and capital to manage effectively. With their extensive networks and resources, they can open doors to high-growth opportunities that could significantly boost your portfolio. By leveraging these opportunities, you can potentially achieve higher returns and shorten the time it takes to reach millionaire status.",
                "It's important to find the right investment professional who aligns with your goals and values. Not all advisors are created equal, and choosing someone who is a fiduciary—legally obligated to act in your best interest—can make a significant difference. Ensure that your advisor has a transparent fee structure and a track record of helping clients achieve their financial goals. Take the time to interview potential advisors and ask questions about their investment philosophy, experience, and how they plan to help you reach your millionaire goals. A good fit will provide not just expertise but also peace of mind on your financial journey.",
                "Ultimately, hiring an investment professional is an investment in yourself and your future. While there is a cost associated with their services, the value they provide—through expert advice, personalized strategies, disciplined management, and access to exclusive opportunities—can far outweigh the expense. By partnering with a knowledgeable advisor, you can make smarter, more strategic decisions that align with your financial goals, helping you steadily build wealth and achieve the millionaire status you desire. Remember, the right professional is not just a guide, but a critical partner in your journey to financial success."
            ]
            
        },
        {
            title: "Put Your Plan on Rpeaet",
            link: "put-your-plan",
            description: [
                "The principle of 'Put Your Plan on Repeat' is a cornerstone for those striving to become a millionaire. Building wealth is not about one-time actions or occasional bursts of effort; it’s about consistent, disciplined habits applied over time. Once you have established a solid financial plan—whether it involves saving a certain percentage of your income, investing regularly, or continuously cutting unnecessary expenses—the key to success lies in repeating those actions month after month, year after year. Consistency is what allows you to harness the power of compound growth, turning small, regular contributions into substantial wealth over time.",
                "Automating your financial plan is one of the most effective ways to put it on repeat. Set up automatic transfers from your checking account to your savings and investment accounts. This removes the need to make a conscious decision every month, ensuring that your money is being allocated according to your plan without you having to think about it. Whether it’s a direct deposit into a retirement account, a recurring investment in a stock or mutual fund, or a regular payment towards debt reduction, automation makes the process seamless and keeps you on track toward your millionaire goal.",
                "Another benefit of repeating your plan consistently is the ability to build strong financial habits. Habits are the foundation of financial success, and by committing to repetitive actions that align with your financial goals, you create a positive cycle of behavior that supports wealth-building. Over time, these habits become second nature, making it easier to maintain financial discipline even during challenging times. The power of habit means that saving, investing, and managing your finances become less of a chore and more of a routine that you don’t even have to think twice about.",
                "Revisiting and fine-tuning your plan periodically is essential, even as you keep it on repeat. Market conditions change, your income may fluctuate, and personal circumstances evolve. Regularly reviewing your financial plan allows you to adjust your contributions, diversify your investments, or reallocate your savings in ways that keep you moving toward your goal efficiently. However, these adjustments should be strategic and data-driven, not reactions to temporary market swings or emotional impulses. The core of your plan—saving consistently and investing wisely—should remain unchanged.",
                "Staying disciplined with your financial plan also means understanding the impact of compounding. When you consistently reinvest your returns, your money grows exponentially rather than linearly. For example, if you invest $500 per month in a portfolio with an average annual return of 8%, after 30 years, you could end up with nearly $750,000. This is the power of repetition combined with compounding returns—it’s a gradual process that becomes significantly more impactful over time. By putting your plan on repeat, you are leveraging time and compound interest, two of the most powerful tools for wealth creation.",
                "Moreover, having a repeatable financial plan helps you stay resilient during financial downturns or personal setbacks. When your plan is built on sound financial principles and automated processes, you're less likely to be swayed by market volatility or short-term losses. This resilience is critical for staying invested during tough times, which can ultimately lead to greater rewards. A consistent, well-executed plan allows you to ride out the market’s ups and downs without making hasty decisions that could derail your long-term goals.",
                "In the end, becoming a millionaire is less about groundbreaking strategies and more about executing a solid plan consistently over time. By putting your plan on repeat, you establish a steady, reliable path toward financial success. Every month you save, invest, or pay down debt brings you one step closer to your goal. While it may seem slow at first, the results compound over time, leading to exponential growth and, ultimately, achieving the millionaire status you’ve been working towards. Remember, wealth-building is a marathon, not a sprint, and the key to winning is consistency."
            ]
            
        }
    ];
     
    const stepsRecord = stepData?.find((x) => x?.link === stepName[1])
    return (
        <div className='container max-w-7xl mx-auto pt-[3rem] max-[768px]:pt-[1.5rem] px-5'>

            <div className='flex justify-center'>
                <h1 className='text-black md:text-[37px]  my-3 font-bold text-[30px] max-[1024px]:text-center'>{stepsRecord?.title}</h1>
            </div>
            <div className='main-div py-3'>
                {
                    stepsRecord?.description?.map((x) => {
                        return <div className='md:text-[20px] text-[17px]'><p>{x}</p><br />
                            {x?.listing && x?.listing?.map((a) => {
                                return <ul className='list-disc'>
                                    <li className=''>{a}</li>
                                </ul>
                            })}
                        </div>
                    })
                }
            </div>

            <BackToTop/>
        </div>
    )
}

export default SuccessAllStep