import React, { useState } from 'react'
import IdeaForm from '../Idea-form/idea-form'
const YourIdea = () => {
    const [isModelOpen,setIsModelOpen] = useState(false)
    return (
        <div>
            <div className='text-center container max-w-7xl mx-auto my-5' onClick={()=>setIsModelOpen(true)}>
                <div className='flex justify-center'>
                <h1 className='font-semibold mt-2 pt-[2rem] w-[75%] max-[1024px]:w-[97%] max-[768px]:pt-1 text-[32px] max-[768px]:text-[22px] text-center'>Do you Have Any Idea Involving Investing In Emerging Markets For Higher Returns?</h1>
                </div>
                <div className='flex justify-center my-4 mx-3 md:mx-0'>
                    <img src="https://billionaireweb.s3.eu-north-1.amazonaws.com/idea_share.webp" alt='your_idea' className='border-2 border-black '/>
                </div>
                <div className='flex justify-center'>
                    <p className='w-[70%] max-[1024px]:w-[97%] mt-4 max-[768px]:text-justify max-[1024px]:px-3 text-[18px] max-[768px]:text-[15px]'>To achieve significant wealth necessitates entrepreneurial vision, innovative thinking, strategic risk-taking, financial acumen, and relentless determination. Successful individuals often identify untapped market opportunities, build thriving businesses, foster valuable networks, maintain financial discipline, and give back to society. However, it's essential to recognize that true wealth is not solely determined by financial assets, but also by one's impact, integrity, and contributions to society.</p>
                </div>
                <button className='bg-[#F06939] px-14 py-4 mt-4 text-white text-[16px] rounded-lg max-[768px]:px-10 font-semibold'>Share Your Investment Idea</button>
            </div>
            {isModelOpen && <IdeaForm isOpen={isModelOpen} onClose={()=>setIsModelOpen(false)}/>}
        </div>
    )
}

export default YourIdea