import React, { useState } from "react";
import AdFooter from "../ads/AdFooter";
import { MdLocationPin, MdOutlineMail, MdOutlinePhone } from "react-icons/md";
import AdResponsive05 from "../ads/AdResponsive05";
import IdeaForm from "../Idea-form/idea-form";
import WebsiteDisclaimer from "../HomeSubPage/WebsiteDisclaimer";
import { useLocation } from "react-router-dom";
import StickyAd from "../ads/StickyAd";

const Footer = () => {
  const token =
    localStorage.getItem("token") && localStorage.getItem("token")?.toString();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const location = useLocation();
  const isDisclaimerPage = location.pathname === "/disclaimer";

  return (
    <div>
      <div className="pt-[20px] pb-[30px]">
        <AdFooter />
      </div>
      {!isDisclaimerPage && <WebsiteDisclaimer />}
      <div className={`${isVisible ? "mb-[130px]" : "mb-[20px]"}`}>
        <div className="py-[50px] bg-[#1a1a1a] text-white">
          <div className="container max-w-7xl mx-auto px-6">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1">
              <div className="mt-3">
                <h4 className="mb-4 text-[22px] font-bold">Quick Link</h4>
                <ul className="list-unstyled link">
                  <li className="mb-2">
                    <a
                      href="/about-us"
                      className="text-[#ffffffb3] cursor-pointer text-[17px]  no-underline"
                    >
                      About Us
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href="/terms-conditions"
                      className="text-[#ffffffb3] cursor-pointer text-[17px] no-underline"
                    >
                      Terms &amp; Conditions
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href="/privacy-policy"
                      className="text-[#ffffffb3] cursor-pointer text-[17px] no-underline"
                    >
                      Privacy Policy
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href="/disclaimer"
                      className="text-[#ffffffb3] cursor-pointer text-[17px] no-underline"
                    >
                      Disclaimer
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-3">
                <h4 className="mb-4 text-[22px] font-bold">Support</h4>
                <ul className="list-unstyled link">
                  <li className="mb-2">
                    <a
                      href="/millionaires"
                      className="text-[#ffffffb3] cursor-pointer text-[17px] no-underline"
                    >
                      Millionaires
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href="/celebrities"
                      className="text-[#ffffffb3] cursor-pointer text-[17px] no-underline"
                    >
                      Celebrities
                    </a>
                  </li>
                  {/* <li className="mb-2">
                    <a
                      href="/blogs"
                      className="text-[#ffffffb3] cursor-pointer text-[17px] no-underline"
                    >
                      Blogs
                    </a>
                  </li>
                  <li className="mb-2">
                    <a
                      href={token ? "/financial-ideas" : "/view-financial-idea"}
                      className="text-[#ffffffb3] cursor-pointer text-[17px] mb-1 no-underline"
                    >
                      Financial Idea
                    </a>
                  </li> */}
                  <li className="mb-2">
                    <a
                      href={"/books"}
                      className="text-[#ffffffb3] cursor-pointer text-[17px] mb-1 no-underline"
                    >
                      Books
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-6 mt-3">
                <h4 className="mb-4 text-[22px] font-bold">Contact Info</h4>
                <div className="flex mb-4">
                  <div className="block italic me-2">
                    <MdLocationPin size={25} className="mt-1" />
                  </div>{" "}
                  <p className="ms-1 text-[#ffffffb3] cursor-pointer mb-1 text-[17px]">
                    209, Sunrise Complex, Savjibhai Korat Bridge, Lajamani
                    chowk, Mota Varachha, Surat, Gujarat 394101, India
                  </p>
                </div>
                <div className="flex">
                  <div className="block italic mb-4 me-2">
                    <MdOutlinePhone size={22} className="mt-1" />
                  </div>{" "}
                  <p className="ms-1 text-[#ffffffb3] cursor-pointer mb-1 text-[17px]">
                    +91 90818 03333
                  </p>
                </div>

                <div className="flex">
                  <div className="block italic mb-1 me-2">
                    <MdOutlineMail size={22} className="mt-1" />
                  </div>{" "}
                  <a
                    href="mailto:support@8bitinfosys@gmail.com"
                    className="ms-1 text-[#ffffffb3] text-[17px] cursor-pointer mb-1"
                  >
                    8bitinfosys@gmail.com
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdResponsive05 />
      </div>
      <StickyAd isVisible={isVisible} setIsVisible={setIsVisible} />
      {isModelOpen && (
        <IdeaForm isOpen={isModelOpen} onClose={() => setIsModelOpen(false)} />
      )}
    </div>
  );
};

export default Footer;
