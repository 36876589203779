import React, { useState } from "react";
import { GrClose } from "react-icons/gr";
import { FaBars, FaUser } from "react-icons/fa";
// import AdHeader from "../ads/AdHeader";
import { useNavigate } from "react-router-dom";
// import AdResponsive04 from "../ads/AdResponsive04";
import IdeaForm from "../Idea-form/idea-form";
import TopStickyAd from "../ads/TopStickyAd";

const Header = () => {
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(true);

  const token =
    localStorage.getItem("token") && localStorage.getItem("token")?.toString();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModelOpen, setIsModelOpen] = useState(false);

  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const handleToggleOpen = () => {
    setIsToggleOpen(!isToggleOpen);
  };
  const user = JSON.parse(localStorage.getItem("user"));
  const handleLogOut = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <>
      {/* <AdHeader /> */}
      <TopStickyAd isVisible={isVisible} setIsVisible={setIsVisible} />
      <div className={`text-white ${isVisible ? "mt-[120px]" : "mt-[0px]"}`}>
        <header className="navbar-div h-[80px] w-full flex items-center justify-around max-[1024px]:justify-between max-[1024px]:items-start relative">
          <div className={"nav_logo px-3  max-[1024px]:mt-[15px]"}>
            <a
              href={"/"}
              className="nav-logo-link text-[24px] text-white font-semibold "
            >
              <img
                src="https://billionaireweb.s3.eu-north-1.amazonaws.com/millionair+clubs-02.png"
                alt="logo"
                className="h-[50px]"
              />
            </a>
          </div>

          <ul
            // isToggleOpen={isToggleOpen}
            className={`listing ${
              isToggleOpen ? "listing-toggle-open" : "listing-toggle-closed"
            }`}
          >
            <li className="hover:cursor-pointer">
              <a
                href={"/"}
                className="text-white block p-3 px-4 font-medium tracking-[0.5px]"
                onClick={() => setIsToggleOpen(false)}
                style={{ textDecoration: "none" }}
              >
                Home
              </a>
            </li>

            <li className="hover:cursor-pointer">
              <a
                href={"/millionaires"}
                className="text-white block p-3 px-4 font-medium tracking-[0.5px]"
                onClick={() => setIsToggleOpen(false)}
                style={{ textDecoration: "none" }}
              >
                Millionaires
              </a>
            </li>
            <li className="hover:cursor-pointer">
              <a
                href={"/celebrities"}
                className="text-white block p-3 px-4 font-medium tracking-[0.5px]"
                onClick={() => setIsToggleOpen(false)}
                style={{ textDecoration: "none" }}
              >
                Celebrities
              </a>
            </li>
            {/* <li className="hover:cursor-pointer">
              <a
                href={"/blogs"}
                className=" text-white block p-3 px-4 font-medium tracking-[0.5px]"
                onClick={() => setIsToggleOpen(false)}
                style={{ textDecoration: "none" }}
              >
                Blog
              </a>
            </li>*/}
            {/* <li className="hover:cursor-pointer">
              <a
                href={"/life-style"}
                className=" text-white block p-3 px-4 font-medium tracking-[0.5px]"
                onClick={() => setIsToggleOpen(false)}
                style={{ textDecoration: "none" }}
              >
                LifeStyle
              </a>
            </li> */}
            {/* <li className="hover:cursor-pointer">
              <p
                className="nav-menu-list decoration-none text-white block p-3 px-4 font-medium tracking-[0.3px]"
                onClick={() => {
                  setIsModelOpen(true);
                  setIsToggleOpen(false);
                }}
                style={{ textDecoration: "none" }}
              >
                Share Investment Idea
              </p>
            </li> */}
            {/* <li className="hover:cursor-pointer">
              <a
                href={token ? "/financial-ideas" : "/view-financial-idea"}
                className="nav-menu-list decoration-none text-white block p-3 px-4 font-medium tracking-[0.5px]"
                onClick={() => setIsToggleOpen(false)}
                style={{ textDecoration: "none" }}
              >
                Financial Idea
              </a>
            </li> */}
            <li className="hover:cursor-pointer">
              <a
                href={"/about-us"}
                className="text-white block p-3 px-4 font-medium tracking-[0.5px]"
                onClick={() => setIsToggleOpen(false)}
                style={{ textDecoration: "none" }}
              >
                About Us
              </a>
            </li>
            <li className="hover:cursor-pointer">
              <a
                href={"/books"}
                className="nav-menu-list decoration-none text-white block p-3 px-4 font-medium tracking-[0.5px]"
                onClick={() => setIsToggleOpen(false)}
                style={{ textDecoration: "none" }}
              >
                Books
              </a>
            </li>
          </ul>
          <div className="hover:cursor-pointer flex items-center max-[1024px]:absolute right-[58px] top-[25px]">
            {token ? (
              <div className="relative inline-block text-left">
                <div>
                  <button
                    type="button"
                    className="bg-[#fff] no-underline text-black h-9 w-10 rounded-full font-semibold uppercase"
                    id="options-menu"
                    aria-expanded="true"
                    aria-haspopup="true"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {user?.first_name?.charAt(0)}
                    {user?.last_name?.charAt(0)}
                  </button>
                </div>

                {isDropdownOpen && (
                  <div
                    className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 hover:bg-gray-100 hover:rounded-md"
                    role="menu"
                  >
                    <button
                      type="submit"
                      className="block w-full text-left px-4 py-2 text-md justify-center text-black"
                      role="menuitem"
                      onClick={() => handleLogOut()}
                    >
                      Log Out
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <>
                <a
                  href="/auth/sign-in"
                  className="flex items-center px-2 py-1 rounded-md no-underline text-white cursor-pointer"
                >
                  <FaUser className="text-[22px]" />
                </a>
              </>
            )}
          </div>
          <div className="lg:hidden flex flex-col items-end gap-1 font-semibold">
            <button
              onClick={handleToggleOpen}
              className="hidden text-white text-[24px] absolute right-[20px]  top-[30px] cursor-pointer max-[1024px]:block"
            >
              {isToggleOpen ? <GrClose /> : <FaBars />}
            </button>
          </div>
        </header>
        {isModelOpen && (
          <IdeaForm
            isOpen={isModelOpen}
            onClose={() => setIsModelOpen(false)}
          />
        )}
      </div>
      {/* <AdResponsive04 /> */}
      {/* <AdHeader /> */}
    </>
  );
};
export default Header;
