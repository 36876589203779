import React from 'react'

const SubBlog = ({ blogData }) => {
    return (
        <div className='px-8 py-6 h-full rounded-lg'>
            <div className='flex'>
                <h3 className='font-semibold text-[24px] border-b border-[#000]'>Recent Blog</h3>
            </div>
            {blogData?.map((blog, index) => {
                return <div key={index}>
                    <a href={`/blog?blog=${blog?.slug}`} className='no-underline' >
                        <div className='grid grid-cols-3 mt-6 gap-4 cursor-pointer'>
                            <div className='h-20'>
                                <img src={blog?.image} alt={blog?.title} className='h-full w-full object-cover rounded-lg' />
                            </div>
                            <div className='col-span-2'>
                                <h4 className='line-clamp-3 tracking-[0.3px] font-medium hover:text-blue-800 text-[17px]'>{blog?.title}</h4>
                            </div>
                        </div>
                    </a>
                </div>
            })}


        </div>
    )
}

export default SubBlog