import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Base_Url from '../../api/baseUrl';
import AdResponsive02 from '../../Components/ads/AdResponsive02';
import GoogleAdsScript from '../../Components/ads/GoogleAdsScript';
import BackToTop from '../../Components/BackToTop/backToTop';
import ReactPaginate from 'react-paginate';
import { FaArrowLeftLong, FaArrowRightLong } from 'react-icons/fa6';
import SubBlog from '../../Components/HomeSubPage/Sub-blog';
import AdResponsive07 from '../../Components/ads/AdResponsive07';

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};
const BillionaireData = () => {
    const navigate = useNavigate()
    const [billionaire, setBillionaire] = useState({});
    const [loading, setLoading] = useState(false);
    const [adDisplayed, setAdDisplayed] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const [remainingItems, setRemainingItems] = useState();
    const [billionaires, setBillionaires] = useState()
    const [pageCount, setPageCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const { slug } = useParams();
    const location = useLocation();
    const querySlug = new URLSearchParams(location.search).get('name');
    const [billionaireData, setBillionaireData] = useState(querySlug || slug);

    useEffect(() => {
        const fetchSlug = slug || querySlug;
        if (fetchSlug) {
            console.log(fetchSlug)
            setBillionaireData(fetchSlug);
        } else {
            window.location.reload('/millionaires');
        }
    }, [slug, querySlug]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        let clickCount = localStorage.getItem('clickCount') || '0';
        let click = Number(clickCount);
        if (click === 3) {
            setTimeout(() => {
                setAdDisplayed(true);
            }, 1000);
        }

        if (click >= 3) {
            localStorage.setItem('clickCount', '0');
        }
    }, []);


    const getOneBillionaire = useCallback(async () => {
        setLoading(true);
        console.log(billionaireData)
        try {
            const res = await axios.get(`${Base_Url}api/billionaire/${billionaireData}`);
            setLoading(false);
            if (res) {
                setBillionaire(res?.data?.data);
            }
        } catch (error) {
            setLoading(false);
            setNotFound(true);
        }
    }, [billionaireData]);

    useEffect(() => {
        if (billionaireData) {
            getOneBillionaire();
        }
    }, [billionaireData, getOneBillionaire]);

    const fetchAllBillionaires = useCallback(
        async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${Base_Url}api/get-billionaire?page=1&limit=100`);
                setLoading(false);
                if (response) {
                    const fetchedBillionaires = response?.data?.data;
                    setBillionaires(fetchedBillionaires);
                    setPageCount(fetchedBillionaires?.length);
                    
                    if (billionaireData) {
                        console.log(fetchedBillionaires);
                        const matchedIndex = fetchedBillionaires?.findIndex(
                            (item) => item.slug === billionaireData
                        );
                        console.log(matchedIndex);
                        if (matchedIndex !== -1) {
                            setCurrentPage(matchedIndex);
                        }
                    }
                }
            } catch (error) {
                setLoading(false);
                console.error('Error fetching billionaires:', error);
            }
        },
        [billionaireData] 
    );
    useEffect(() => {
        fetchAllBillionaires();
    }, [fetchAllBillionaires]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const allBlogData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${Base_Url}api/get-blog?limit=100&page=1`)
                setLoading(false)
                if (response) {
                    const shuffledBlogs = shuffleArray(response.data.data).slice(0, 15);
                    setRemainingItems(shuffledBlogs);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching blog data:", error);
            }
        };
        allBlogData();
        ;
    }, [])
    const handlePageClick = async ({ selected }) => {
        setCurrentPage(selected);
        window.scrollTo({ top: 0, behavior: 'smooth' });

        if (billionaires && billionaires[selected]) {
            const nextSlug = billionaires[selected]?.slug;
            if (nextSlug) {
                navigate(`?name=${nextSlug}`);
            }
        }
    };

    const reloadAds = () => {
        if (window.googletag && window.googletag.cmd) {
          window.googletag.cmd.push(function () {
            window.googletag.display("div-gpt-ad-1721020439738-0");
            window.googletag.display("div-gpt-ad-1722943952843-0");
            window.googletag.display("div-gpt-ad-1726053586752-0");
            window.googletag.display("div-gpt-ad-1721020427956-0");
            window.googletag.display("div-gpt-ad-1721020424421-0");
          });
        }
        if (window.googletag && window.googletag.pubads) {
          try {
            window.googletag.pubads().refresh();
          } catch (error) {
            console.error("Failed to refresh ads:", error);
          }
        }
      };
    
      useEffect(() => {
        reloadAds();
      }, [currentPage]);

    return (
        <div className='flex justify-center'>
            {adDisplayed && <GoogleAdsScript />}
            {!notFound ? (
                <>
                    {loading ? (
                        <div className='flex justify-center'>
                            <div className='loader my-5 !w-[40px]'></div>
                        </div>
                    ) : (
                        <div className='max-[768px]:p-5 max-[768px]:pt-0 container max-w-7xl mx-auto md:px-0 px-3'>
                            <h1 className='text-[#000] text-center text-[35px] font-semibold mt-4 max-[768px]:text-[27px]'>
                                {billionaire?.name}
                            </h1>
                            <div className='flex justify-center mt-4 mb-3'>
                                <div className='h-[600px] max-[768px]:h-full'>
                                    <img
                                        src={billionaire?.image}
                                        alt={billionaire?.name}
                                        className='h-full'
                                    />
                                </div>
                            </div>
                            <AdResponsive02 />
                            <div className='flex gap-7 md:px-5'>
                                <div className='lg:w-[65%] w-full'>
                                    <div className='mt-5'>
                                        <div className='grid md:grid-cols-2 grid-cols-1 mb-3'>
                                            <div className=''>
                                                {billionaire?.net_worth && (
                                                    <p className='md:text-[20px] font-bold text-[17px]'>
                                                        <span className='text-[#000] text-[20px] font-medium max-[768px]:text-[17px]'>
                                                            Net Worth:{' '}
                                                        </span>
                                                        {billionaire?.net_worth}
                                                    </p>
                                                )}
                                            </div>
                                            <div className=''>
                                                {billionaire?.country && (
                                                    <p className='md:text-[20px] font-bold text-[17px]'>
                                                        <span className='text-[#000] text-[20px] font-medium max-[768px]:text-[17px]'>
                                                            Country/Territory:{' '}
                                                        </span>
                                                        {billionaire?.country}
                                                    </p>
                                                )}
                                            </div>
                                            <div className=''>
                                                {billionaire?.industry && (
                                                    <p className='md:text-[20px] font-bold text-[17px]'>
                                                        <span className='text-[#000] text-[20px] font-medium max-[768px]:text-[17px]'>
                                                            Industry:{' '}
                                                        </span>
                                                        {billionaire?.industry}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <span
                                            dangerouslySetInnerHTML={{ __html: billionaire?.description }}
                                            className='max-[768px]:text-justify md:text-[19px] text-[16px]'
                                        />
                                    </div>
                                    <div className='my-5 md:p-4'>
                                        <ReactPaginate
                                            previousLabel={
                                                <div className='flex items-center gap-2 bg-[#F06939] text-white py-2 md:px-4 px-3 rounded-sm tracking-[0.3px] font-semibold md:text-[17px] text-[14px]'>
                                                    <FaArrowLeftLong />
                                                    Previous
                                                </div>
                                            }
                                            nextLabel={
                                                <div className='flex items-center gap-2 bg-[#F06939] text-white py-2 md:px-4 px-3 rounded-sm tracking-[0.3px] font-semibold md:text-[17px] text-[14px]'>
                                                    Next
                                                    <FaArrowRightLong />
                                                </div>
                                            }
                                            pageCount={pageCount}
                                            marginPagesDisplayed={0}
                                            pageRangeDisplayed={0}
                                            onPageChange={handlePageClick}
                                            containerClassName={'flex  justify-between mt-4'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'!hidden'}
                                            forcePage={currentPage}
                                        />

                                    </div>


                                </div>
                                <div className='lg:w-[35%] lg:block hidden'>
                                    <div className="shadow-2xl rounded-lg border border-[#cacaca]">
                                        <SubBlog blogData={remainingItems} />
                                    </div>
                                    <div className='py-10'>
                                        <AdResponsive07 />
                                    </div>
                                </div>
                            </div>


                        </div>

                    )}
                </>
            ) : (
                <div className='flex justify-center flex-col text-center'>
                    <h1 className='text-[120px] text-black max-[768px]:text-[94px] max-[768px]:mb-0'>404</h1>
                    <h2 className='text-3xl mb-1 text-black font-semibold max-[768px]:text-[25px]'>
                        Ooops... No Millionaire found!
                    </h2>
                    <div className='flex justify-center mt-3'>
                        <a href='/' className='no-underline'>
                            <button className='text-xl underline font-medium text-[#fff] bg-[#F06939] w-[220px] rounded-md h-[60px] mt-2'>
                                Go Back
                            </button>
                        </a>
                    </div>
                </div>
            )}

            <BackToTop />
        </div>
    );
};

export default BillionaireData;
