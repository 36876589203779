import React, { useState } from 'react';

const FAQ = () => {
    const accordionData = [
        {
            title: 'What is being rich?',
            content: `<p class="max-[768px]:text-[15px]">Being rich refers to having a significant amount of wealth, often measured by one's net worth. A rich individual possesses substantial assets—such as cash, investments, real estate, and personal property—that far exceed their liabilities. Achieving wealth typically involves disciplined saving, smart investing, and sometimes inheriting wealth. People from all walks of life can attain riches, spanning various industries and professions.</p>`,
        },
        {
            title: 'How can I develop a rich mindset?',
            content: `<p class="max-[768px]:text-[15px] mb-3">Developing a rich mindset involves cultivating the attitudes, habits, and perspectives that are common among successful individuals who have achieved significant wealth. Key steps to developing this mindset include:</p>
            <p class="max-[768px]:text-[15px] mb-3"><b>Set Clear Financial Goals:</b> Define what financial success looks like for you, and set specific, measurable goals to achieve them.</p>
            <p class="max-[768px]:text-[15px] mb-3"><b>Embrace Continuous Learning:</b> Commit to constantly improving your financial knowledge through books, courses, and mentorship. A rich mindset values education and self-improvement.</p>
            <p class="max-[768px]:text-[15px] mb-3"><b>Think Long-Term:</b> Rich individuals focus on long-term wealth-building rather than short-term gains. They understand the power of compound interest and make decisions that will benefit them in the future.</p> 
            <p class="max-[768px]:text-[15px] mb-3"><b>Take Calculated Risks:</b> While they are careful with their money, rich individuals are not afraid to take calculated risks. They understand that growth often comes from stepping out of their comfort zones.</p>
            <p class="max-[768px]:text-[15px] mb-3"><b>Surround Yourself with Success:</b> Build a network of like-minded individuals who share your goals. Being around successful people can inspire and motivate you to reach higher.</p>
            <p class="max-[768px]:text-[15px] mb-3"><b>Stay Disciplined:</b> Consistency and discipline in saving, investing, and managing money are crucial. A rich mindset involves resisting the temptation to spend impulsively and instead focusing on wealth accumulation.</p>
            <p class="max-[768px]:text-[15px] mb-3"><b>Adopt a Positive Attitude:</b> Belief in your ability to achieve financial success. A positive, can-do attitude is essential for overcoming challenges and staying focused on your goals.</p>`,
        },
        {
            title: 'What investment strategies do rich people use?',
            content: '<p class="max-[768px]:text-[15px] mb-2">Rich people typically adopt a strategic, disciplined, and long-term approach to investment to build and preserve their wealth.</p> <p class="max-[768px]:text-[15px] mb-2"><b>Diversification</b> is central to their strategy; they allocate their investments across a range of asset classes such as stocks, bonds, real estate, and alternative assets like private equity, commodities, or fine art. This approach minimizes risk and helps ensure their portfolios remain resilient under various market conditions.</p> <p class="max-[768px]:text-[15px] mb-2">Another crucial strategy is <b>focusing on high-quality investments.</b> They often invest in blue-chip stocks, prime real estate, or businesses with robust fundamentals and growth potential.</p><p class="max-[768px]:text-[15px] mb-2"> Compounding returns plays a significant role in their wealth-building; by investing consistently over time, they harness the power of compound interest to grow their wealth exponentially. Many rich people also recognize the importance of professional advice, working closely with financial advisors to tailor and refine their investment strategies.</p><p class="max-[768px]:text-[15px] mb-2"> Additionally, they tend to be patient investors, utilizing a buy-and-hold approach rather than chasing short-term market trends. This long-term focus, combined with careful risk management and value investing, enables them to steadily increase their wealth while minimizing potential losses.</p>',
        },
        {
            title: 'How do rich people manage their money differently from others?',
            content: '<p class="max-[768px]:text-[15px] mb-2">Rich people manage their money with exceptional discipline and strategic insight. They focus on meticulous budgeting and financial planning, living below their means and consistently saving a portion of their income. Their investment strategy is highly diversified, including stocks, bonds, real estate, and alternative assets, which helps reduce risk and maximize returns.</p><p class="max-[768px]:text-[15px] mb-2">Additionally, rich people tend to adopt a long-term investment approach, favoring buy-and-hold strategies over chasing short-term market trends. They are also proactive about risk management, often consulting with financial advisors to make informed decisions. By continuously seeking knowledge and adapting their strategies, they effectively build and preserve their wealth over time.</p>',
        },
        {
            title: 'How can I develop a rich mindset?',
            content: '<p class="max-[768px]:text-[15px] mb-2">Developing a rich mindset involves cultivating key attitudes and habits that contribute to financial success. Start by setting clear, actionable financial goals and maintaining a long-term perspective on wealth-building. Embrace continuous learning by educating yourself on financial strategies and investment opportunities. Rich people also take calculated risks and are open to stepping out of their comfort zones for growth.</p><p class="max-[768px]:text-[15px] mb-2">Additionally, surround yourself with successful, like-minded individuals who can inspire and motivate you. Practice disciplined saving and investing, focusing on consistent, strategic actions rather than short-term gains. Adopting a positive, growth-oriented mindset and being patient and resilient in the face of challenges will help you build and sustain wealth over time.</p>',
        },
        {
            title:'How Rich People Spend Their Money?',
            content: '<p class="max-[768px]:text-[15px] mb-2">Rich people tend to invest a significant portion of their wealth in assets that offer long-term growth and stability. Common investments include stocks, real estate, bonds, and private equity. They also diversify their portfolios to manage risk effectively. While some indulge in luxury items like high-end cars and designer clothing, many are surprisingly frugal, prioritizing spending on experiences, quality goods, and investments that appreciate over time. Education, personal development, and health are also key areas where rich people are willing to invest, recognizing the value of continuous learning and maintaining their well-being.</p><p class="max-[768px]:text-[15px] mb-2">In addition to their investments and spending habits, rich people often engage in philanthropy, donating to causes they care about or setting up charitable foundations. They manage their daily expenses with care, often living below their means and focusing on value over cost. Retirement savings and planning are also important, with many using diversified retirement accounts and other income-generating investments to secure their future. While they do enjoy travel and leisure, many rich people prefer to spend on unique experiences that create lasting memories rather than on accumulating material possessions.</p>',
        },
        {
            title:'What are the steps to becoming rich?',
            content: '<p class="max-[768px]:text-[15px] mb-2"><b>Set Clear Financial Goals:</b> Define your vision of being rich with specific, measurable goals. Break them into actionable steps and review regularly. Clear goals keep you focused and guide your financial decisions, making wealth accumulation more achievable.</p><p class="max-[768px]:text-[15px] mb-2"><b>Create a Budget and Save Aggressively:</b> Track your income and expenses with a detailed budget. Live below your means by cutting unnecessary spending and saving 15-20% of your income. Build an emergency fund and adjust your budget regularly. Aggressive saving and budgeting are key to accumulating wealth and reaching your financial goals..</p> <p class="max-[768px]:text-[15px] mb-2"><b>Invest Wisely:</b> Invest in appreciating assets like stocks and real estate. Diversify your investments and align them with your goals and risk tolerance. Wise investing is crucial for building substantial wealth over time.</p> <p class="max-[768px]:text-[15px] mb-2"><b>Increase Your Income:</b> Boost your earnings through career advancement, side businesses, or freelancing. Seek promotions, higher-paying jobs, or invest in skill development. Use additional income to enhance savings and investments. Increasing your income accelerates wealth accumulation and helps you achieve financial goals faster.</p><p class="max-[768px]:text-[15px] mb-2"><b>Avoid Debt and Manage Risk:</b> Minimize unnecessary debt and use loans strategically. Protect your wealth with insurance and diversify investments. Effective debt and risk management safeguard your financial health and support wealth-building efforts.</p><p class="max-[768px]:text-[15px] mb-2"><b>Continue Learning and Adapting:</b> Stay informed about financial trends and adjust strategies as needed. Ongoing learning and adaptability help optimize wealth-building efforts and achieve financial success.</p>',
        }
    ];
    
    const [openIndex, setOpenIndex] = useState(0);

    const handleClick = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className="container max-w-7xl mx-auto py-12">
            <div>
                <h4 className="lg:text-[30px] text-[24px] font-semibold text-center lg:text-start">FAQ's on Becoming Rich</h4>
                <div className="bg-white rounded-lg  my-7 ">
                    {accordionData?.map((item, index) => (
                        <div key={index} className={`${index !== accordionData?.length - 1 ? 'border-b border-[#cacaca]' : ''
                            } border border-[#cacaca] mb-3 rounded-md`}>
                            <button
                                className="w-full md:py-4 py-3 md:px-6 px-4 text-left focus:outline-none flex justify-between items-center hover:bg-[#F4F4F4] focus:bg-[#F4F4F4]"
                                onClick={() => handleClick(index)}
                            >
                                <p className="font-semibold md:text-[17px] text-[15px] tracking-[0.2px] text-gray-600">{item?.title}</p>
                                <span
                                    className={`${openIndex === index ? 'transform rotate-180' : ''
                                        } transition-transform duration-300`}
                                >
                                    <svg
                                        className="md:w-6 md:h-6 w-5 h-5"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M19 9l-7 7-7-7"
                                        />
                                    </svg>
                                </span>
                            </button>
                            {openIndex === index && (
                                <div className="px-2 py-4 border-t border-gray-200 md:mx-5 mx-2">
                                    <p className="text-gray-600" dangerouslySetInnerHTML={{ __html: item?.content }}></p>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default FAQ;
