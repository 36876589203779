import React from "react";
import thinkImg from "../../assests/Think-grow.png";
import JackCanfield from "../../assests/jack-canfield.png";
import Cardone from "../../assests/Cardone.png";
import kids from "../../assests/10x-kids.png";
import zeroToHero from "../../assests/zeroToHero.png";
import artinnovation from "../../assests/art-innovation.png";
import goodToGreat from "../../assests/goodToGreat.png";
import execution from "../../assests/execution.png";
import startUp from "../../assests/startUp.png";
import invester from "../../assests/invester.png";
import mindset from "../../assests/mindset.png";
import money from "../../assests/money.png";
import BackToTop from "../../Components/BackToTop/backToTop";
import amazon from "../../assests/buy button.png";
import LeftSideAd from "../../Components/ads/LeftSideAd";
import RightSideAd from "../../Components/ads/RightSideAd";
import LongAdCard from "../../Components/ads/LongAdCard";
import LongAdCard02 from "../../Components/ads/LongAdCard02";

const Books = () => {
  const booksData = [
    {
      image: thinkImg,
      book_name: "Think And Grow Rich",
      link: "https://www.amazon.in/Think-Grow-Rich-Napoleon-Hill/dp/817234564X/ref=as_li_ss_tl?ie=UTF8&qid=1486052146&sr=8-3&keywords=think+and+grow+rich&linkCode=sl1&tag=10xreading-21&linkId=7a96ddc2aa99730a990280aa586095c0",
    },
    {
      image: JackCanfield,
      book_name: "The Success Principles",
      link: "https://www.amazon.in/Success-Principles-Jack-Canfield/dp/0007301677/ref=as_li_ss_tl?ie=UTF8&qid=1486052211&sr=8-1&keywords=the+success+principles+by+jack+canfield&linkCode=sl1&tag=10xreading-21&linkId=80368e3f59ad08e7953f0c783c26d20c",
    },
    {
      image: Cardone,
      book_name: "Rich Dad Poor Dad",
      link: "https://www.amazon.in/Rich-Dad-Poor-Middle-Anniversary/dp/1612681131",
    },
    {
      image: kids,
      book_name: "The 10X Rule",
      link: "https://www.amazon.in/10X-Rule-Difference-Between-Success/dp/0470627603",
    },
    {
      image: zeroToHero,
      book_name: "Zero to One",
      link: "https://www.amazon.in/Zero-One-Start-Build-Future/dp/0753555190/ref=as_li_ss_tl?ie=UTF8&qid=1486054553&sr=8-1&keywords=zero+to+one+peter+thiel&linkCode=sl1&tag=10xreading-21&linkId=7681c37a33719f703f6d92d3e7589346",
    },
    {
      image: goodToGreat,
      book_name: "Good To Great",
      link: "https://www.amazon.in/Good-Great-Some-Companies-Others/dp/0712676090",
    },
    {
      image: execution,
      book_name: "Execution",
      link: "https://www.amazon.in/Execution-Discipline-Getting-Things-Done/dp/0609610570/ref=as_li_ss_tl?ie=UTF8&qid=1486054503&sr=8-2&keywords=execution+the+discipline+of+getting+things+done&linkCode=sl1&tag=10xreading-21&linkId=47f6ec676bc0c86956a09032c38c9e42",
    },
    {
      image: artinnovation,
      book_name: "The Art of Innovation",
      link: "https://www.amazon.in/ART-INNOVATION-UPDATED-ED/dp/1781256144/ref=sr_1_3?crid=1N0F55916VF91&dib=eyJ2IjoiMSJ9.cENX-LQdmsJ2nLQoaoZHaRM8sVsVbhyMiATeaqmNUs5r8l0thge1bhqKjmQRsdiDPpCPciYLDLz0FkILr-I48g6zsHkLZKMWePmm2K_vKmRLpo9GUhj4IOK7IUoJrrjsZvXZh-r8EZ6egIe_weJwQSDHw98lTCZxjBN0Ahx-eM4En7MnHZf0Ks5nWUkvJE7Op4_v1QineBpvWdG71veuRRrrK7vnh9jd_ESVVhZhZ8Q.gjzFh-npYXfw4WA2Nwht9dv0xSzIT3T4tZVnin1o4L4&dib_tag=se&keywords=The+Art+of+Innovation&qid=1715593201&sprefix=the+art+of+innovation%2Caps%2C191&sr=8-3",
    },
    {
      image: startUp,
      book_name: "The Lean Startup",
      link: "https://www.amazon.in/Lean-Startup-Innovation-Successful-Businesses/dp/0670921602/ref=as_li_ss_tl?ie=UTF8&qid=1486053586&sr=8-1&keywords=lean+startup&linkCode=sl1&tag=10xreading-21&linkId=c410c5cac0bdc9b4e9afd436b49bd323",
    },
    {
      image: invester,
      book_name: "The Intelligent Investor",
      link: "https://www.amazon.in/Intelligent-Investor-English-Paperback-2013/dp/0062312685/ref=sr_1_3?crid=21NIZ5GCS6PGR&dib=eyJ2IjoiMSJ9.4T4liS_vCDoV8lqznwgAex4gFn02-ET48iEOJwYt90gHBUnCAggGXpxE_VsL3m3883GVgXmokvzkktbOkxOXDkcOlaq_zZxmuv21r3fz87JjZxMjAKupVvZVOERxWIM8_ACVI1BfxVA5pN1ZgMRE_Vuilli1_61mUqo8bQcofFQeKu77-ZkV0KoLagK5EgTME2Xc4MVY5pHenwHGvlL4Qwfs9nng5fqXYvVT-qBLzuA.tv93kKrgS5MpFkKJy5aCjYwqA9VfhX9pRtGOFSs1ooM&dib_tag=se&keywords=The+Intelligent+Investor&qid=1715593251&sprefix=the+intelligent+investor%2Caps%2C204&sr=8-3",
    },
    {
      image: mindset,
      book_name: "Mindset",
      link: "https://www.amazon.in/Mindset-Psychology-Success-Your-Coach/dp/1596597682/ref=sr_1_2?crid=X0KA3ND6L3PY&dib=eyJ2IjoiMSJ9.wQI-norEmBVmVXMDQTsvLkom26d54cbX067iFgB8dOlt4IO5Dy_QAxC9uXfAc3MCQALh4gD8oZUop8Auyb5P4NOVgjj03w7HRdfxJyumtdPgFlhoix35lwnkVQcFBkCjzbuWW1TKQeH_OfnL6lTAaUNuT4yVzEu323tYm61vo3sq3f8HKPAHy_HrnNkuH1Kfp1rjNxjdp9wzw_YvuwIHxC_-bUD39gIgtkQGhNLNdB0.o0GzA61hhEjWszcxyOt4beXZPESRkmx7jC-MO-RDixY&dib_tag=se&keywords=Mindset%3A+The+New+Psychology+of+Success&qid=1715593356&sprefix=mindset+the+new+psychology+of+success%2Caps%2C194&sr=8-2",
    },
    {
      image: money,
      book_name: "The Psychology of Money",
      link: "https://www.amazon.in/Psychology-Money-Morgan-Housel/dp/9390166268/ref=sr_1_2?crid=2P9DIZZZO4Z8&dib=eyJ2IjoiMSJ9.ueQnEG9br0m11BFiFkG_MYdPy72WABpfGMsQcqkNe32jsxkGrxIBxMcCA_nF3cIOw8SxzFMtuIH-pIP1HH87sGozLRXEf8c2IpE_Oot36IeDaqDq4Vxkh3YY4U5cOOeCSmDleJtYsH8iGX4FsVlj9gvYLGSauWUUo4094Jn9NbHFwSQGmaU3IJY2XihSG8n6DDDc3TipZalJT8EUlmSp1f4zIoAHaaq8_SdQR8DM9bI.iL97AaCcSn1ZGaRI9FACwv2C62Mh5spp1j0Pf6o6VWE&dib_tag=se&keywords=The+Psychology+of+Money&qid=1715593494&sprefix=the+psychology+of+money%2Caps%2C201&sr=8-2",
    },
  ];
  return (
    <div>
      <LeftSideAd />
      <RightSideAd />
      <div className="container max-w-7xl mx-auto mt-14">
        <div className="flex justify-center">
          <h1 className="md:text-[37px] font-bold text-[#000] text-[28px] text-center">
            Books to Read for Becoming a Rich
          </h1>
        </div>
        <div className="mt-7">
          <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8">
            {booksData?.map((book, index) => (
              <React.Fragment key={index}>
                <div className="">
                  <div className="bg-white m-3 p-4 border border-[#cacaca]">
                    <div className="border-b border-[#cacaca]">
                      <div className="flex justify-center p-3 mb-4 md:h-60 sm:h-48 h-56 bg-[#F1F1F1]">
                        <img
                          src={book?.image}
                          className="object-cover"
                          alt={book?.book_name}
                        />
                      </div>
                    </div>
                    <div className="">
                      <p className="text-center line-clamp-1 font-semibold mt-3">
                        {book?.book_name}
                      </p>
                      <div className="flex justify-center mt-2">
                        <a
                          href={book?.link}
                          target="_blank"
                          rel="noreferrer"
                          className="no-underline"
                        >
                          <div className="w-[130px] ">
                            <img src={amazon} alt="" className="w-full" />
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {index === 3 && (
                  <div className="lg:col-span-4 md:col-span-3 sm:col-span-2 col-span-1 mt-4">
                    <LongAdCard />
                  </div>
                )}
                {index === 7 && (
                  <div className="lg:col-span-4 md:col-span-3 sm:col-span-2 col-span-1 mt-4">
                    <LongAdCard02 />
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default Books;
