import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Base_Url from '../../api/baseUrl'
import ReactPaginate from 'react-paginate'
import BackToTop from '../BackToTop/backToTop'
import IdeaForm from '../Idea-form/idea-form'
import AdResponsive07 from '../ads/AdResponsive07'

const ViewFinancialIdea = () => {
    const [financialIdea, setFinancialIdea] = useState([])
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(false)
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false)
    const limit = 10;
    useEffect(() => {
        const ideaView = async () => {
            setLoading(true)
            try {
                const response = await axios.get(`${Base_Url}api/getidea?limit=${limit}&page=${page + 1}`)
                setLoading(false)
                setFinancialIdea(response?.data?.data)
                setPageCount(response?.data?.totalPage)
            } catch (error) {
            }
        }
        ideaView()
    }, [page, limit])
    const updateScreenSize = () => {
        setIsSmallScreen(window.innerWidth < 640);
    };

    useEffect(() => {
        updateScreenSize();
        window.addEventListener("resize", updateScreenSize);

        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);
    return (
        <>
            <div className='px-5'>
                <div className='container max-w-7xl mx-auto'>
                    <h1 className='flex justify-center text-[#000] md:text-[40px] text-[30px] mt-[3rem] max-[768px]:my-[2rem] font-semibold max-[768px]:text-justify'>All Financial Idea</h1>
                    <div className='flex justify-end'>
                        <button className='py-3 px-5 font-semibold bg-[#F06939] text-[17px] text-[#fff] rounded-md' onClick={() => setIsModelOpen(true)}>Share Your Idea</button>
                    </div>
                    {
                        loading ? <div className='flex justify-center'>
                            <div className='loader my-5 !w-[40px]'></div>
                        </div> :
                            <div className='my-[3rem] max-[768px]:my-[2rem]'>
                                <div className='grid lg:grid-cols-2 grid-cols-1 gap-9'>
                                    {
                                        financialIdea && financialIdea?.map((idea, index) => {
                                            const cardIndex = index + 1;
                                            return <>
                                                <div className='rounded-[10px] px-5 py-3' key={index} style={{ boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px" }}>
                                                    <div className='sm:flex'>
                                                        <div className='flex justify-center'>
                                                            <div className='sm:h-28 h-[200px] w-full sm:w-52'>
                                                                <img src={idea?.image_url[0]} alt='' className='h-full w-full object-cover' />
                                                            </div>
                                                        </div>
                                                        <div className='w-full mt-2'>
                                                            <div className='sm:ms-6 flex flex-col justify-between h-full'>
                                                                <h3 className='text-black text-[19px] max-[768px]:text-[1.3rem] line-clamp-1 font-semibold'>{idea?.topic}</h3>
                                                                <p className='line-clamp-2'>{idea?.description}</p>
                                                                <a href='/auth/sign-in'>
                                                                    <div className='flex'>
                                                                        <button className='h-8 bg-[#F06939] text-[#fff] px-4 rounded-md font-bold mt-2'>Read More..</button>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {cardIndex % 6 === 0 && cardIndex !== financialIdea?.length && (
                                                    <div key={`ad-${cardIndex}`}  className='lg:col-span-2 py-5'>
                                                        <AdResponsive07 />
                                                    </div>
                                                )}
                                            </>
                                        })
                                    }
                                </div>
                            </div>
                    }
                </div>
                {!loading && financialIdea?.length > 0 &&
                    <div className='flex justify-center mt-4 my-3 max-[768px]:mt-0'>
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={isSmallScreen ? 1 : 2}
                            pageRangeDisplayed={isSmallScreen ? 1 : 3}
                            onPageChange={(e) => {
                                setPage(e.selected)
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            forcePage={page}
                        />
                    </div>
                }
                <BackToTop />
              
            </div>
            {
                isModelOpen && <IdeaForm isOpen={isModelOpen} onClose={() => setIsModelOpen(false)} />
            }
        </>
    )
}

export default ViewFinancialIdea