import React from 'react'
import welcome from "../../assests/Group 321.png"
import welcome1 from "../../assests/close-up.png"

const Welcome = () => {
    return (
        <div className=''>
            {/* <Ad300_250/> */}
            <div className='container max-w-7xl mx-auto'>
                <a href='millionaires' className='no-underline'>
                    <div className='grid md:grid-cols-2 grid-cols-1 py-16 max-[1024px]:py-14 gap-5'>
                        <div className=''>
                            <div className='md:h-[570px]'>
                                <img src={welcome} className='h-full w-full object-cover md:flex hidden' alt="" />
                                <img src={welcome1} className='h-full w-full object-cover md:hidden flex' alt="" />
                            </div>
                        </div>
                        <div className='flex justify-center flex-col'>
                            <p className='font-semibold text-[36px] max-[1100px]:text-[32px] max-[900px]:text-[25px] max-[768px]:text-[21px]'>Case studies of businesses that grew from small startups to successful enterprises</p>
                            <hr className='w-[120px] border-2 border-[#F06939] my-3 max-[1100px]:my-2' />
                            <p className='text-[18px] text-[#535353] mt-2 tracking-[0.5px] max-[1100px]:text-[16px] max-[768px]:text-[15px]'>Feature stories of successful business owners and entrepreneurs who aren't widely known but have built successful companies.</p>
                            <p className='text-[18px] text-[#535353] mt-7 max-[1100px]:mt-4 tracking-[0.5px] max-[1100px]:text-[16px] max-[768px]:text-[15px]'>With limited funds and resources, the team faced significant hurdles, including securing initial funding, developing their product, and standing out in a competitive market.</p>
                            <div className="grid lg:grid-cols-2 grid-cols-1 items-center mt-10 max-[1100px]:mt-4">
                                <div className="flex items-center space-x-2 lg:mb-4 mb-3">
                                    <div className="bg-[#F06939] lg:w-4 lg:h-4 w-3.5 h-3.5 transform rotate-45 me-2"></div>
                                    <span className="font-semibold tracking-[0.5px] text-[18px] max-[1100px]:text-[16px] max-[768px]:text-[15px]">Technology And Innovation</span>
                                </div>
                                <div className="flex items-center space-x-2 lg:mb-4 mb-3">
                                    <div className="bg-[#F06939] lg:w-4 lg:h-4 w-3.5 h-3.5 transform rotate-45 me-2"></div>
                                    <span className="font-semibold tracking-[0.5px] text-[18px] max-[1100px]:text-[16px] max-[768px]:text-[15px]">Initial Challenges</span>
                                </div>
                                <div className="flex items-center space-x-2 lg:mb-4 mb-3">
                                    <div className="bg-[#F06939] lg:w-4 lg:h-4 w-3.5 h-3.5 transform rotate-45 me-2"></div>
                                    <span className="font-semibold tracking-[0.5px] text-[18px] max-[1100px]:text-[16px] max-[768px]:text-[15px]">Breakthrough Moment</span>
                                </div>
                                <div className="flex items-center space-x-2 lg:mb-4 mb-3">
                                    <div className="bg-[#F06939] lg:w-4 lg:h-4 w-3.5 h-3.5 transform rotate-45 me-2"></div>
                                    <span className="font-semibold tracking-[0.5px] text-[18px] max-[1100px]:text-[16px] max-[768px]:text-[15px]">Growth And Expansion</span>
                                </div>
                            </div>
                            <div>
                                <button className='bg-[#F06939] text-white px-7 py-3 font-semibold flex items-center lg:mt-4 mt-2'>More From Us</button>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Welcome