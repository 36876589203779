import React from 'react'
import Slider from 'react-slick';
import exploreImg from "../../assests/Mask group.png"
import { GoArrowRight } from 'react-icons/go';

const Club = ({ stepData }) => {

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };


    return (
        <div className='mt-[100px]'>
            <div className="relative">
                <img src={exploreImg} alt="Background" className="w-full md:h-[600px] h-[500px] object-cover" />
                <div className="absolute top-0 left-0 w-full h-full flex flex-col pt-28 items-center bg-black bg-opacity-50">
                    <h3 className="text-[#F06939] md:text-2xl text-xl">Explore Business</h3>
                    <h1 className="text-white lg:text-5xl md:text-3xl text-2xl font-bold mt-2 tracking-[0.5px]">We Love What We Do</h1>
                </div>
            </div>

            <div className="flex justify-center mt-[-120px] container max-w-[1600px] mx-auto px-3">
                <Slider {...settings} className="p-0 pb-[36px]" style={{ overflow: "hidden" }}>
                    {stepData?.map((data, index) => (
                        <div className='mt-3' key={index}>
                            <a href={`/success-step?${data.link}`} className='no-underline'>
                                <div className='text-center bg-white px-6 py-8 max-[1300px]:p-5 border border-[#C8C8C8]'>
                                    <h5 className='flex justify-center text-[#6c757d] md:text-[75px] text-[55px]'>{data?.icon}</h5>
                                    <h3 className='text-[23px] max-[1250px]:text-[20px] font-semibold lg:mt-5 mt-3 lg:line-clamp-1 md:line-clamp-2'>{data?.title}</h3>
                                    <p className='cursor-pointer lg:mt-5 mt-3 md:text-[18px] text-[17px] line-clamp-2 text-[#6c757d]'>{data?.description}</p>
                                    <p className='flex items-center font-medium text-[19px] mt-5 justify-center'>Read More <GoArrowRight size={24} className='ms-2' /></p>
                                </div>
                            </a>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}

export default Club