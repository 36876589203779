import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactPaginate from "react-paginate";
import Base_Url from "../../api/baseUrl";
import AdResponsive07 from "../../Components/ads/AdResponsive07";
import GoogleAdsScriptCard from "../../Components/ads/GoogleAdsScriptCard";
import LinkModel from "./linkModel";
import BackToTop from "../../Components/BackToTop/backToTop";
import AdResponsive04 from "../../Components/ads/AdResponsive04";
import AdResponsive03 from "../../Components/ads/AdResponsive03";
import AdResponsive01 from "../../Components/ads/AdResponsive01";
import AdResponsive02 from "../../Components/ads/AdResponsive02";
// import AdResponsive05 from "../../Components/ads/AdResponsive05";
import AdResponsive06 from "../../Components/ads/AdResponsive06";
import BlogPage from "../Blog-page";
import ViewFinancialIdea from "../../Components/View-idea/view financial-idea";
// import RightSideAd from "../../Components/ads/RightSideAd";
// import LeftSideAd from "../../Components/ads/LeftSideAd";

const SuccessStory = () => {
  const [page, setPage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const limit = 9;

  const adsArray = [
    <AdResponsive04 />,
    <AdResponsive07 />,
    <AdResponsive03 />,
    <AdResponsive01 />,
    <AdResponsive02 />,
    <AdResponsive06 />,
  ];

  const updateScreenSize = () => {
    setIsSmallScreen(window.innerWidth < 640);
  };

  useEffect(() => {
    updateScreenSize();
    window.addEventListener("resize", updateScreenSize);

    return () => window.removeEventListener("resize", updateScreenSize);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const reloadAds = () => {
    if (window.googletag && window.googletag.cmd) {
      window.googletag.cmd.push(function () {
        window.googletag.display("div-gpt-ad-1721020421262-0");
        window.googletag.display("div-gpt-ad-1721020424421-0");
        window.googletag.display("div-gpt-ad-1721020427956-0");
        window.googletag.display("div-gpt-ad-1726053586752-0");
        window.googletag.display("div-gpt-ad-1722943950051-0");
        window.googletag.display("div-gpt-ad-1722943946813-0");
        window.googletag.display("div-gpt-ad-1722943952843-0");
        window.googletag.display("div-gpt-ad-1721020442767-0");
        window.googletag.display("div-gpt-ad-1721020436899-0");
        window.googletag.display("div-gpt-ad-1721020439738-0");
      });
    }
    if (window.googletag && window.googletag.pubads) {
      try {
        window.googletag.pubads().refresh();
      } catch (error) {
        console.error("Failed to refresh ads:", error);
      }
    }
  };

  useEffect(() => {
    reloadAds();
  }, [page]);

  const [billionaireData, setBilionaireData] = useState([]);
  const [openModel, setOpenModel] = useState(false);

  useEffect(() => {
    const allBillionaireData = async () => {
      setLoading(true);
      const response = await axios.get(
        `${Base_Url}api/get-billionaire?page=${page + 1}&limit=${limit}`
      );
      setLoading(false);
      if (response) {
        setBilionaireData(response?.data?.data);
        setPageCount(response?.data?.totalPage);
      }
    };
    allBillionaireData();
  }, [page, limit]);

  const [adDisplayed, setAdDisplayed] = useState(false);

  const handleCardClick = () => {
    let clickCount = localStorage.getItem("clickCount") || 0;
    let click = Number(clickCount);
    localStorage.setItem("clickCount", click + 1);
    if (clickCount === 3) {
      setAdDisplayed(true);
    }
    if (clickCount >= 3) {
      localStorage.setItem("clickCount", "0");
    }
  };

  const handleCloseModal = () => {
    setOpenModel(false);
  };

  return (
    <>
      

      <div className="px-4">
        {adDisplayed && <GoogleAdsScriptCard />}
        <div className="container max-w-7xl mx-auto pt-[2rem] lg:px-0">
          {loading ? (
            <div className="flex justify-center">
              <div className="loader my-5 !w-[40px]"></div>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {billionaireData?.map((x, index) => {
                const showAd = index < 6;
                const adComponent = adsArray[index % adsArray.length];
                return (
                  <>
                    <div
                      className="mt-5 cursor-pointer border border-[#cacaca] rounded-md h-fit"
                      key={index}
                      onClick={() => {
                        handleCardClick();
                        window.location.href = `/millionaire?name=${x?.slug}`;
                      }}
                    >
                      <div className="">
                        <div className="h-[330px] ">
                          <img
                            src={x?.image}
                            className="h-full w-full object-cover"
                            alt={x?.image}
                          />
                        </div>
                        {showAd && adComponent}
                      </div>
                      <div className="p-5">
                        <div className="flex justify-end">
                          <p className="mb-0 text-gray-400 text-sm">
                            Last Updated At:{" "}
                            {new Date(
                              x?.updated_at ? x?.updated_at : x?.created_at
                            )?.toLocaleDateString("en-US", {
                              day: "2-digit",
                              month: "long",
                              year: "numeric",
                            })}
                          </p>
                        </div>
                        <h1 className="text-[#000] text-center line-clamp-2 md:text-[28px] text-[20px] font-semibold mt-3">
                          {/* {uniqueNumber}.  */}
                          {x?.name}
                        </h1>
                        <span
                          dangerouslySetInnerHTML={{ __html: x?.description }}
                          className=" max-[768px]:text-justify max-[768px]:text-[16px] line-clamp-3 mt-3 text-[17px]"
                        />
                        <div className="flex justify-center mt-3">
                          <a
                            href={`/millionaire?name=${x?.slug}`}
                            className="no-underline flex"
                          >
                            <p
                              className="text-[#fff] bg-[#F06939] rounded-sm py-2 px-5 mt-2 font-semibold cursor-pointer"
                              onClick={() => {
                                handleCardClick();
                              }}
                            >
                              Read More...
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          )}
          <div className="flex justify-center mt-8">
            <h3 className="w-[80%] max-[1024px]:w-[93%] text-center text-[#000] font-medium md:text-[20px] text-[15px]">
              Explore in-depth profiles and stories of various rich individuals
              and millionaires to understand their journeys, achievements, and
              the strategies that led to their immense success.{" "}
            </h3>
          </div>
          <div className="flex justify-center">
            <h3
              className="text-center text-[#fff] bg-[#F06939] py-2 px-3 font-semibold cursor-pointer rounded-sm mt-1"
              onClick={() => setOpenModel(true)}
            >
              Click Here...
            </h3>
          </div>
          {/* <AdResponsive01 /> */}
          <div className="flex justify-center mt-[33px] max-[768px]:mt-[10px]">
            {!loading && billionaireData?.d > 0 && (
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={pageCount}
                marginPagesDisplayed={isSmallScreen ? 1 : 2}
                pageRangeDisplayed={isSmallScreen ? 1 : 3}
                onPageChange={(e) => {
                  setPage(e.selected);
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                forcePage={page}
              />
            )}
          </div>
        </div>
        <div className="mt-5">
          <BlogPage />
          <ViewFinancialIdea />
        </div>
        {openModel && (
          <LinkModel isOpen={openModel} onClose={handleCloseModal} />
        )}
        <BackToTop />
      </div>
    </>
  );
};

export default SuccessStory;
