import streetStyle from "../../assests/street-style.png";
import weekSpring from "../../assests/week-spring.png";

export const celebrityBlog = [
  {
    id: "1",
    title: "The Highest Paid Celebrities in the World",
    image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Mask+group+(7).png",
    blogDetails: [
      {
        id: "1",
        name: "Kylie Jenner",
        work: "Founder, Kylie Cosmetics",
        netWorth: "$590M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/0x0.webp",
        age: "27",
        SourceofWealth: "Cosmetics",
        Residence: "Hidden Hills, California",
        Citizenship: "United States",
        MaritalStatus: "Single",
        description: "Kylie Jenner is an entrepreneur in the cosmetics industry and a reality TV personality, belonging to the Kardashian-Jenner family.",
        otherDetails: [
          `Star of "Keeping Up With The Kardashians," Jenner launched a line of lip kits in 2015 using $250,000 she earned from modelling.`,
          `In January 2020, she sold 51% of Kylie Cosmetics to the publicly traded Coty, Inc. for $600 million, taking home around $540 million before taxes.`,
          `She lost her status as a billionaire since records revealed that the company was far less than the family had long represented.`,
          `An estimated 44% of Kylie Cosmetics is still owned by her.`
        ]
      },
      {
        id: "2",
        name: "Kanye West",
        work: "Musician",
        netWorth: "$400M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/0x0.jpg",
        age: "47",
        SourceofWealth: "music, sneakers",
        Residence: "Los Angeles, California",
        Citizenship: "United States",
        MaritalStatus: "Divorced",
        description: "After making antisemitic remarks, artist and entrepreneur Kanye West lost his lucrative multi-year contract to create the Adidas footwear line, Yeezy.",
        otherDetails: [
          `Balenciaga, Gap, and CAA are among the other brands and corporations who severed their relationship with West, who legally changed his name to Ye.`,
          `Forbes said the Adidas agreement was worth $1.5 billion. West's wealth decreases to $400 million without it.`,
          `His present wealth consists of cash, property, the value of his record collection, and a five percent share in Skims, the shapewear company founded by his ex-wife Kim Kardashian West.`,
        ]
      },
      {
        id: "3",
        name: "Tyler Perry",
        work: "Director, Television",
        netWorth: "$1.4B",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/0x0+(1).jpg",
        age: "54",
        SourceofWealth: "Movies, television",
        Residence: "Atlanta, Georgia",
        Citizenship: "United States",
        MaritalStatus: "In Relationship",
        description: `Tyler Perry is a director, actor, producer, and writer. His "Madea" franchise has brought in over $660 million in revenue.`,
        otherDetails: [
          `Prior to making the move to film and television in the 2000s, Perry began her career in live theater in the 1990s and quickly rose to fame.`,
          `Perry owns all of the stuff he has produced, thus his fortune is derived from both his cut as a producer and a library that dates back to the early 1990s.`,
          `He inaugurated Tyler Perry Studios in 2019, a 330-acre complex in Atlanta featuring 12 sound stages and personalized sets that feature a scale replica of the White House.`,
          `In 2019, after working for Oprah Winfrey's OWN for seven years, Perry signed a similar contract with Viacom, gaining 25% of the streaming channel BET+.`
        ]
      },
      {
        id: "4",
        name: "Dwayne Johnson",
        work: "Actor, Film",
        netWorth: "$87.5M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/0x0+(2).jpg",
        age: "52",
        SourceofWealth: "Movies",
        Residence: "Miami, Florida",
        Citizenship: "United States",
        MaritalStatus: "Married",
        description: `Well-known as "The Rock," Johnson has the biggest salary of any actor in the world because of his upcoming roles in "Black Adam" and "Red Notice."`,
        otherDetails: [
          `Thanks to a slew of box office successes, the former wrestler can now earn more than $20 million per film.`,
          `In addition, he makes money from small-screen shows including the upcoming series "Young Rock" and the NBC competition show "The Titan Games".`,
          `Recognized for dedicating a significant amount of time to working out, his popular Project Rock Under Armour line offers apparel, footwear, and headphones.`
        ]
      },
      {
        id: "5",
        name: "Rush Limbaugh",
        work: "Radio",
        netWorth: "$85M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/0x0+(3).jpg",
        age: "73",
        SourceofWealth: "Radio",
        Residence: "Palm Beach, Florida",
        Citizenship: "United States",
        MaritalStatus: "Married",
        description: `The conservative talk radio broadcaster still has a lot of airtime despite having advanced lung cancer.`,
        otherDetails: [
          `His contract with Premiere Radio Networks, which pays him over $70 million a year for his broadcasts, was renewed in January.`,
          `In February 2020, Donald Trump's friend Limbaugh received the Presidential Medal of Freedom, a decision that caused some controversy.`,
          `Along with publishing seven books, including a five-title children's series called "Rush Revere," Limbaugh also charges a fee to view videos of his radio shows.`
        ]
      },
      {
        id: "6",
        name: "Ellen DeGeneres",
        work: "Television shows",
        netWorth: "$84M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/0x0+(4).jpg",
        age: "66",
        SourceofWealth: "Television shows",
        Residence: "Carpinteria, California",
        Citizenship: "United States",
        MaritalStatus: "Married",
        description: `Launched in 2003, Ellen DeGeneres' daytime talk show "The Ellen DeGeneres Show" ran until 2022.`,
        otherDetails: [
          `She declared in 2021 that her show would conclude following its 19th season.
Stand-up comedian Ellen DeGeneres became well-known in the 1990s for her sitcom "Ellen." She has also developed television programs such as "Little Big Shots."`,
          `Living with her spouse, Portia de Rossi, in Montecito, California, DeGeneres, an enthusiastic property flipper, bought a $70 million estate in 2022.`,
          `She became an activist in the LGTBQ community after coming out as LGBT in 1997, supporting organizations including GLAAD, the Human Rights Campaign, and the Trevor Project.`
        ]
      }
    ]
  },
  {
    id: "2",
    title: "India's richest celebrities",
    image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Mask+group+(6).png",
    date: "11 september,2024",
    blogDetails: [
      {
        id: "1",
        name: "Shah Rukh Khan",
        work: "Actor, Film",
        netWorth: "$770M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Shah_Rukh_Khan_graces_the_launch_of_the_new_Santro.jpg.webp",
        age: "58",
        SourceofWealth: "Actor, Film",
        Residence: "Mumbai",
        Citizenship: "India",
        MaritalStatus: "Married",
        description: "Shah Rukh Khan's net worth is estimated to be around $725 million to $770 million, making him one of the wealthiest actors in the world.",
        otherDetails: [
          `Khan has earned substantial wealth from acting in over 80 films, many of which have been massive hits in India and overseas. He commands a high fee per film, often in the range of ₹50-100 crore.`,
          `SRK is one of the most sought-after celebrities for brand endorsements, having worked with top global brands like Pepsi, Hyundai, and Tag Heuer. His endorsement earnings contribute significantly to his overall wealth.`,
          `Shah Rukh co-owns Red Chillies Entertainment, a production company that has produced several hit films. This venture has been a major source of revenue for him.`,
          `Khan co-owns the IPL cricket team Kolkata Knight Riders, which is valued at around $1 billion. KKR's success in the IPL has further boosted his net worth.`,
          `SRK owns several high-end properties worldwide, including his iconic home "Mannat" in Mumbai, which is valued at approximately ₹200-300 crore, and properties in Dubai, London, and elsewhere.`
        ]
      },
      {
        id: "2",
        name: "Salman Khan",
        work: "Actor, Film",
        netWorth: "$400M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Salman_Khan_in_2023_(cropped).jpg",
        age: "58",
        SourceofWealth: "Actor, Film",
        Residence: "Mumbai",
        Citizenship: "India",
        MaritalStatus: "Single",
        description: "Salman Khan’s net worth is estimated to be between $350 million and $400 million, making him one of the wealthiest actors in India.",
        otherDetails: [
          `Salman commands one of the highest fees in the Indian film industry. For each movie, he charges approximately ₹50-100 crore, depending on the film. He is known for delivering numerous box-office hits, particularly in the action genre.`,
          `Salman Khan endorses several major brands, including Pepsi, Suzuki, Relaxo, and Chlormint, earning a significant income from these deals. His endorsement income is reported to be around ₹15-20 crore per brand.`,
          `Salman owns Salman Khan Films (SKF), a successful production company that has produced blockbuster movies like Bajrangi Bhaijaan and Race 3. SKF is a major revenue stream for him.`,
          `Salman Khan has been the host of the popular reality TV show Bigg Boss for over a decade. He reportedly charges around ₹20 crore per episode, making him one of the highest-paid TV hosts in India.`
        ]
      },
      {
        id: "3",
        name: "Akshay Kumar",
        work: "Actor, Film",
        netWorth: "$400M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Akshay_Kumar.jpg",
        age: "57",
        SourceofWealth: "Actor, Film",
        Residence: "Mumbai",
        Citizenship: "India",
        MaritalStatus: "Married",
        description: `Akshay Kumar’s net worth is estimated to be around $350 million to $400 million, placing him among the richest actors in India and globally.`,
        otherDetails: [
          `Akshay Kumar is known for his prolific work ethic, often releasing multiple films each year. He charges approximately ₹50-100 crore per movie and is considered one of the most bankable stars in Bollywood.`,
          `Akshay endorses a wide range of brands, from fitness and healthcare products to consumer goods. His endorsement portfolio includes brands like Tata Motors, Harpic, Policybazaar, and Honda Motorcycles. He reportedly earns ₹10-12 crore per endorsement, making him one of the top celebrity endorsers in India.`,
          `Akshay Kumar co-owns Hari Om Entertainment, a production house that has produced several of his films and other successful projects. This venture contributes significantly to his income.`
        ]
      },
      {
        id: "4",
        name: "Rajinikanth",
        work: "Actor, Film",
        netWorth: "$70M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Rajinikanth_Felicitates_Writer_Kalaignanam_1.jpg",
        age: "73",
        SourceofWealth: "Movies",
        Residence: "Chennai",
        Citizenship: "India",
        MaritalStatus: "Married",
        description: `Rajinikanth's net worth is estimated to be around $55 million to $70 million, making him one of the highest-paid actors in the South Indian film industry.`,
        otherDetails: [
          `Rajinikanth has acted in over 160 films across multiple languages including Tamil, Hindi, and Telugu. For recent films, he reportedly charges a fee of ₹50-100 crore per movie. His massive fan following ensures that his films often break box-office records, contributing to his financial success.`,
          `While Rajinikanth is not as active in endorsing brands compared to other Bollywood stars, he still commands high fees for any endorsements he takes on. He has endorsed brands like Gulf Oil and Tata Sky in the past.`,
          `Rajinikanth owns several high-value properties, including a luxurious house in Chennai and multiple real estate investments in Bangalore and other cities. His home in Poes Garden, Chennai, is among his most valuable assets.`
        ]
      },
      {
        id: "5",
        name: "Joseph Vijay",
        work: "Actor, Film",
        netWorth: "$60M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Vijay_at_Protest_of_the_Nadigar_Sangam.jpg",
        age: "50",
        SourceofWealth: "Actor, Film",
        Residence: "Bengaluru, Karnataka",
        Citizenship: "India",
        MaritalStatus: "Married",
        description: `Vijay’s net worth is estimated to be around $50 million to $60 million, making him one of the highest-paid actors in the South Indian film industry.`,
        otherDetails: [
          `Vijay commands a high fee for his films, typically charging between ₹50 crore to ₹100 crore per movie. He has starred in numerous blockbuster hits, contributing significantly to his wealth.`,
          `Vijay is known for his commercial success in films like Mersal, Sarkar, and Master, which have grossed hundreds of crores at the box office, enhancing his earnings from film projects.`,
          `Vijay has endorsed various brands, including Coca-Cola, Tamil Nadu Silk sarees, and Sunfeast. His popularity in the South Indian market allows him to command high fees for endorsements, earning him several crores per brand.`,
          `Vijay has ventured into production with his company, Vijay Productions, which has produced several successful films. This involvement adds to his overall financial portfolio.`
        ]
      },
    ]
  },
  {
    id: "3",
    title: "The Wealthiest Self-Made Women in America",
    image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/image+67.png",
    blogDetails: [
      {
        id: "1",
        name: "Diane Hendricks",
        work: "Cofounder, ABC Supply",
        netWorth: "$21.9B",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/0x0+(5).jpg",
        age: "77",
        SourceofWealth: "Building supplies, Self Made",
        Residence: "Afton, Wisconsin",
        Citizenship: "United States",
        MaritalStatus: "Widowed",
        description: "One of the biggest wholesale distributors of windows, siding, and roofing in the United States is ABC Supply, which Diane Hendricks is the chair of.",
        otherDetails: [
          `In Beloit, Wisconsin, Hendricks and her late husband Ken cofounded the company in 1982. Since his passing in 2007, she has presided over it.`,
          `Under her direction, ABC completed the two largest acquisitions in its history: the purchase of rival Bradco in 2010 and the distributor of building products L&W Supply in 2016.`,
          `The corporation generated $20.4 billion in revenue in 2023 and has over 900 branch locations.`,
          `Before meeting Ken, a roofer, Hendricks worked for a builder selling bespoke homes.`,
          `She has invested millions of dollars in redeveloping entire Beloit blocks and luring several new companies to the state in order to boost the local economy.`
        ]
      },
      {
        id: "2",
        name: "Judy Love & family",
        work: "Love's Travel Stops and Country Stores",
        netWorth: "$13.1B",
        image: "https://imageio.forbes.com/specials-images/imageserve/5b3fa7d1a7ea4341970ddbf3/0x0.jpg?format=jpg&crop=1083,1084,x45,y83,safe&height=416&width=416&fit=bounds",
        age: "87",
        SourceofWealth: "Gas stations, Self Made",
        Residence: "Oklahoma City, Oklahoma",
        Citizenship: "United States",
        MaritalStatus: "Widowed",
        description: "Love's Travel Stops & Country Stores is a chain of truck stops and convenience stores that Judy Love and her late husband Tom (d. 2023) created.",
        otherDetails: [
          `Currently, she owns the business with her four kids. `,
          `Tom's parents loaned the couple $5,000 to lease their first petrol station in Watonga, Oklahoma.`,
          `Up until 1975, when she went back to college, Judy managed the business and kept the books.`,
          `With over 640 locations throughout 42 states, Love's is projected to generate $24 billion in sales.`
        ]
      },
      {
        id: "3",
        name: "Judy Faulkner",
        work: "Founder & CEO, Epic Systems",
        netWorth: "$7.7B",
        image: "https://imageio.forbes.com/specials-images/imageserve/63507a42f764049c77c9a295/0x0.jpg?format=jpg&crop=1080,1080,x0,y0,safe&height=416&width=416&fit=bounds ",
        age: "81",
        SourceofWealth: "Healthcare software, Self Made",
        Residence: "Madison, Wisconsin",
        Citizenship: "United States",
        MaritalStatus: "Married",
        description: `In 1979, Judy Faulkner established Epic Systems, a provider of medical record software, in her Wisconsin basement.`,
        otherDetails: [
          `The CEO of the $4.9 billion (2023 sales) corporation, of which Faulkner controls 47%, is a computer programmer.`,
          `Top hospitals like Johns Hopkins and Mayo Clinic use Epic, which supports the medical records of over 250 million patients.`,
          `The business develops all of its software internally and has never sought venture money or completed an acquisition.`,
          `In 2015, Faulkner committed to donating 99% of her assets to a private charitable foundation by signing the Giving Pledge. `
        ]
      },
      {
        id: "4",
        name: "Thai Lee",
        work: "IT provider, Self Made",
        netWorth: "$7B",
        image: "https://imageio.forbes.com/specials-images/imageserve/5d8afd6822254b0008e1672a/0x0.jpg?format=jpg&crop=3404,3407,x49,y938,safe&height=416&width=416&fit=bounds",
        age: "65",
        SourceofWealth: "IT provider, Self Made",
        Residence: "Austin, Texas",
        Citizenship: "United States",
        MaritalStatus: "Divorced",
        description: `Thai Lee is the CEO of SHI International, a $14 billion (sales) IT supplier with 15,000 clients, including AT&T and Boeing.`,
        otherDetails: [
          `Lee was born and raised in Bangkok, South Korea, before coming to the United States for high school. `,
          `Lee's father was the first Korean graduate of Amherst, a renowned economist who toured the globe endorsing his nation's postwar economic strategy.`,
          `Lee, a fellow Harvard Business School alum, held positions at American Express and Procter & Gamble.`,
          `In 1989, Lee and her now-ex-husband purchased the software reseller that would later become SHI for less than $1 million. She now owns 60% of the company, he owns 40%.`
        ]
      },
    ]
  },
  {
    id: "4",
    title: "Top Most Popular Chinese Actors in 2024",
    image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Mask+group+(8).png",
    blogDetails: [
      {
        id: "1",
        name: "Xu Kai",
        work: "Actor, Model",
        netWorth: "$5M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Xu+Kai.jpg",
        age: "28",
        SourceofWealth: "Acting, Modeling",
        Residence: "Beijing, China",
        Citizenship: "China",
        MaritalStatus: "Single",
        description: "Xu Kai is a well-known Chinese actor and model, famous for his roles in popular Chinese dramas like 'The Legends' and 'Arsenal Military Academy'.",
        otherDetails: [
          "Xu Kai rose to fame with his role in the hit drama 'Story of Yanxi Palace' in 2018.",
          "He won the 'Best New Actor' award at the Tencent Video Star Awards in 2018.",
          "Apart from acting, Xu Kai is also a model and has been the face of several major brands in China.",
          "He continues to work on new television dramas and movies, gaining a growing fanbase both domestically and internationally."
        ]
      },
      {
        id: "2",
        name: "Luo Yun Xi",
        work: "Actor, Singer, Dancer",
        netWorth: "$8M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/74d47076d431e782b0bee3de4a0b84f4.jpg",
        age: "35",
        SourceofWealth: "Acting, Singing, Endorsements",
        Residence: "Shanghai, China",
        Citizenship: "China",
        MaritalStatus: "Single",
        description: "Luo Yun Xi, also known as Leo Luo, is a talented Chinese actor, singer, and dancer known for his versatile acting skills and captivating performances in various popular Chinese dramas.",
        otherDetails: [
          "Luo Yun Xi initially debuted as a member of the boy group JBOY3 before transitioning into acting.",
          "He gained widespread recognition for his role in the drama 'Ashes of Love' (2018) and has since starred in multiple successful TV series like 'Love is Sweet' and 'Broker'.",
          "Luo Yun Xi is also known for his dedication to his roles, often undergoing intense training for physically demanding performances.",
          "Apart from his acting career, he has a strong presence in the fashion and endorsement industry, working with brands like Bulgari and L'Oreal."
        ]
      },
      {
        id: "3",
        name: "Xiao Zhan",
        work: "Actor, Singer",
        netWorth: "$10M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Xiao+Zhan.jpg",
        age: "32",
        SourceofWealth: "Acting, Singing, Endorsements",
        Residence: "Beijing, China",
        Citizenship: "China",
        MaritalStatus: "Single",
        description: "Xiao Zhan, also known as Sean Xiao, is a Chinese actor and singer who gained international fame through his role in the popular drama 'The Untamed'. He is also a member of the Chinese boy band X NINE.",
        otherDetails: [
          "Xiao Zhan became widely popular after his breakthrough role in the drama 'The Untamed' (2019), which earned him a massive fanbase both in China and internationally.",
          "He has released several solo singles that have topped music charts, including 'Spotlight,' which became one of the best-selling digital singles worldwide.",
          "Xiao Zhan has won numerous awards for his acting and music, and he is considered one of the most influential celebrities in China.",
          "He is also highly sought after for brand endorsements, working with global brands like Gucci, Estée Lauder, and Zenith."
        ]
      },
      {
        id: "4",
        name: "Chen Zhe Yuan",
        work: "Actor, Model",
        netWorth: "$4M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/862e362b8056b410eb89119d95346d52.jpg",
        age: "30",
        SourceofWealth: "Acting, Modeling, Endorsements",
        Residence: "Beijing, China",
        Citizenship: "China",
        MaritalStatus: "Single",
        description: "Chen Zhe Yuan is a Chinese actor and model known for his roles in popular Chinese dramas. He gained fame for his performances in series like 'Addicted' and 'The Untamed'.",
        otherDetails: [
          "Chen Zhe Yuan's career took off with his role in 'Addicted' (2016), which earned him a dedicated fanbase.",
          "He further solidified his status with his performance in 'The Untamed' (2019), where he gained widespread recognition.",
          "Apart from acting, Chen Zhe Yuan is also a successful model, working with various high-profile fashion brands.",
          "He is known for his charismatic screen presence and has been nominated for several awards in the Chinese entertainment industry."
        ]
      },
      {
        id: "5",
        name: "Dylan Wang",
        work: "Actor, Model",
        netWorth: "$6M",
        image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Dylan+Wang.jpg",
        age: "26",
        SourceofWealth: "Acting, Modeling, Endorsements",
        Residence: "Beijing, China",
        Citizenship: "China",
        MaritalStatus: "Single",
        description: "Dylan Wang, also known as Wang He Di, is a Chinese actor and model best known for his role as Dao Ming Si in the popular drama 'Meteor Garden'.",
        otherDetails: [
          "Dylan Wang gained significant fame with his role in 'Meteor Garden' (2018), which was a major hit both in China and internationally.",
          "He has since starred in several other dramas and variety shows, further increasing his popularity.",
          "Dylan Wang is also a prominent model, having worked with various fashion brands and magazines.",
          "He has been recognized for his acting and modeling work with several awards and nominations in the Chinese entertainment industry."
        ]
      }
    ]
  },
  {
    id: "5",
    title: "The Evolution of Chinese Cinema – Iconic Celebrities Leading the Way",
    image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Mask+group+(9).png",
    blogDetails: [
      {
        "id": "1",
        "name": "Jackie Chan",
        "work": "Actor, Martial Artist, Director, Producer",
        "netWorth": "$400M",
        "image": "https://billionaireweb.s3.eu-north-1.amazonaws.com/f725e42a5d2949dfae5bab37e710022d_xl.webp",
        "age": "70",
        "SourceofWealth": "Acting, Martial Arts, Directing, Producing",
        "Residence": "Hong Kong, China",
        "Citizenship": "China",
        "MaritalStatus": "Married",
        "description": "Jackie Chan is a renowned Hong Kong actor and martial artist, famous for his innovative stunts and action choreography. His films have achieved global acclaim and he remains a major figure in both Chinese and international cinema.",
        "otherDetails": [
          "Jackie Chan's career began in the Hong Kong film industry as a child actor. His early experiences included performing stunts and participating in various martial arts training, which laid the foundation for his future success.",
          "In the 1970s, Chan transitioned from being a stuntman to becoming a leading actor. His breakthrough came with the film 'Drunken Master,' which showcased his unique style of combining martial arts with comedy.",
          "Chan's success continued to grow with films like 'Police Story' and 'Project A,' which featured elaborate stunts and action sequences. His dedication to performing his own stunts has become one of his trademarks.",
          "The 1990s marked Chan's successful crossover into Hollywood with films such as the 'Rush Hour' series. His international success helped him build a global fanbase and increased the visibility of Asian cinema in the West.",
          "Jackie Chan has also ventured into directing and producing. His directorial efforts include films like 'The Young Master' and 'The Legend of Drunken Master,' where he applied his creative vision to both action and storytelling."
        ]
      },
      {
        "id": "2",
        "name": "Bruce Lee",
        "work": "Actor, Martial Artist, Director, Philosopher",
        "netWorth": "$10M",
        "image": "https://billionaireweb.s3.eu-north-1.amazonaws.com/bruce-lee-1_43.jpeg",
        "age": "32",
        "SourceofWealth": "Acting, Martial Arts, Directing",
        "Residence": "Hong Kong, China",
        "Citizenship": "China",
        "MaritalStatus": "Married",
        "description": "Bruce Lee is a legendary martial artist and actor whose films revolutionized the action genre. His philosophy and unique fighting style have had a lasting impact on cinema worldwide.",
        "otherDetails": [
          "Bruce Lee began his career as a child actor in Hong Kong cinema. His early roles were in films and television series, where he showcased his martial arts skills and charisma.",
          "In the late 1960s and early 1970s, Lee's rise to fame was marked by his work in films such as 'The Big Boss' and 'Fist of Fury.' These films showcased his exceptional martial arts abilities and solidified his reputation as a leading action star.",
          "Lee's 1973 film 'Enter the Dragon' was a major success and became a global hit. It was instrumental in bringing martial arts cinema to the Western audience and cemented Lee's status as an international icon.",
          "Despite his untimely death in 1973, Bruce Lee's influence on martial arts and cinema has continued to grow. His innovative fighting techniques and philosophy have inspired generations of actors and martial artists.",
          "Lee's legacy extends beyond film. He was also a philosopher and writer, and his teachings on martial arts and personal development have had a profound impact on many.",
          "Bruce Lee's life and career have been celebrated through various documentaries, books, and films. His influence is still felt today, and he remains a revered figure in both martial arts and cinema."
        ]
      },
      {
        "id": "3",
        "name": "Gong Li",
        "work": "Actress",
        "netWorth": "$100M",
        "image": "https://billionaireweb.s3.eu-north-1.amazonaws.com/gong-li.jpg",
        "age": "58",
        "SourceofWealth": "Acting",
        "Residence": "Beijing, China",
        "Citizenship": "China",
        "MaritalStatus": "Married",
        "description": "Gong Li is a celebrated Chinese actress known for her work in both domestic and international films. Her performances in films like 'Raise the Red Lantern' and 'Memoirs of a Geisha' have garnered critical acclaim.",
        "otherDetails": [
          "Gong Li made her film debut in the 1987 film 'Red Sorghum,' directed by Zhang Yimou. This role established her as a major talent in Chinese cinema and marked the beginning of her successful collaboration with Yimou.",
          "She gained international recognition with her role in 'Raise the Red Lantern' (1991), which was nominated for an Academy Award for Best Foreign Language Film. Her portrayal of the lead character earned her critical acclaim and numerous awards.",
          "Gong Li's work with Zhang Yimou continued with films such as 'The Story of Qiu Ju' (1992) and 'To Live' (1994), both of which further solidified her reputation as a leading actress in Chinese cinema.",
          "In 2005, Gong Li appeared in 'Memoirs of a Geisha,' which introduced her to a global audience and earned her additional recognition in international cinema.",
          "Her performances have earned her multiple awards, including the Golden Rooster Award and the Hong Kong Film Award. She is celebrated for her versatility and ability to portray complex characters.",
          "Gong Li remains an influential figure in Chinese cinema and continues to work on a diverse range of film projects. Her contributions to film have made her one of the most respected actresses in the industry."
        ]
      },
      {
        "id": "4",
        "name": "Zhang Ziyi",
        "work": "Actress",
        "netWorth": "$40M",
        "image": "https://billionaireweb.s3.eu-north-1.amazonaws.com/Zhang+Ziyi.jpg",
        "age": "45",
        "SourceofWealth": "Acting, Endorsements",
        "Residence": "Shanghai, China",
        "Citizenship": "China",
        "MaritalStatus": "Married",
        "description": "Zhang Ziyi is an acclaimed Chinese actress known for her roles in critically acclaimed films such as 'Crouching Tiger, Hidden Dragon' and 'Hero.' Her international success has made her one of the most recognizable faces in Chinese cinema.",
        "otherDetails": [
          "Zhang Ziyi's career began with her breakthrough role in 'Crouching Tiger, Hidden Dragon' (2000), which was an international success and earned her acclaim for her performance and martial arts skills.",
          "She continued her success with roles in films such as 'Hero' (2002) and 'House of Flying Daggers' (2004), further establishing her as a leading actress in Chinese cinema.",
          "Ziyi's performances have garnered her several awards, including the Hong Kong Film Award for Best Actress and the Golden Horse Award for Best Leading Actress.",
          "In addition to her acting career, Zhang Ziyi has been a prominent figure in endorsements, working with major brands such as Chanel and Louis Vuitton.",
          "Her influence extends beyond cinema, as she has become a global ambassador for Chinese culture and cinema. Her success has helped pave the way for other Chinese actors in international film markets.",
          "Zhang Ziyi continues to work on diverse film projects and remains a prominent figure in both domestic and international cinema."
        ]
      },
      {
        "id": "5",
        "name": "Wong Kar-wai",
        "work": "Director, Screenwriter",
        "netWorth": "$20M",
        "image": "https://billionaireweb.s3.eu-north-1.amazonaws.com/Wong-Kar.webp",
        "age": "65",
        "SourceofWealth": "Directing, Screenwriting",
        "Residence": "Hong Kong, China",
        "Citizenship": "China",
        "MaritalStatus": "Married",
        "description": "Wong Kar-wai is an influential Hong Kong director and screenwriter known for his unique and visually distinctive filmmaking style. His films have won international acclaim and have significantly impacted the global film industry.",
        "otherDetails": [
          "Wong Kar-wai began his career in the Hong Kong film industry as a screenwriter and director. His early works included collaborations with other filmmakers and television projects.",
          "He gained prominence with his 1990 film 'As Tears Go By,' which showcased his distinctive style and laid the groundwork for his future successes.",
          "Wong's films, such as 'Chungking Express' (1994) and 'In the Mood for Love' (2000), are renowned for their innovative narrative structures and visual aesthetics.",
          "His work has received numerous awards, including the Best Director award at the Cannes Film Festival for 'Happy Together' (1997).",
          "Wong's influence extends beyond cinema, impacting fashion and art with his unique visual style. His work has been studied and admired by filmmakers and critics worldwide.",
          "Despite the challenges of the film industry, Wong Kar-wai remains a significant figure in cinema, continuously exploring new creative avenues and pushing the boundaries of filmmaking."
        ]
      }
    ]
  },
  {
    id: "6",
    title: "Stars of Russia: A Look at the Country's Most Influential Celebrities",
    image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Mask+group+(10).png",
    blogDetails: [
      {
        "id": "1",
        "name": "Sergey Lazarev",
        "work": "Singer, Actor, TV Presenter",
        "netWorth": "$10M",
        "image": "https://wiwibloggs.com/wp-content/uploads/2019/03/sergey-lazarev-eurovision-2019.jpg",
        "age": "41",
        "SourceofWealth": "Music, Acting, Television",
        "Residence": "Moscow, Russia",
        "Citizenship": "Russia",
        "MaritalStatus": "Single",
        "description": "Sergey Lazarev is a popular Russian singer, actor, and TV presenter known for his successful pop career and television appearances.",
        "otherDetails": [
          "Lazarev gained fame in the early 2000s with his hit songs and has released several successful albums since then.",
          "He represented Russia in the Eurovision Song Contest multiple times, achieving significant success and recognition.",
          "In addition to his music career, Lazarev has appeared in various Russian TV shows and films, showcasing his versatility as an entertainer.",
          "He is known for his energetic performances and has a strong following both in Russia and internationally.",
          "Lazarev has won several awards for his contributions to music and entertainment, including multiple Russian music awards.",
          "He continues to be a prominent figure in Russian pop culture, actively working on new music projects and television appearances."
        ]
      },
      {
        "id": "2",
        "name": "Ksenia Sobchak",
        "work": "TV Personality, Journalist, Politician",
        "netWorth": "$50M",
        "image": "https://cloudfront-us-east-2.images.arcpublishing.com/reuters/D7KM7S2ZXBNKTFKWNA3P2XYAXU.jpg",
        "age": "42",
        "SourceofWealth": "Media, Politics",
        "Residence": "Moscow, Russia",
        "Citizenship": "Russia",
        "MaritalStatus": "Married",
        "description": "Ksenia Sobchak is a well-known Russian TV personality, journalist, and politician, recognized for her influential role in Russian media and political life.",
        "otherDetails": [
          "Sobchak began her career as a TV host and journalist, gaining prominence with her work on various Russian television programs.",
          "She has been involved in several high-profile media projects and is known for her outspoken views on political and social issues.",
          "In 2018, Sobchak ran for the Russian presidency as a candidate, adding a significant political dimension to her career.",
          "She has also been an advocate for various social causes, including women's rights and freedom of speech.",
          "Sobchak is a prominent figure in Russian society and continues to influence public discourse through her media and political work.",
          "Her contributions to both media and politics have made her a notable and influential celebrity in Russia."
        ]
      },

      {
        "id": "3",
        "name": "Ivan Urgant",
        "work": "TV Host, Actor, Comedian",
        "netWorth": "$15M",
        "image": "https://vtb-league.com/app/uploads/2021/01/IMG_1642-1.jpg",
        "age": "45",
        "SourceofWealth": "Television, Acting, Comedy",
        "Residence": "Moscow, Russia",
        "Citizenship": "Russia",
        "MaritalStatus": "Married",
        "description": "Ivan Urgant is a popular Russian TV host, actor, and comedian known for his work on various entertainment programs and his humor.",
        "otherDetails": [
          "Urgant is best known for hosting the late-night talk show 'Evening Urgant,' which has become one of the most-watched programs in Russia.",
          "He has appeared in numerous films and television series, showcasing his talents as both an actor and comedian.",
          "Urgant's humor and charisma have made him a beloved figure in Russian entertainment, with a large and dedicated fan base.",
          "In addition to his television work, he has been involved in various theatrical productions and has worked as a voice actor.",
          "He has received several awards for his contributions to television and comedy, reflecting his impact on the Russian entertainment industry.",
          "Ivan Urgant continues to be a prominent and influential figure in Russian media and entertainment."
        ]
      },
      {
        "id": "4",
        "name": "Anastasia Volochkova",
        "work": "Ballerina, Actress",
        "netWorth": "$10M",
        "image": "https://penzanews.ru/images/stories/img2014/volochkova03042014_5.jpg",
        "age": "48",
        "SourceofWealth": "Ballet, Acting",
        "Residence": "Moscow, Russia",
        "Citizenship": "Russia",
        "MaritalStatus": "Single",
        "description": "Anastasia Volochkova is a renowned Russian ballerina and actress known for her exceptional performances in ballet and her presence in Russian media.",
        "otherDetails": [
          "Volochkova gained fame as a principal dancer at the Bolshoi Theatre, where she became known for her remarkable skill and artistry in ballet.",
          "She has performed in various international ballet productions and has received acclaim for her interpretations of classic roles.",
          "In addition to her ballet career, Volochkova has appeared in several Russian television programs and films, showcasing her versatility as a performer.",
          "Her contributions to ballet have earned her numerous awards and accolades, solidifying her reputation as one of Russia's leading dancers.",
          "Volochkova is also known for her public appearances and involvement in various social and cultural events.",
          "She continues to be an influential figure in Russian arts and entertainment, inspiring many with her talent and dedication."
        ]
      },
      {
        "id": "5",
        "name": "Danila Kozlovsky",
        "work": "Actor, Director",
        "netWorth": "$8M",
        "image": "https://images.squarespace-cdn.com/content/v1/5accc51696e76f10e151d123/1585915746063-0T4DR7JCKW13AHKGD1FU/dusya_sobol_2-gthumb-gwdata1200-ghdata1200-gfitdatamax.jpg",
        "age": "42",
        "SourceofWealth": "Acting, Directing",
        "Residence": "Moscow, Russia",
        "Citizenship": "Russia",
        "MaritalStatus": "Single",
        "description": "Danila Kozlovsky is a prominent Russian actor and director known for his work in film and theater, with a career marked by a range of critically acclaimed performances.",
        "otherDetails": [
          "Kozlovsky first gained recognition for his roles in Russian television series and films, establishing himself as a talented actor.",
          "His performances in films such as 'Vysotsky: Thank God I'm Alive' and 'Legend No. 17' have earned him critical acclaim and a strong following.",
          "In addition to acting, Kozlovsky has ventured into directing, with his directorial work receiving praise for its creativity and impact.",
          "He has received several awards for his contributions to cinema and theater, highlighting his versatility and skill as an entertainer.",
          "Kozlovsky's work often explores complex characters and themes, showcasing his ability to bring depth to his roles.",
          "He continues to be an influential figure in Russian cinema, working on new projects and expanding his creative endeavors."
        ]
      },
      {
        "id": "6",
        "name": "Yuri Dud",
        "work": "Journalist, Blogger, TV Presenter",
        "netWorth": "$5M",
        "image": "https://www.unaids.org/sites/default/files/thumbnail_image001.jpg",
        "age": "39",
        "SourceofWealth": "Journalism, Blogging, Television",
        "Residence": "Moscow, Russia",
        "Citizenship": "Russia",
        "MaritalStatus": "Married",
        "description": "Yuri Dud is a well-known Russian journalist, blogger, and TV presenter recognized for his influential work in media and his popular online content.",
        "otherDetails": [
          "Dud began his career as a journalist and quickly gained prominence with his engaging and insightful reporting on various topics.",
          "He is known for his popular YouTube channel 'vDud,' where he conducts in-depth interviews with celebrities, politicians, and other prominent figures.",
          "His work has garnered a large following and has been praised for its thoughtful and provocative approach to current events and social issues.",
          "Dud has also worked as a TV presenter and has been involved in several media projects, showcasing his versatility as a media professional.",
          "He has received recognition for his contributions to journalism and online media, reflecting his impact on Russian media landscape.",
          "Yuri Dud continues to be a significant figure in Russian media, influencing public opinion and shaping media discussions through his work."
        ]
      }

    ]
  },
  {
    id: "7",
    title: "From U.S.A. to the World Famous Celebrities Making Waves",
    image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Mask+group+(11).png",
    blogDetails: [
      {
        "id": "1",
        "name": "Sergey Brin",
        "work": "Co-founder of Google",
        "netWorth": "$100B",
        "image": "https://fortune.com/img-assets/wp-content/uploads/2024/07/GettyImages-2076649968-e1721760679875.jpg?w=1440&q=75",
        "age": "51",
        "SourceofWealth": "Technology",
        "Residence": "California, USA",
        "Citizenship": "USA",
        "MaritalStatus": "Divorced",
        "description": "Sergey Brin is a Russian-American computer scientist and internet entrepreneur who co-founded Google Inc. His innovative work in search algorithms has transformed the way people access information online.",
        "otherDetails": [
          "Brin co-founded Google in 1998 with Larry Page, revolutionizing the search engine industry and internet technologies.",
          "Google's success led to numerous other products and services, including Android, YouTube, and Google Cloud.",
          "Brin played a key role in developing Google's search algorithms, which continue to be a critical part of the company's offerings.",
          "He has been involved in various philanthropic efforts, including the Brin Wojcicki Foundation and other initiatives focused on science and education.",
          "Brin is known for his work on Google Glass, a pioneering project in augmented reality technology.",
          "Despite stepping down from his role at Alphabet Inc., Brin remains influential in technology and innovation, with ongoing investments and projects."
        ]
      },
      {
        "id": "2",
        "name": "Maria Sharapova",
        "work": "Former World No. 1 Tennis Player",
        "netWorth": "$195M",
        "image": "https://imgresizer.eurosport.com/unsafe/1200x0/filters:format(jpeg)/origin-imgresizer.eurosport.com/2020/02/26/2784764-57479290-2560-1440.jpg",
        "age": "37",
        "SourceofWealth": "Tennis, Endorsements",
        "Residence": "Los Angeles, USA",
        "Citizenship": "Russia",
        "MaritalStatus": "Single",
        "description": "Maria Sharapova is a retired Russian professional tennis player who has left an indelible mark on the sport with her powerful playing style and competitive spirit. She achieved significant success on the court and has made a successful transition into business.",
        "otherDetails": [
          "Sharapova won five Grand Slam singles titles and held the World No. 1 ranking multiple times throughout her career.",
          "Her career highlights include victories at the Australian Open, French Open, Wimbledon, and the US Open.",
          "Sharapova's endorsement deals with major brands like Nike and Wilson have contributed significantly to her wealth.",
          "She launched her own candy brand, Sugarpova, which has expanded her influence beyond the tennis world.",
          "Sharapova has been involved in numerous philanthropic efforts, including her own foundation supporting children’s education.",
          "Even after retiring from professional tennis, she remains a prominent figure in sports and business."
        ]
      },
      {
        "id": "3",
        "name": "Dmitry Muratov",
        "work": "Journalist, Editor",
        "netWorth": "$10M",
        "image": "https://static.themoscowtimes.com/image/article_1360/6a/545a995a4b5a4f7687ef5c6e8521d601.jpg",
        "age": "61",
        "SourceofWealth": "Journalism",
        "Residence": "Moscow, Russia",
        "Citizenship": "Russia",
        "MaritalStatus": "Married",
        "description": "Dmitry Muratov is a distinguished Russian journalist and editor, celebrated for his dedication to independent journalism and his fight for freedom of the press. As co-founder and editor-in-chief of Novaya Gazeta, Muratov has been at the forefront of investigative journalism in Russia.",
        "otherDetails": [
          "Muratov co-founded Novaya Gazeta in 1993, which has become known for its courageous reporting on corruption, human rights abuses, and political issues.",
          "Under his leadership, Novaya Gazeta has faced significant threats and attacks, but remains committed to uncovering the truth.",
          "In 2021, Muratov and Novaya Gazeta were awarded the Nobel Peace Prize for their efforts to defend freedom of expression.",
          "He has been a vocal advocate for press freedom and has spoken out against censorship and repression.",
          "Muratov's work has had a significant impact on Russian journalism and has inspired other journalists and media professionals.",
          "He continues to work at Novaya Gazeta, ensuring its role as an independent and critical voice in Russian media."
        ]
      },
      {
        "id": "4",
        "name": "Alexander Ovechkin",
        "work": "Professional Ice Hockey Player",
        "netWorth": "$80M",
        "image": "https://i0.wp.com/novacapsfans.com/wp-content/uploads/2022/11/Ovi-Head-Road-Jersey.webp?fit=920%2C613&ssl=1",
        "age": "39",
        "SourceofWealth": "Hockey",
        "Residence": "Washington, D.C., USA",
        "Citizenship": "Russia",
        "MaritalStatus": "Married",
        "description": "Alexander Ovechkin is one of the most celebrated ice hockey players of his generation, known for his exceptional skill, powerful shooting, and leadership on the ice. His contributions to the sport have earned him numerous accolades and a place among hockey's elite.",
        "otherDetails": [
          "Ovechkin has been a key player for the Washington Capitals, leading them to their first Stanley Cup victory in 2018.",
          "He has won the Hart Trophy as the NHL's Most Valuable Player multiple times and is a perennial contender for the Rocket Richard Trophy for leading goal-scorer.",
          "Ovechkin's playing style is characterized by his physicality and an impressive slapshot, making him a constant threat on the ice.",
          "He has represented Russia in international competitions, including the Winter Olympics and the World Championships.",
          "Ovechkin is involved in various charitable activities and community outreach programs in the Washington, D.C. area.",
          "His impact on the game of hockey is significant, with many young players citing him as an inspiration."
        ]
      },
      {
        "id": "5",
        "name": "Anna Netrebko",
        "work": "Opera Singer",
        "netWorth": "$10M",
        "image": "https://operalife.it/wp-content/uploads/2022/01/06-%C2%A9-Julian-Hargreaves-1-scaled-e1641382349431.jpg",
        "age": "53",
        "SourceofWealth": "Opera Performances",
        "Residence": "Vienna, Austria",
        "Citizenship": "Russia",
        "MaritalStatus": "Married",
        "description": "Anna Netrebko is an internationally acclaimed opera singer known for her powerful voice and dramatic stage presence. Her performances have captivated audiences worldwide, making her one of the leading sopranos of her generation.",
        "otherDetails": [
          "Netrebko has performed at major opera houses, including the Metropolitan Opera in New York, La Scala in Milan, and the Vienna State Opera.",
          "She is known for her versatility in roles ranging from the dramatic to the comedic, including parts in operas by Verdi, Puccini, and Mozart.",
          "Netrebko has released numerous acclaimed recordings and has received several Grammy nominations.",
          "Her career has been marked by collaborations with some of the world's leading conductors and orchestras.",
          "Netrebko has been involved in various charitable activities and supports initiatives related to music education.",
          "Despite facing challenges and controversies, she remains a prominent and influential figure in the world of opera."
        ]
      },
      {
        "id": "6",
        "name": "Konstantin Khabensky",
        "work": "Actor",
        "netWorth": "$5M",
        "image": "https://upload.wikimedia.org/wikipedia/commons/f/f3/%D0%9A%D0%BE%D0%BD%D1%81%D1%82%D0%B0%D0%BD%D1%82%D0%B8%D0%BD_%D0%A5%D0%B0%D0%B1%D0%B5%D0%BD%D1%81%D0%BA%D0%B8%D0%B9_2022.jpg",
        "age": "52",
        "SourceofWealth": "Acting",
        "Residence": "Moscow, Russia",
        "Citizenship": "Russia",
        "MaritalStatus": "Married",
        "description": "Konstantin Khabensky is a highly regarded Russian actor known for his work in both film and television. His performances have earned him acclaim and recognition in the Russian entertainment industry and beyond.",
        "otherDetails": [
          "Khabensky gained fame with his roles in films such as 'Night Watch' and 'Day Watch,' which brought him international recognition.",
          "He has starred in a range of genres, from dramatic roles to comedies, showcasing his versatility as an actor.",
          "Khabensky has received several awards for his acting, including the Golden Eagle Award and the Nika Award.",
          "He has also appeared in successful Russian TV series and has been a prominent figure in Russian cinema.",
          "Khabensky is known for his commitment to his craft and his ability to bring depth to his characters.",
          "He continues to work on new projects and remains a significant presence in the Russian film and television industry."
        ]
      },
      {
        "id": "7",
        "name": "Yuri Gagarin",
        "work": "Cosmonaut",
        "netWorth": "N/A",
        "image": "https://www.thoughtco.com/thmb/V4KRHRDl6KvVaHj956VxSTPVHYA=/1500x0/filters:no_upscale():max_bytes(150000):strip_icc()/gagarin-waving-3361068-5c89a5eb46e0fb0001431aaf.jpg",
        "age": "Deceased",
        "SourceofWealth": "Military, Space Program",
        "Residence": "N/A",
        "Citizenship": "Russia",
        "MaritalStatus": "Married",
        "description": "Yuri Gagarin was a pioneering Soviet cosmonaut who made history as the first human to journey into outer space. His groundbreaking flight marked a significant milestone in space exploration and solidified his place as a global icon.",
        "otherDetails": [
          "Gagarin's historic flight aboard Vostok 1 on April 12, 1961, made him the first human to orbit the Earth, orbiting the planet in just 108 minutes.",
          "His successful mission demonstrated the feasibility of human space travel and paved the way for future space exploration.",
          "Gagarin became an international hero and a symbol of Soviet technological prowess during the Space Race.",
          "After his spaceflight, Gagarin continued to work in the space program and was involved in promoting space exploration.",
          "He tragically died in a training flight accident in 1968, but his legacy continues to inspire generations of astronauts and space enthusiasts.",
          `Gagarin's contributions to space exploration are celebrated annually on Yuri's Night, also known as the "World Space Party," which marks the anniversary of his historic flight.`
        ]
      }
    ]
  },
  {
    id: "8",
    title: "Japanese Icons Celebrating the Nation’s Top Celebrities",
    image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/image+75.png",
    blogDetails: [
      {
        "id": "1",
        "name": "Ken Watanabe",
        "work": "Actor",
        "netWorth": "$30M",
        "image": "https://facts.net/wp-content/uploads/2023/10/21-mind-blowing-facts-about-ken-watanabe-1698558695.jpg",
        "age": "64",
        "SourceofWealth": "Acting",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Married",
        "description": "Ken Watanabe is a celebrated Japanese actor known for his roles in both Japanese cinema and international films. His powerful performances have earned him widespread acclaim and numerous awards.",
        "otherDetails": [
          "Watanabe gained international fame with his role in 'The Last Samurai,' where he showcased his exceptional acting skills.",
          "He has appeared in several major Hollywood films, including 'Inception,' 'Godzilla,' and 'Letters from Iwo Jima.'",
          "Watanabe has received several awards for his work, including the Japan Academy Prize for Best Actor.",
          "His career also includes notable work in Japanese TV dramas and theater, demonstrating his versatility as an actor.",
          "He is recognized for his distinctive voice and commanding presence on screen.",
          "Ken Watanabe continues to be a leading figure in both Japanese and international film industries."
        ]
      },
      {
        "id": "2",
        "name": "Ryo Nishikido",
        "work": "Singer, Actor",
        "netWorth": "$12M",
        "image": "https://aramajapan.com/wp-content/uploads/2019/09/aramajapan.com-nishikido-ryo-leaves-kanjani8-and-johnny-associates-nishikido-ryo-leaves-kanjani8-and-johnny-associates.jpg",
        "age": "39",
        "SourceofWealth": "Music, Acting",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Single",
        "description": "Ryo Nishikido is a popular Japanese singer and actor known for his work with the boy band Kanjani Eight and his roles in various TV dramas and films.",
        "otherDetails": [
          "Nishikido first gained prominence as a member of the boy band Kanjani Eight, which has enjoyed significant success in Japan.",
          "He has also been recognized for his acting roles in popular Japanese TV dramas such as 'One Litre of Tears' and 'The Last Cop.'",
          "His contributions to both music and acting have earned him a dedicated fan base and several awards.",
          "Nishikido is known for his versatile talents, transitioning seamlessly between music and acting.",
          "He has been involved in various entertainment projects, including stage performances and solo music endeavors.",
          "Ryo Nishikido remains a significant figure in Japanese pop culture, continuing to impact both the music and acting industries."
        ]
      },
      {
        "id": "3",
        "name": "Hikaru Utada",
        "work": "Singer, Songwriter",
        "netWorth": "$60M",
        "image": "https://media.pitchfork.com/photos/620a7bf05bb611d025e2509d/3:2/w_1128,h_752,c_limit/badmode.jpeg",
        "age": "41",
        "SourceofWealth": "Music",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Married",
        "description": "Hikaru Utada is a renowned Japanese singer and songwriter whose work has had a profound impact on J-pop and the global music scene.",
        "otherDetails": [
          "Utada's debut album 'First Love' is one of the best-selling albums in Japanese music history.",
          "She has released multiple hit albums and singles, including 'Automatic,' 'Can You Keep A Secret?', and 'Flavor Of Life.'",
          "Her music has achieved critical and commercial success, both in Japan and internationally.",
          "Utada has won numerous awards, including Japan Record Awards and Billboard Japan Music Awards.",
          "She is known for her innovative music style and contributions to the evolution of J-pop.",
          "Hikaru Utada continues to influence the music industry with her ongoing projects and releases."
        ]
      },
      {
        "id": "4",
        "name": "Takeshi Kitano",
        "work": "Filmmaker, Actor, Comedian",
        "netWorth": "$20M",
        "image": "https://images.squarespace-cdn.com/content/v1/57825361440243db4a4b7830/1620686214122-C09YCNI2KNCTHQWY6SGZ/Takeshi-kitano-movies-kikujiro-dolls-hanabi-outrage-japanese-movie-director-beat-takeshi100.jpg?format=2500w",
        "age": "77",
        "SourceofWealth": "Filmmaking, Comedy, Acting",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Married",
        "description": "Takeshi Kitano is a celebrated Japanese filmmaker, comedian, and actor known for his unique style in Japanese cinema and his contributions to comedy and television.",
        "otherDetails": [
          "Kitano is renowned for his work as a director with films such as 'Hana-bi,' 'Sonatine,' and 'Zatoichi.'",
          "He has received numerous awards for his films, including the Golden Lion at the Venice Film Festival.",
          "Kitano's comedy career includes his work as a host on various Japanese TV shows and his performances in stand-up comedy.",
          "He is known for his distinctive directorial style, blending humor and violence in his films.",
          "Kitano has also worked as an actor in both Japanese and international films, showcasing his versatility.",
          "Despite the challenges of the industry, he remains a highly influential figure in Japanese cinema and entertainment."
        ]
      },
      {
        "id": "5",
        "name": "Aya Ueto",
        "work": "Actress, Singer",
        "netWorth": "$8M",
        "image": "https://www.tokyohive.com/upload/2023/03/content/261248/1679849304-20230326-uetoaya.jpg",
        "age": "39",
        "SourceofWealth": "Acting, Music",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Married",
        "description": "Aya Ueto is a well-known Japanese actress and singer recognized for her work in television dramas and her successful music career.",
        "otherDetails": [
          "Ueto gained fame with her roles in popular Japanese TV dramas such as 'Attention Please' and 'Good Luck!!.'",
          "She has released several music albums and singles, earning her recognition as a successful pop singer.",
          "Her acting career includes roles in both TV dramas and films, showcasing her talent across multiple entertainment mediums.",
          "Ueto has received numerous awards for her performances and contributions to Japanese entertainment.",
          "She is also known for her public appearances and involvement in various charity events.",
          "Aya Ueto continues to be a prominent and influential figure in Japanese pop culture and entertainment."
        ]
      },
      {
        "id": "6",
        "name": "Kento Yamazaki",
        "work": "Actor, Model",
        "netWorth": "$5M",
        "image": "https://kingchoice.me/media/CACHE/images/b2c102f10b42ba20d9c640a84d4a5c89_shNS8fg/b9ac318329db07d9ffa756ccf3d43307.jpg",
        "age": "30",
        "SourceofWealth": "Acting, Modeling",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Single",
        "description": "Kento Yamazaki is a rising star in Japanese cinema, known for his roles in popular dramas and films, and his work as a model.",
        "otherDetails": [
          "Yamazaki first gained attention with his role in the drama 'Death Note,' which showcased his acting range and versatility.",
          "He has since starred in several successful films and TV dramas, including 'Your Eyes Tell' and 'Kimi ni Todoke.'",
          "His work as a model has also contributed to his popularity, with numerous endorsements and fashion campaigns.",
          "Yamazaki has been recognized for his acting with several awards and nominations, reflecting his impact on the industry.",
          "He continues to build his career with new and diverse roles, earning a growing fan base both in Japan and internationally.",
          "Kento Yamazaki remains a prominent figure in Japanese entertainment, with a promising future ahead."
        ]
      }
    ]
  },
  {
    id: "9",
    title: "From Tokyo to the World Famous Japanese Celebrities Making Waves",
    image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/image+76.png",
    blogDetails: [
      {
        "id": "1",
        "name": "Haruki Murakami",
        "work": "Author, Novelist",
        "netWorth": "$10M",
        "image": "https://assets.cntraveller.in/photos/60ba1e51f27d46df614fc5d7/4:3/w_1916,h_1437,c_limit/Haruki-Murakami-radio-show-timing.jpg",
        "age": "75",
        "SourceofWealth": "Writing, Books",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Married",
        "description": "Haruki Murakami is a globally renowned Japanese author known for his unique storytelling style and surreal narratives. His works have been translated into multiple languages, reaching a worldwide audience.",
        "otherDetails": [
          "Murakami's notable works include 'Norwegian Wood,' 'Kafka on the Shore,' and '1Q84,' which have been bestsellers globally.",
          "His writing style blends magical realism, postmodernism, and elements of pop culture, creating a unique literary voice.",
          "Murakami has received numerous literary awards, including the World Fantasy Award and the Jerusalem Prize.",
          "He is known for his deep exploration of themes such as loneliness, existentialism, and the subconscious mind.",
          "Murakami's influence extends beyond literature; his works have been adapted into films, stage plays, and even music.",
          "He continues to write and inspire new generations of readers and writers around the world."
        ]
      },
      {
        "id": "2",
        "name": "Naomi Osaka",
        "work": "Professional Tennis Player",
        "netWorth": "$60M",
        "image": "https://hs.mediadelivery.fi/img/468/666426de8a8cb7265756bb6205f1a2c6.jpg",
        "age": "26",
        "SourceofWealth": "Tennis, Endorsements",
        "Residence": "Los Angeles, USA",
        "Citizenship": "Japan",
        "MaritalStatus": "Single",
        "description": "Naomi Osaka is a Japanese professional tennis player known for her powerful playing style and advocacy for social justice. She has won multiple Grand Slam titles and is a prominent figure in sports globally.",
        "otherDetails": [
          "Osaka became the first Asian player to hold the No. 1 ranking in singles by the Women's Tennis Association (WTA).",
          "She has won four Grand Slam titles, including the US Open and the Australian Open.",
          "Osaka is known for her activism, including speaking out on racial injustice and mental health awareness.",
          "Her endorsement deals with major brands like Nike, Louis Vuitton, and Nissan have significantly contributed to her wealth.",
          "Osaka's influence extends beyond the tennis court; she was listed as one of Time's 100 most influential people.",
          "She continues to break barriers in sports and remains a role model for young athletes around the world."
        ]
      },
      {
        "id": "3",
        "name": "Rinko Kikuchi",
        "work": "Actress",
        "netWorth": "$10M",
        "image": "https://fwcdn.pl/nph/1985708/2020/23796_1.8.jpg",
        "age": "43",
        "SourceofWealth": "Acting",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Married",
        "description": "Rinko Kikuchi is an acclaimed Japanese actress known for her roles in both Japanese and international films. She gained worldwide recognition for her performance in the film 'Babel.'",
        "otherDetails": [
          "Kikuchi became the first Japanese actress to be nominated for an Academy Award in 50 years for her role in 'Babel.'",
          "She has starred in several international films, including 'Pacific Rim' and '47 Ronin,' showcasing her versatility.",
          "Kikuchi's performances are known for their depth and intensity, earning her critical acclaim.",
          "She has received multiple awards, including the Japan Academy Prize and the Gotham Independent Film Award.",
          "Kikuchi is known for her bold choices in roles, often exploring complex and challenging characters.",
          "She continues to be a leading figure in both Japanese and global cinema."
        ]
      },
      {
        "id": "4",
        "name": "Masayoshi Son",
        "work": "Entrepreneur, CEO of SoftBank Group",
        "netWorth": "$22B",
        "image": "https://nypost.com/wp-content/uploads/sites/2/2023/06/NYPICHPDPICT000012997801.jpg",
        "age": "66",
        "SourceofWealth": "Technology, Investments",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Married",
        "description": "Masayoshi Son is a Japanese entrepreneur and the CEO of SoftBank Group, a multinational conglomerate known for its investments in technology and innovation globally.",
        "otherDetails": [
          "Son founded SoftBank in 1981 and has since turned it into one of the world's largest technology investors.",
          "He is known for his visionary investments, including early stakes in companies like Alibaba and Yahoo Japan.",
          "Under his leadership, SoftBank launched the $100 billion Vision Fund, the largest technology investment fund in history.",
          "Son's business strategies have significantly impacted the global tech landscape, especially in AI and robotics.",
          "He is known for his bold and sometimes risky investment moves, earning both praise and criticism.",
          "Masayoshi Son continues to be a pivotal figure in technology, driving innovation and investment on a global scale."
        ]
      },
      {
        "id": "5",
        "name": "Hiroshi Fujiwara",
        "work": "Fashion Designer, Musician",
        "netWorth": "$50M",
        "image": "https://cloudfront-eu-central-1.images.arcpublishing.com/businessoffashion/HQ2AOIGQ35DIVGBKS2FYVGQZXA.jpg",
        "age": "59",
        "SourceofWealth": "Fashion, Music",
        "Residence": "Tokyo, Japan",
        "Citizenship": "Japan",
        "MaritalStatus": "Married",
        "description": "Hiroshi Fujiwara is a legendary Japanese fashion designer, musician, and streetwear icon, often referred to as the 'Godfather of Harajuku.' His influence extends globally in both fashion and music.",
        "otherDetails": [
          "Fujiwara founded the influential streetwear brand 'Fragment Design,' which has collaborated with major brands like Nike and Louis Vuitton.",
          "He is considered a pioneer of street fashion in Japan, influencing a new generation of designers worldwide.",
          "Fujiwara has also contributed to music, particularly in the hip-hop and electronic scenes, as a DJ and producer.",
          "He has been a key figure in bridging Japanese and Western street culture, bringing a unique blend of aesthetics to global fashion.",
          "Fujiwara's collaborations and designs are highly sought after, making him a trendsetter in the fashion industry.",
          "He continues to innovate and influence fashion and music, remaining a major cultural icon."
        ]
      }
    ]
  },
  {
    "id": "10",
    "title": "Spotlight on South Africa's Rising Stars and Legends",
    "image": "https://billionaireweb.s3.eu-north-1.amazonaws.com/Mask+group+(13).png",
    "blogDetails": [
      {
        "id": "1",
        "name": "Charlize Theron",
        "work": "Actress, Producer",
        "netWorth": "$160M",
        "image": "https://i.dailymail.co.uk/i/pix/2016/05/20/23/347089E900000578-0-image-a-1_1463782068177.jpg",
        "age": "48",
        "SourceofWealth": "Acting, Producing",
        "Residence": "Los Angeles, USA",
        "Citizenship": "South Africa, USA",
        "MaritalStatus": "Single",
        "description": "Charlize Theron is an acclaimed South African actress and producer known for her versatility and powerful performances in Hollywood films.",
        "otherDetails": [
          "Theron gained international fame with her role in 'The Devil's Advocate' (1997) and later won an Academy Award for Best Actress for her performance in 'Monster' (2003).",
          "She has starred in numerous blockbuster films, including 'Mad Max: Fury Road,' 'Atomic Blonde,' and 'Bombshell.'",
          "Theron is also known for her work as a producer, founding her production company Denver and Delilah Productions.",
          "She has been actively involved in humanitarian efforts, particularly in HIV/AIDS prevention through her Africa Outreach Project.",
          "Charlize has received multiple awards, including a Golden Globe and a Screen Actors Guild Award, for her outstanding work in cinema.",
          "She continues to be one of the most respected and influential actresses in Hollywood, maintaining a strong connection to her South African roots."
        ]
      },
      {
        "id": "2",
        "name": "Trevor Noah",
        "work": "Comedian, TV Host, Author",
        "netWorth": "$100M",
        "image": "https://media.washtimes.com/media/image/2017/10/02/books-trevor_noah_21171.jpg",
        "age": "40",
        "SourceofWealth": "Comedy, Television, Books",
        "Residence": "New York City, USA",
        "Citizenship": "South Africa",
        "MaritalStatus": "Single",
        "description": "Trevor Noah is a celebrated South African comedian, TV host, and author, best known for hosting 'The Daily Show' on Comedy Central.",
        "otherDetails": [
          "Noah began his career in South Africa, gaining fame through his stand-up comedy and hosting various TV shows.",
          "He became the host of 'The Daily Show' in 2015, succeeding Jon Stewart, and quickly became a prominent voice in late-night television.",
          "Noah is the author of the bestselling memoir 'Born a Crime,' which explores his experiences growing up in apartheid-era South Africa.",
          "He has won numerous awards for his work, including an Emmy Award, and is considered one of the most influential comedians globally.",
          "Trevor's sharp wit and insightful commentary have made him a key figure in American media and comedy.",
          "He continues to perform stand-up, write, and produce, while also working on various charitable endeavors."
        ]
      },
      {
        "id": "3",
        "name": "Thuso Mbedu",
        "work": "Actress",
        "netWorth": "$5M",
        "image": "https://assets.isu.pub/document-structure/211004112712-828c5b6fbcd4717b251d0704d23026ec/v1/0c99fe6518af480065ff0925dd2c25fb.jpeg?crop=985%2C739%2Cx1177%2Cy7&originalHeight=1453&originalWidth=2162&zoom=1.96733355939867",
        "age": "32",
        "SourceofWealth": "Acting",
        "Residence": "Johannesburg, South Africa",
        "Citizenship": "South Africa",
        "MaritalStatus": "Single",
        "description": "Thuso Mbedu is a rising South African actress known for her powerful performances in both local and international television series and films.",
        "otherDetails": [
          "Mbedu gained widespread acclaim for her role in the series 'Is'Thunzi,' earning her two International Emmy Award nominations.",
          "She made her Hollywood debut in the critically acclaimed series 'The Underground Railroad,' directed by Barry Jenkins.",
          "Mbedu has won several awards for her acting, including a Hollywood Critics Association Award for her role in 'The Woman King.'",
          "Her talent and charisma have made her one of South Africa's most promising young stars.",
          "Thuso is known for her dedication to her craft and her commitment to portraying complex, layered characters.",
          "She continues to work on high-profile projects in both South Africa and the United States, cementing her place as a global star."
        ]
      },
      {
        "id": "4",
        "name": "Black Coffee",
        "work": "DJ, Record Producer",
        "netWorth": "$60M",
        "image": "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTz-t_vUMBNviDgN1QpqQVAxg0Dc7QX5HWr3w&s",
        "age": "48",
        "SourceofWealth": "Music, DJing",
        "Residence": "Johannesburg, South Africa",
        "Citizenship": "South Africa",
        "MaritalStatus": "Divorced",
        "description": "Black Coffee is a globally renowned South African DJ and record producer known for his unique blend of house music and Afrobeat.",
        "otherDetails": [
          "Black Coffee, born Nkosinathi Maphumulo, began his career in Durban's dance music scene before rising to international fame.",
          "He has performed at major music festivals and venues worldwide, including Coachella and Ibiza.",
          "Black Coffee has released several successful albums and singles, earning numerous awards, including a Grammy for Best Dance/Electronic Album.",
          "His music is known for its soulful and rhythmic beats, blending African sounds with contemporary electronic music.",
          "He is also a philanthropist, involved in various charitable efforts in South Africa, particularly in education and community development.",
          "Black Coffee continues to influence the global music scene, expanding his reach and collaboration with international artists."
        ]
      },
      {
        "id": "5",
        "name": "Pearl Thusi",
        "work": "Actress, Model, TV Host",
        "netWorth": "$2.5M",
        "image": "https://www.hollywoodreporter.com/wp-content/uploads/2017/06/145645_0549_-_h_2017.jpg?w=1296&h=730&crop=1",
        "age": "35",
        "SourceofWealth": "Acting, Modeling, Television",
        "Residence": "Johannesburg, South Africa",
        "Citizenship": "South Africa",
        "MaritalStatus": "Single",
        "description": "Pearl Thusi is a South African actress, model, and TV host known for her work in local and international productions.",
        "otherDetails": [
          "Thusi gained recognition for her role in the South African soap opera 'Isidingo' and later became the host of 'Lip Sync Battle Africa.'",
          "She starred in the lead role of 'Queen Sono,' Netflix's first African original series, earning widespread acclaim.",
          "Thusi has been featured in several international films and TV shows, showcasing her talent and versatility as an actress.",
          "She is also known for her work as a model and brand ambassador, representing major brands in South Africa and beyond.",
          "Pearl Thusi has been involved in various charitable causes, advocating for women's rights and children's welfare.",
          "She continues to be a prominent figure in the South African entertainment industry, balancing her career with her philanthropic efforts."
        ]
      },
      {
        "id": "6",
        "name": "Sharlto Copley",
        "work": "Actor, Producer, Director",
        "netWorth": "$15M",
        "image": "https://cdn.24.co.za/files/Cms/General/d/11687/8eeb148644c1441493917f4da0d1b529.jpg",
        "age": "50",
        "SourceofWealth": "Acting, Producing, Directing",
        "Residence": "Cape Town, South Africa",
        "Citizenship": "South Africa",
        "MaritalStatus": "Married",
        "description": "Sharlto Copley is a South African actor, producer, and director known for his roles in major Hollywood films and his unique approach to filmmaking.",
        "otherDetails": [
          "Copley first gained fame for his role in the sci-fi film 'District 9,' directed by Neill Blomkamp, where he delivered a standout performance.",
          "He has since starred in several Hollywood films, including 'Elysium,' 'Maleficent,' and 'Hardcore Henry.'",
          "Copley is known for his collaboration with Blomkamp, having worked on multiple projects that explore science fiction and dystopian themes.",
          "He has received various awards and nominations for his acting, particularly for his ability to portray complex and unconventional characters.",
          "In addition to acting, Copley has ventured into producing and directing, contributing to South African and international cinema.",
          "Sharlto Copley continues to work on innovative projects, balancing mainstream and independent films, and remains a prominent figure in global cinema."
        ]
      }
    ]
  },
  {
    "id": "11",
    "title": "South Africa's Celebrity Trailblazers Icons Changing the Game",
    "image": "https://billionaireweb.s3.eu-north-1.amazonaws.com/image+78.png",
    "blogDetails": [
      {
        "id": "1",
        "name": "John Kani",
        "work": "Actor, Playwright",
        "netWorth": "$5M",
        "image": "https://www.stratford-herald.com/_media/img/W0SQDVTMHKFV5TNRVH4E.jpg",
        "age": "81",
        "SourceofWealth": "Acting, Playwriting",
        "Residence": "Cape Town, South Africa",
        "Citizenship": "South Africa",
        "MaritalStatus": "Married",
        "description": "John Kani is a renowned South African actor and playwright known for his powerful performances in theatre and film. His contributions to the arts have made him a significant figure in South African culture.",
        "otherDetails": [
          "Kani's notable works include his role in *The Lion King* and the acclaimed play *Sizwe Banzi Is Dead*.",
          "He has received numerous awards for his contributions to theatre and film.",
          "Kani is known for his impactful storytelling and commitment to promoting South African culture.",
          "He continues to be an influential voice in the performing arts.",
          "Kani has also been involved in various social and community initiatives.",
          "His career spans several decades, showcasing his enduring talent and dedication."
        ]
      },
      {
        "id": "2",
        "name": "Nomzamo Mbatha",
        "work": "Actress, TV Host",
        "netWorth": "$2M",
        "image": "https://cdn.prod.website-files.com/60c689ba9e78889f30709db3/65a035903ccb363c1909357d_Nomzamo%20Mbatha%20400.jpg",
        "age": "34",
        "SourceofWealth": "Acting, Television",
        "Residence": "Johannesburg, South Africa",
        "Citizenship": "South Africa",
        "MaritalStatus": "Single",
        "description": "Nomzamo Mbatha is a South African actress and TV host celebrated for her roles in television dramas and her work as a presenter. Her dynamic presence and engaging performances have earned her acclaim in the entertainment industry.",
        "otherDetails": [
          "Mbatha gained prominence with her role in the popular South African TV series *Isibaya*.",
          "She has also worked as a presenter on various television shows and events.",
          "Nomzamo is recognized for her advocacy work and involvement in charitable causes.",
          "She continues to expand her career with new acting projects and public appearances.",
          "Her work has earned her several awards and a growing fan base.",
          "Nomzamo Mbatha remains a prominent figure in South African media and entertainment."
        ]
      },
      {
        "id": "3",
        "name": "Sibusiso Vilane",
        "work": "Adventurer, Author",
        "netWorth": "$1M",
        "image": "https://champions-speakers.co.uk/sites/default/files/styles/speaker_portrait/public/2020-11/mark-pattison-thumbnail.jpg.jpeg?itok=JgPvaQg-",
        "age": "56",
        "SourceofWealth": "Adventuring, Writing",
        "Residence": "Mbabane, Eswatini",
        "Citizenship": "Eswatini",
        "MaritalStatus": "Married",
        "description": "Sibusiso Vilane is a renowned South African adventurer and author, celebrated for his remarkable feats in mountaineering and exploration. His achievements have inspired many and brought attention to South African and African adventurers.",
        "otherDetails": [
          "Vilane was the first black African to reach the summit of Mount Everest.",
          "He has written books about his adventures and experiences, including *To the Top from Nowhere*.",
          "Sibusiso has been involved in various motivational speaking engagements and community projects.",
          "His achievements in the field of adventure have earned him international recognition.",
          "He continues to inspire others through his stories and contributions to exploration.",
          "Vilane is known for his dedication to promoting adventure and outdoor activities."
        ]
      },
      {
        "id": "4",
        "name": "Jesse Suntele",
        "work": "Actor, Model",
        "netWorth": "$1.5M",
        "image": "https://www.blazonmagazine.co.za/wp-content/uploads/2019/08/Jesse-1024x692.jpg",
        "age": "31",
        "SourceofWealth": "Acting, Modeling",
        "Residence": "Johannesburg, South Africa",
        "Citizenship": "South Africa",
        "MaritalStatus": "Single",
        "description": "Jesse Suntele is a South African actor and model known for his roles in television series and his work in the fashion industry. His rising star in both acting and modeling has made him a notable figure in South African entertainment.",
        "otherDetails": [
          "Suntele gained fame with his role in the popular TV show *The Queen*.",
          "He has appeared in various modeling campaigns and fashion events.",
          "Jesse is recognized for his versatility and charisma both on screen and in print.",
          "He continues to build his career with new acting roles and modeling opportunities.",
          "His work has earned him a growing fan base and several accolades.",
          "Jesse Suntele is an emerging talent with a promising future in entertainment."
        ]
      },
      {
        "id": "5",
        "name": "Thuso Mbedu",
        "work": "Actress",
        "netWorth": "$5M",
        "image": "https://assets.isu.pub/document-structure/211004112712-828c5b6fbcd4717b251d0704d23026ec/v1/0c99fe6518af480065ff0925dd2c25fb.jpeg?crop=985%2C739%2Cx1177%2Cy7&originalHeight=1453&originalWidth=2162&zoom=1.96733355939867",
        "age": "32",
        "SourceofWealth": "Acting",
        "Residence": "Johannesburg, South Africa",
        "Citizenship": "South Africa",
        "MaritalStatus": "Single",
        "description": "Thuso Mbedu is a rising South African actress known for her powerful performances in both local and international television series and films.",
        "otherDetails": [
          "Mbedu gained widespread acclaim for her role in the series 'Is'Thunzi,' earning her two International Emmy Award nominations.",
          "She made her Hollywood debut in the critically acclaimed series 'The Underground Railroad,' directed by Barry Jenkins.",
          "Mbedu has won several awards for her acting, including a Hollywood Critics Association Award for her role in 'The Woman King.'",
          "Her talent and charisma have made her one of South Africa's most promising young stars.",
          "Thuso is known for her dedication to her craft and her commitment to portraying complex, layered characters.",
          "She continues to work on high-profile projects in both South Africa and the United States, cementing her place as a global star."
        ]
      },
      {
        "id": "6",
        "name": "Moshidi Motshegwa",
        "work": "Actress, Director",
        "netWorth": "$3M",
        "image": "https://lh3.googleusercontent.com/61Wjk-wjhjfW37L0VcqDyCV9CBLZrxCnjFmjruV5UnHch7alAjIuaDLHsHbyqZlfq4BijrEHjFEYvqOEuIo5ccI=s714",
        "age": "49",
        "SourceofWealth": "Acting, Directing",
        "Residence": "Johannesburg, South Africa",
        "Citizenship": "South Africa",
        "MaritalStatus": "Married",
        "description": "Moshidi Motshegwa is a prominent South African actress and director known for her impactful roles in television and film. Her contributions to the South African entertainment industry have made her a respected figure in the field.",
        "otherDetails": [
          "Motshegwa has received critical acclaim for her roles in TV dramas such as *Tsha Tsha* and *The Lab*.",
          "She has also worked as a director and producer, expanding her influence in the industry.",
          "Moshidi is known for her powerful performances and commitment to storytelling.",
          "Her work has earned her several awards and nominations in South Africa.",
          "She continues to be an influential figure in both acting and directing.",
          "Moshidi Motshegwa remains a celebrated talent in South African cinema and television."
        ]
      }
    ]
  },
  {
    "id": "12",
    "title": "Spotlight on German Talent Celebrities Who Continue to Shine Bright",
    "image": "https://liverpoolbuzz.co.uk/wp-content/uploads/2024/05/Conne.png",
    "blogDetails": [
      {
        "id": "1",
        "name": "Diane Kruger",
        "work": "Actress, Model",
        "netWorth": "$24M",
        "image": "https://d1nslcd7m2225b.cloudfront.net/Pictures/480xAny//P/web/f/h/i/Diane-Kruger.jpg",
        "age": "47",
        "SourceofWealth": "Acting, Modeling",
        "Residence": "Berlin, Germany",
        "Citizenship": "Germany",
        "MaritalStatus": "In a Relationship",
        "description": "Diane Kruger is a German actress and former fashion model known for her roles in both German and international films. Her performances have earned her several accolades and a prominent place in global cinema.",
        "otherDetails": [
          "Kruger gained international recognition with her role as Helen in 'Troy' and later starred in 'Inglourious Basterds' and 'National Treasure.'",
          "She won the Best Actress award at the Cannes Film Festival for her role in the German film 'In the Fade.'",
          "Kruger has been involved in numerous international film projects, showcasing her versatility as an actress.",
          "She is also a fashion icon, frequently appearing on best-dressed lists for her elegant style.",
          "Apart from her film career, Kruger has been a brand ambassador for several luxury brands.",
          "Diane continues to work in both Hollywood and European cinema, adding depth to her filmography."
        ]
      },
      {
        "id": "2",
        "name": "Florian David Fitz",
        "work": "Actor, Screenwriter, Director",
        "netWorth": "$10M",
        "image": "https://www.derwesten.de/wp-content/uploads/sites/8/2024/07/imago0306060400h-e1721653875424.jpg?w=1200",
        "age": "49",
        "SourceofWealth": "Acting, Filmmaking",
        "Residence": "Munich, Germany",
        "Citizenship": "Germany",
        "MaritalStatus": "Single",
        "description": "Florian David Fitz is a renowned German actor, screenwriter, and director, known for his dynamic performances and creative storytelling in German cinema.",
        "otherDetails": [
          "Fitz starred in the critically acclaimed film 'Vincent Wants to Sea,' which won several awards.",
          "He has directed and written successful German films like 'Jesus Loves Me' and 'The Most Beautiful Day.'",
          "Known for his charm and versatility, Fitz has been a staple in both comedy and drama genres in Germany.",
          "He has received several awards, including the Bambi Award and the German Film Award.",
          "His work as a director has been well-received, showcasing his ability to blend humor and drama.",
          "Florian continues to be an influential figure in German cinema, both in front of and behind the camera."
        ]
      },
      {
        "id": "3",
        "name": "Lena Meyer-Landrut",
        "work": "Singer, Songwriter",
        "netWorth": "$5M",
        "image": "https://assets.glamour.de/photos/61b231ebb9941a537a0237c8/16:9/w_2560%2Cc_limit/lenameyerlandrut-interview-quer-jpg-60d1e5a1595c4",
        "age": "33",
        "SourceofWealth": "Music",
        "Residence": "Cologne, Germany",
        "Citizenship": "Germany",
        "MaritalStatus": "Single",
        "description": "Lena Meyer-Landrut is a German singer and songwriter who rose to fame after winning the Eurovision Song Contest in 2010. She has since become one of Germany’s most beloved pop artists.",
        "otherDetails": [
          "Lena won the Eurovision Song Contest in 2010 with her hit song 'Satellite,' which became a European chart-topper.",
          "She has released several successful albums and singles, cementing her place in the German music industry.",
          "Lena has served as a judge on 'The Voice Kids' Germany, showcasing her influence in the music industry.",
          "Known for her distinctive voice and style, she has earned several music awards, including the Echo Award.",
          "She continues to evolve her music style, experimenting with pop, indie, and alternative genres.",
          "Lena remains a prominent figure in German pop culture, inspiring young musicians and fans alike."
        ]
      },
      {
        "id": "4",
        "name": "Daniel Brühl",
        "work": "Actor, Producer",
        "netWorth": "$20M",
        "image": "https://img-s-msn-com.akamaized.net/tenant/amp/entityid/BB1pstek.img?w=768&h=512&m=6&x=530&y=155&s=195&d=195",
        "age": "45",
        "SourceofWealth": "Acting, Producing",
        "Residence": "Barcelona, Spain",
        "Citizenship": "Germany",
        "MaritalStatus": "Married",
        "description": "Daniel Brühl is a German-Spanish actor and producer known for his roles in both European and Hollywood films. His talent and range have made him a respected figure in international cinema.",
        "otherDetails": [
          "Brühl gained international fame with his role in 'Good Bye Lenin!' and later appeared in 'Inglourious Basterds' and 'Rush.'",
          "He played the role of Baron Zemo in Marvel's 'Captain America: Civil War' and 'The Falcon and the Winter Soldier.'",
          "Brühl is multilingual and has worked in several languages, enhancing his appeal in global cinema.",
          "He has received numerous awards for his performances, including the European Film Award.",
          "Daniel is also involved in film production and owns a restaurant in Berlin, showcasing his diverse interests.",
          "He continues to work in both European and Hollywood projects, further solidifying his global presence."
        ]
      },
      {
        "id": "5",
        "name": "Nico Rosberg",
        "work": "Former Formula 1 Driver, Entrepreneur",
        "netWorth": "$50M",
        "image": "https://i0.gmx.ch/image/144/36651144,pd=1,f=size-l/nico-rosberg.jpg",
        "age": "38",
        "SourceofWealth": "Racing, Business",
        "Residence": "Monaco",
        "Citizenship": "Germany",
        "MaritalStatus": "Married",
        "description": "Nico Rosberg is a retired German Formula 1 driver and entrepreneur who won the World Championship in 2016. He is now involved in various business ventures and sustainable initiatives.",
        "otherDetails": [
          "Rosberg won the Formula 1 World Championship in 2016 with Mercedes-AMG Petronas.",
          "He retired from racing at the peak of his career to focus on his family and business interests.",
          "Nico is an advocate for sustainability and has invested in several eco-friendly startups.",
          "He works as a pundit and commentator for Formula 1, sharing his expertise and insights.",
          "Rosberg is involved in various philanthropic efforts, including promoting green energy and electric mobility.",
          "Continues to be a respected figure in motorsports and business, inspiring future generations."
        ]
      },
      {
        "id": "6",
        "name": "Heidi Klum",
        "work": "Model, TV Host, Producer",
        "netWorth": "$160M",
        "image": "https://cdn.britannica.com/53/144453-050-579293DE/Heidi-Klum.jpg",
        "age": "51",
        "SourceofWealth": "Modeling, Television",
        "Residence": "Los Angeles, USA",
        "Citizenship": "Germany",
        "MaritalStatus": "Married",
        "description": "Heidi Klum is a German model, television host, and producer known for her work in fashion and her role as a host on 'Germany's Next Topmodel' and 'Project Runway.'",
        "otherDetails": [
          "Klum became the first German model to become a Victoria's Secret Angel.",
          "She has hosted and produced 'Germany's Next Topmodel' and 'Project Runway,' winning an Emmy Award for the latter.",
          "Heidi is known for her entrepreneurial spirit, having launched several successful brands and products.",
          "She remains a fashion icon, frequently appearing in magazines and at high-profile events.",
          "Klum is actively involved in charity work, particularly in children's causes and animal rights.",
          "She continues to be a prominent figure in fashion and entertainment, balancing multiple roles as a model, host, and producer."
        ]
      }
    ]
  }

]



export const otherCelebrityBlogs = [
  {
    id: "1",
    title: "The Most Shameless Red Carpet Outfits Of All Time",
    date: "13 September 2024",
    image:
      "https://billionaireweb.s3.eu-north-1.amazonaws.com/Group+362.png",
    description:
      "Rihanna, Jennifer Lopez, Cher and Kendall Jenner balance the rundown of stars who have worn all-sheer, scarcely there looks on honorary pathway throughout the long term",
    description2: `When it comes to showing some or all of their skin, some celebrities aren't exactly camera-shy. Scarcely there dresses have been causing a stir and producing outrageous titles since the '60s. Nonetheless, the expression "exposed dress" didn't come into the design dictionary until a 1998 episode of Sex and the City, where Carrie Bradshaw (Sarah Jessica Parker) shook a beige DKNY slip for her most memorable date with Enormous (Chris Noth). Celebrities have fully embraced this trend at various events, opting for see-through fabrics, dramatic cutaways, midriff-baring silhouettes, plunging necklines, and thigh-high slits—whether it's about feeling confident in their bodies, making a political statement, or turning heads. From Cher's down changing dress at the 1974 Met Function to Jennifer Lopez's notorious Versace dress, take a look at probably the hottest, most scandalous bare dresses worn on honorary pathway.`,
    blogDetails: [
      {
        id: "1",
        title: "Ice Spice",
        image:
          "https://people.com/thmb/tsgIoesus_nvZfKJ_19bHPyQf3E=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(599x0:601x2):format(webp)/ice-spice-at-the-2024-Vanity-Fair-Oscar-Party-031124-4796a41d0e464423ab93055ce222bf52.jpg",
        description:
          "Ice Spice attended the annual Vanity Fair afterparty following the 2024 Oscars in a floor-length sheer gown with long lace sleeves, a matching bra, and matching pants. At the point when her honorary pathway look was censured via virtual entertainment, the Bronx-conceived rapper applauded back on X (previously Twitter), stating, 'How about we see u bitches in custom dolce.'",
      },
      {
        id: "2",
        title: "Vanessa Hudgens",
        image:
          "https://people.com/thmb/N9QFA6qmv0Be190zmo5M07_XrEo=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(613x178:615x180):format(webp)/vanessa-hudgens-vanity-fair-baby-bump-oscars-los-angeles-031024_2044-f92fb54cdba34ffbb64c17ccf8748734.jpg",
        description:
          "Vanessa Hudgens paralyzed in custom Alberta Ferretti at the 2024 Vanity Fair Oscar all-nighter, flaunting her child knock in a sheer, off-the-shoulder dark dress while wearing gold hoops and dark heels.",
      },
      {
        id: "3",
        title: "Dakota Johnson",
        image:
          "https://people.com/thmb/9f0Dw2Wf_HTA0iE2S2vIV_IQi98=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(666x0:668x2):format(webp)/Dakota-Johnson-Madame-Web-LA-Premiere-021224-dfa7b5aa7c334963b22e22d336d51240.jpg",
        description:
          "At the Los Angeles debut of Madame Web, Dakota Johnson — who stars in the eponymous job — imitated her personality in a sheer, spaghetti-tied, chain metal dress that looked like a shimmering web. Alongside a low neck area, the Fifty Shades of Dim entertainer likewise shook a solitary precious stone pendant.",
      },
      {
        id: "4",
        title: "Miley Cyrus",
        image:
          "https://people.com/thmb/98OttzSH3ug9YxxeUVHl7g3yUXs=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(599x0:601x2):format(webp)/miley-cyrus-grammys-2024-arrivals-227f0e53f9a9458691c78ed43df5af97.jpg",
        description:
          "Miley Cyrus wore a gold metal mesh dress, matching metal pants, and voluminous, 1970s-style bouffant hairstyle on the night she won her first Grammy (for best pop solo performance and record of the year) in 2024.",
      },
      {
        id: "5",
        title: "Dua Lipa",
        image:
          "https://people.com/thmb/F8oQaLkqfmT9GNnL8nwT6tojIS4=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(1625x463:1627x465):format(webp)/Dua-Lipa-Barbie-Premiere-071123-03-93020c05511c436da40d06aae56a6dca.jpg",
        description:
          "At the Barbie world debut in July 2023, Dua Lipa — who showed up in the film as Mermaid Barbie and made the Grammy-named tune off its soundtrack 'Dance the Evening' — wore a silver, translucent fishnet dress with a white trim strap under. ' It's shimmering and fun,' she told Vogue (by means of TikTok) of her honorary pathway look. ' I simply figured it would match my mermaid character in Barbie, so I just went with that.'",
      },
      {
        id: "6",
        title: "Hunter Schafer",
        image:
          "https://people.com/thmb/eDnYevbDOQ_IZDWac5F6zJkwYxg=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(637x0:639x2):format(webp)/hunter-schafer-vanity-fair-oscar-party-hosted-by-radhika-jones-031323-1-2000-b916b8d73fbf412990bcc5985fea3549.jpg",
        description:
          "Wearing a custom Ann Demeulemeester look, Rapture's Tracker Schafer blew some people's minds at the 2023 Vanity Fair Oscars all-nighter, demonstrating an all-white group that included a solitary plume as a bandeau top, a silk maxi skirt and white stage heels.",
      },
      {
        id: "7",
        title: "Doja Cat",
        image:
          "https://people.com/thmb/soo03jvRNqLQxBWZTnf8-nA6cng=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(599x0:601x2):format(webp)/doja-grammys-2024-arrivals-2a5e308748024680b9ddbad5238ccd61.jpg",
        description:
          "Doja Cat, who was nominated for three Grammys that night, got everyone's 'Attention' on the 2024 Grammys red carpet when she showed off her nipples in a see-through corset dress, red Alexander Wang heels, and black glasses.",
      },
      {
        id: "8",
        title: "Megan Fox",
        image:
          "https://people.com/thmb/bzKMqVhTUFuMevOb9_KQTiSQFO8=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(509x132:511x134):format(webp)/Megan-Fox-Grammys-tattoos-020624-1-03325c254a0d4264a1974d1917ea8f41.jpg",
        description:
          "Appearing a new bubblegum pink hairdo, Megan Fox showed up at the fifth yearly Jam for Janie Grammy Grants seeing party wearing a transparent chainmail small dress enhanced with silver paillettes and a G-string under.",
      },
      {
        id: "9",
        title: "Rita Ora",
        image:
          "https://people.com/thmb/kn4btsjWFayhljl9f16YOBgR7LU=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(561x159:563x161):format(webp)/Rita-Ora-120522-02-2000-3ba268a1328c4676840d11aba1b65bce.jpg",
        description:
          "At the 2022 English Style Grants, Rita Ora settled on 'mermaid stylish' in a red Nensi Dojaka dress comprising of a lattice board over a shell bra top and matching red strap — looking like a mermaid trapped in a fishing net. Ora raised the amphibian look with fish scale-esque prosthetics around the eye region, beachy waves and red Aquazzura shoes.",
      },
      {
        id: "10",
        title: "Kendall Jenner",
        image:
          "https://people.com/thmb/GDvuUNtmTCFjc6cK2pqEjclyGeU=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(534x0:536x2):format(webp)/kendall-jenner-sheer-011623-2-86883ab5024a43e1923e7065c0f4dadf.jpg",
        description:
          "Celebrating her friend Lori Harvey's birthday in January 2023, Kendall Jenner dared to impress in a black Ludovic de Saint Sernin midi dress with a red floral choker ... just another regular Friday night look for the model and reality star!",
      },
      {
        id: "11",
        title: "Olivia Wilde",
        image:
          "https://people.com/thmb/IkSZso79iMB0pgrgaNDQLzDi0Jo=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(660x0:662x2):format(webp)/olivia-wilde-102722-1-2000-5667ceb4c1814d0cb50097ec3e863fa2.jpg",
        description:
          "Old Hollywood glitz — with a turn! At the Institute Exhibition hall of Films' second yearly function, Olivia Wilde wore a sparkling silver outfit from Alexandre Vauthier High fashion with a pink marabou feather trim and sheer bodice. Supplementing her troupe, the Don't Stress Dear chief decorated with silver drop studs and fragile rings.",
      },
      {
        id: "12",
        title: "Heather Rae El Moussa",
        image:
          "https://people.com/thmb/SEITkLEL8lyaCQDQQ8kmTygP1hc=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(663x0:665x2):format(webp)/Heather-Rae-El-Moussa-peoples-choice-2000-b32e3d13c9974b8597eb976b54532ebb.jpg",
        description:
          "On honorary pathway at the 2022 Individuals' Decision Grants, Heather Rae El Moussa — then, at that point pregnant with child Tristan — wore a David Koma gem network sheath with a high cut over a dark bra and briefs. At the time, Moussa told PEOPLE about her pregnancy: Wow, it feels better. I'm feeling fantastic.'",
      },
      {
        id: "13",
        title: "Emily Ratajkowski",
        image:
          "https://people.com/thmb/NhA2sd1clF0h7gJGGhpc_p1pmcU=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(587x227:589x229):format(webp)/Emily-Ratajkowski-wmag-anniv-1013-2705510bd1eb4fc398d4d2017a5b8d86.jpg",
        description:
          "At the 50th anniversary party of W Magazine, Emily Ratajkowski made a glittering statement. The model ventured out in a story length, surprised fishnet sheath over pink silk underwear, dark heels and a dark grasp.",
      },
      {
        id: "14",
        title: "Florence Pugh",
        image:
          "https://people.com/thmb/bzkt8PcV04WowojkyF0wnuWSiy4=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(551x286:553x288):format(webp)/florence-pugh-1003-d5169adc6a5e4eaca0b2cce470e11ff9.jpg",
        description:
          "In the wake of causing disturbances with a pink sheer outfit at the Valentino High fashion show in August 2022 (and applauding back at pundits), Florence Pugh ventured out again during Paris Design Week that October. This time, she wore another high-design straightforward look from the brand: a champagne-shaded, long-sleeved sheer top and a transparent skirt over tall building briefs, both weaved with a gold-sequin plan.",
      },
      {
        id: "15",
        title: "Kylie Jenner",
        image:
          "https://people.com/thmb/61rkvyMFzswY07WrWZSgMOD6BxQ=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(749x0:751x2):format(webp)/kylie-jenner-paris-093022-b2cba26e6297438898bfd8eb41040c10.jpg",
        description:
          'Carrying wreck stylish to Paris Design Week in September 2022, Kylie Jenner made a showstopping entry wearing a Dilara Findikoglu "Broke down Doll" dress, which includes a white ribbon outfit with a thigh-high cut layered over areas of red glossy silk texture. The star likewise added a red sack, clear heels and a banged haircut.',
      },
      {
        id: "16",
        title: "Rose McGowan",
        image:
          "https://people.com/thmb/2r8uUznxfgs-UtSIqs6FUBC68xA=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(600x0:602x2):format(webp)/rose-mcgowan-2000-25ae5af923424743b9b760a825199279.jpg",
        description:
          'Rose McGowan wore one of the most popular "bare dresses" ever. The Charmed actress stole the show at the 1998 MTV Video Music Awards by wearing a black beaded dress with a visible thong. She later claimed that the dress was a political statement in response to her sexual assault.',
      },
      {
        id: "17",
        title: "Kendall Jenner",
        image:
          "https://people.com/thmb/0pljxDIdbnIl5mBv0zqWLuIM7dE=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(666x0:668x2):format(webp)/chopard-arrivals-1-04f8e33ec5e8475485c5af0aa2dbc9aa.jpg",
        description:
          "Yet again at a Chopard party at the 2018 Cannes Film Celebration, Kendall exposed her areolas in a sparkling green Alexandre Vauthier network small dress layered over bare hued underwear. She finished the look with dark red-base heels and gold hoops.",
      },
      {
        id: "18",
        title: "Jennifer Lopez",
        image:
          "https://people.com/thmb/rd5BpyzCwys0RisJKW2zPYLKtkM=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(599x0:601x2):format(webp)/jlo-1daf901a4568450f8f7a9dd0825ca06c.jpg",
        description:
          "Subsequent to tolerating the Telemundo Star Grant — which regarded her achievements past her music profession — at the 2017 Latin Board Music Grants, the This Is Me... Presently star graced the occasion in a silver pattern outfit planned by Julien Macdonald",
      },
      {
        id: "19",
        title: "Miley Cyrus",
        image:
          "https://people.com/thmb/NTUPN4N0YJHIwYy5VmJl1AEBT4E=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(587x287:589x289):format(webp)/miley-cryus-488f277a2a754f9181463654090ecd66.jpg",
        description:
          'The "12 PM Sky" vocalist made a head-turning appearance at the 2020 MTV VMAs in a decorated sheer Mugler strapless outfit with matching drama gloves and gems by Loree Rodkin.',
      },
      {
        id: "20",
        title: "Addison Rae",
        image:
          "https://people.com/thmb/t7jKppLgFFbQIrZ0BRfUFi_uVw8=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(665x0:667x2):format(webp)/Addison-Rae-fcbc592e13d54fadb3b2dfe83d443356.jpg",
        description:
          "Christopher Esber's ensemble was worn by TikTok sensation Addison Rae, who was nominated for breakthrough social star at the 2021 Movie & TV Awards. a cross section bandeau-style bra top, a silver body chain, a long dark skirt and a dark coat. Beautician Regulation Insect assisted her top off the look with Stuart Weitzman shoes and gems by Djula and Le Vian.",
      },
      {
        id: "21",
        title: "Alessandra Ambrosio",
        image:
          "https://people.com/thmb/SJL03pIKU-tvsc6vV3T0X5K7cW0=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(614x0:616x2):format(webp)/alessandra-ambrosio-b91102b4151948828b73990f47d174f1.jpg",
        description:
          "Alessandra Ambrosio bared her butt cheeks in a Ralph & Russo mesh gown with crystals, Lorraine Schwartz jewelry, and gold Giuseppe Zanotti Design heels at the Vanity Fair Oscars afterparty in 2018.",
      },
      {
        id: "22",
        title: "Joan Smalls",
        image:
          "https://people.com/thmb/i4xa37iTg00ES3ekDXSHM7-eO_Y=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(707x0:709x2):format(webp)/joan-smalls-1-2000-fef0fbd991aa4835b352cac66c2d79f8.jpg",
        description:
          "Joan Smalls ventured out at the 2020 Vanity Fair Oscar all-nighter in a ruched two-conditioned blue skirt with a cloth hemline and silver precious stone pasties. The previous Victoria's Mysterious Holy messenger styled her hair aside — showing her jewel studs — and wore blue-shaded contacts to match her group.",
      },
      {
        id: "23",
        title: "Gisele Bündchen",
        image:
          "https://people.com/thmb/Vc748JUSlYlYKq2iTy_yDeG6zvQ=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(599x0:601x2):format(webp)/gisele-b2ba37e2d122445f9e688297075ee816.jpg",
        description:
          'Gisele Bündchen embraced the Met Affair\'s 2008 topic, "Superheroes: Style and Dream," by appearing in a smooth, pink silk Versace outfit with a super low back.',
      },
      {
        id: "24",
        title: "Heidi Klum",
        image:
          "https://people.com/thmb/Y5dT39lmrzwTGfL1WbcbBx6KT_k=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(599x0:601x2):format(webp)/heidi-klum-617844d916414f14b8c02f256f1b8172.jpg",
        description:
          "At the 2017 MTV VMAs, Heidi Klum showed off her cleavage in a gold, sheer Peter Dundas gown with a keyhole neckline and beaded design. She finished off the look with Lorraine Schwartz studs and Giuseppe Zanotti shoes.",
      },
      {
        id: "25",
        title: "Kendall Jenner",
        image:
          "https://people.com/thmb/se-gxM_28qax_5QvYjaU5ov8EW0=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(599x0:601x2):format(webp)/kendall-jenner-8099e316835249f0b390ac14ca4cc79c.jpg",
        description:
          "Kendall uncovered her areolas in a sheer, layered and silk-and-tulle white Schiaparelli High fashion outfit with Chopard gems while going to the Young ladies of the Sun debut during the 2018 Cannes Film Celebration.",
      },
      {
        id: "26",
        title: "Ciara",
        image:
          "https://people.com/thmb/NOQqNyDlBv1XnU9f7mbuawqy_2Q=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(465x0:467x2):format(webp)/ciara-8-9018c9fc32644600a26619b345455913.jpg",
        description:
          "Ciara went to the Vanity Fair Oscars all-nighter while pregnant with her third youngster with spouse and NFL star Russell Wilson) in 2020, wearing a bodice style bustier overlaid in shimmering sheer texture by Ralph and Russo, a matching headband and stiletto shoes.",
      },
      {
        id: "27",
        title: "Jennifer Lopez",
        image:
          "https://people.com/thmb/v7-JLJnLUQs8JBA1fkFSJZi1qYE=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(599x0:601x2):format(webp)/jennifer-lopez-02-9042e36392fc44e4b398250a6f0aa9d6.jpg",
        description:
          "Prior to tolerating the Telemundo Star Grant, Lopez showed up at the 2017 Latin Board Music Grants wearing a dark Julien MacDonald outfit with cutaway netting, a plunging neck area and a thigh-high cut — complete with Lynn Boycott studs and Jimmy Choo peep-toe heels.",
      },
      {
        id: "28",
        title: "Paula Abdul",
        image:
          "https://people.com/thmb/wtsA0CzhMhSHGn4t4JY71DejXrU=/4000x0/filters:no_upscale():max_bytes(150000):strip_icc():focal(651x0:653x2):format(webp)/paula-2000-efa58234a1514bfcb69b22fe9eb364c6.jpg",
        description:
          "Paula Abdul attended the 2015 American Music Awards in a dress by Charbel Zoe Couture with a short mesh train and see-through black embroidery.",
      },
    ],
  },
  {
    id: "2",
    title: "The Most Daring Red Carpet Looks of All Time",
    date: "13 September 2024",
    image:
      "https://billionaireweb.s3.eu-north-1.amazonaws.com/taylor+swift.png",
    description:
      "Anything goes on the red carpet, as demonstrated by Rihanna's Adam Selman \"naked dress\" and Jennifer Lopez's Versace dress with a jungle print. The aphorism for certain famous people? Toning it down would be ideal.",
    description2: `Kendall Jenner and Zoë Kravitz fled for small clothing finally year's Met Affair when the two of them went to mold's enormous night in tantalizingly sheer outfits. Back in 2015, Beyoncé showed up at a similar occasion in one of her most trying honorary pathway searches in a scarcely there dress by Riccardo Tisci for Givenchy, and in 2019, Emily Ratajkowski deciphered the Camp: Notes on Style topic in a vital stomach muscle uncovering showgirl look by Dundas. EmRata even figured out how to out dress herself at the current year's ball in a file Versace look that was fundamentally dots. Here are probably the most trying honorary pathway looks ever, which take a subtle approach with pretty much nothing.`,
    blogDetails: [
      {
        id: "1",
        title: "Megan Fox in Mugler",
        image:
          "https://assets.vogue.in/photos/627894008cd4b91f5309d0e5/master/w_1920%2Cc_limit/GettyImages-1340002403.jpg",
        description: "",
      },
      {
        id: "2",
        title: "Cher in Bob Mackie",
        image:
          "https://assets.vogue.in/photos/627894038cd4b91f5309d0e6/master/w_1920%2Cc_limit/GettyImages-797682.jpg",
        description:
          "",
      },
      {
        id: "3",
        title: "Beyoncé in Givenchy by Riccardo Tisci",
        image:
          "https://assets.vogue.in/photos/6278940518cc2b9e81de41bd/master/w_1920%2Cc_limit/GettyImages-473031300.jpg",
        description:
          "",
      },
      {
        id: "4",
        title: "Emily Ratajkowski in Dundas",
        image:
          "https://assets.vogue.in/photos/62789408df2aeda1f8f79cca/master/w_1920%2Cc_limit/GettyImages-1148091879.jpg",
        description:
          "",
      },
      {
        id: "5",
        title: "Zoë Kravitz in Saint Laurent",
        image:
          "https://assets.vogue.in/photos/6278940adf2aeda1f8f79ccb/master/w_1920%2Cc_limit/GettyImages-1340827207.jpg",
        description:
          "",
      },
      {
        id: "6",
        title: "Halle Berry in Elie Saab",
        image:
          "https://assets.vogue.in/photos/627894138cd4b91f5309d0e8/master/w_1920%2Cc_limit/GettyImages-110152660.jpg",
        description:
          "",
      },
      {
        id: "7",
        title: "Irina Shayk in Versace",
        image:
          "https://assets.vogue.in/photos/62789414056197311204c66a/master/w_1920%2Cc_limit/GettyImages-583884574.jpg",
        description:
          "",
      },
      {
        id: "8",
        title: "Hailey Bieber in Alexander Wang",
        image:
          "https://assets.vogue.in/photos/62789415df2aeda1f8f79cce/master/w_1920%2Cc_limit/GettyImages-1141862513.jpg",
        description:
          "",
      },
      {
        id: "9",
        title: "Kendall Jenner in Givenchy by Matthew Williams",
        image:
          "https://assets.vogue.in/photos/62789417056197311204c66b/master/w_1920%2Cc_limit/GettyImages-1340273771.jpg",
        description:
          "",
      },
      {
        id: "10",
        title: "Bella Hadid in Schiaparelli",
        image:
          "https://assets.vogue.in/photos/6278941818cc2b9e81de41bf/master/w_1920%2Cc_limit/GettyImages-1328328771.jpg",
        description:
          " ",
      },
      {
        id: "11",
        title: "Zendaya in Tom Ford",
        image:
          "https://assets.vogue.in/photos/6278941b056197311204c66c/master/w_1920%2Cc_limit/GettyImages-1199141625.jpg",
        description:
          "",
      },
      {
        id: "12",
        title: "Andra Day in Vera Wang",
        image:
          "https://assets.vogue.in/photos/6278941d18cc2b9e81de41c0/master/w_1920%2Cc_limit/GettyImages-1314438019.jpg",
        description:
          "",
      },
      {
        id: "13",
        title: "Jodie Turner-Smith in Gucci",
        image:
          "https://assets.vogue.in/photos/627894238cd4b91f5309d0ea/master/w_1920%2Cc_limit/GettyImages-1395085983.jpg",
        description:
          "",
      },
      {
        id: "14",
        title: "Courtney Love in Christian Dior by John Galliano",
        image:
          "https://assets.vogue.in/photos/627894268cd4b91f5309d0eb/master/w_1920%2Cc_limit/GettyImages-147599504.jpg",
        description:
          "",
      },
      {
        id: "15",
        title: "Iris Law in Moschino",
        image:
          "https://assets.vogue.in/photos/6278942a18cc2b9e81de41c2/master/w_1920%2Cc_limit/GettyImages-1395062956.jpg",
        description:
          '',
      }
    ],
  },
  {
    id: "3",
    title: "53 Times Celebrities Wore Naked Dresses on the Red Carpet",
    date: "13 September 2024",
    image:
      "https://billionaireweb.s3.eu-north-1.amazonaws.com/image+57.png",
    description:
      "Although the most memorable red carpets are always filled with outrageous outfits, they are not always red. The Met Function that shocks with a Kendall Jenner dress. The GRAMMY Awards ceremony that inspired Jennifer Lopez's iconic look. The MTV Video Music Grants night that prods Miley Cyrus to allow everything to hang out. Go along with us now as we return to more than 75 of the most vital, trying, genuinely shocking honorary pathway minutes from the monsters of the oeuvre. Or on the other hand, to make it plainer: here are loads of pictures of Chrissy Teigen, Kim Kardashian West, Rihanna, Cardi B, Woman Crazy and additional dazzling stars giving their all on honorary pathway to make us look.",
    description2: "",
    blogDetails: [
      {
        id: "1",
        title: "Tate McRae",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2022-09/vma2022_nova.jpg?width=640&quality=80",
        description: "Tate McRae went to the 2022 MTV Video Music Grants in Newark, New Jersey. The VIP reporter wore Niné.",
      },
      {
        id: "2",
        title: "Lil Nas X",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2022-09/vma2022_nasx.jpg?width=640&quality=80",
        description:
          "For the 2022 MTV Video Music Grants in New Jersey, the rapper didn't go little. The main thing greater than his shocking headpiece? an identical hoop skirt. Furthermore, not much else. Furthermore, that is completely fine with us.",
      },
      {
        id: "3",
        title: "Bebe Rexha",
        image:
          "https://assets.vogue.in/photos/6278940518cc2b9e81de41bd/master/w_1920%2Cc_limit/GettyImages-473031300.jpg",
        description:
          "In the event that you didn't see the stripes on Bebe Rexha's Vivienne Westwood dress, it's reasonable. The artist wore this striking number at the 2022 MTV VMAs.    ",
      },
      {
        id: "4",
        title: "Anitta",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2022-09/vma2022_red.jpg?width=640&quality=80",
        description:
          "The Brazilian entertainer wore this troupe to the MTV VMA grants in 2022. She then sang on the main stage.",
      },
      {
        id: "5",
        title: "Nicola Peltz",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2022-05/Nicola-Peltz-Getty-1395051768.jpg?width=640&quality=80",
        description:
          "Entertainer Nicola Peltz loves her some Valentino - - it was her outfit planner of decision for her wedding to Brooklyn Beckham, all things considered. Therefore, it stands to reason that she would again select Pierpaolo Piccioli from Valentino for the 2022 Met Gala. The hot pink gown did not leave much room for imagination!",
      },
      {
        id: "6",
        title: "Megan Fox",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2021-09/GettyImages-1340093764.jpg?width=640&quality=80",
        description:
          "The entertainer knocked some people's socks off on the 2021 MTV Video Music Grants honorary pathway. Fox wore a sheer scaled down dress by Mugler that took a subtle approach with close to nothing.",
      },
      {
        id: "7",
        title: "Lily James",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2022-05/Lily-James-Getty-1395072388.jpg?width=640&quality=80",
        description:
          "Yes, that is the Versace-provided, completely see-through beaded dress that the actress is wearing to the Met Ball in 2022. The look incorporated a thigh-high cut and stage heels.",
      },
      {
        id: "8",
        title: "Chloe Bailey and Halle Bailey",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2021-09/GettyImages-1342591331.jpg?width=640&quality=80",
        description:
          "In 2021, the sister act shone on honorary pathway for Worldwide Resident Live. They wore comparative bare, gem covered outfits.",
      },
      {
        id: "9",
        title: "Kimberly Garner",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2021-09/GettyImages-1343592835.jpg?width=640&quality=80",
        description:
          "In 2021, Gather went to the world debut of No Opportunity to Kick the bucket at the Regal Albert Lobby in London. The entertainer wore a dress with trying cuts.",
      },
      {
        id: "10",
        title: "Maggie Q",
        image:
          "https://assets.vogue.in/photos/6278941818cc2b9e81de41bf/master/w_1920%2Cc_limit/GettyImages-1328328771.jpg",
        description:
          "Before moving on to television roles in series like Nikita and Designated Survivor, Maggie Q worked as a model. So, when she actually worked a number in 2014 at the Los Angeles premiere of the first Divergent movie, she knew how to do so. One more dress of the beguilingly coy assortment, the Anthony Vaccarello creation was all business in front, and all party - - and drafty - - as an afterthought. The Night Standard decided that the outfit \"took a subtle approach with nearly nothing,\" while different media inclusion at the time zeroed in on how (or on the other hand if?) Maggie Q went \"commando\" to make the look total.",
      },
      {
        id: "11",
        title: "Gabrielle Union",
        image:
          "https://assets.vogue.in/photos/6278941b056197311204c66c/master/w_1920%2Cc_limit/GettyImages-1199141625.jpg",
        description:
          "The Being Mary Jane entertainer gets more sweltering as time passes as is confirmed by her style articulation at the 2017 Vanity Fair Oscar Party.",
      },
      {
        id: "12",
        title: "Olivia Culpo",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2018-12/olivia_culpo.jpg?width=640&quality=80",
        description:
          "The 26-year-old model went to the 2018 Calzedonia Summer Show in unmentionables covered by a scarcely there dress.",
      },
      {
        id: "13",
        title: "Kim Kardashian",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2018-12/kim_kardashian.jpg?width=640&quality=80",
        description:
          "A stunner in Balmain, the Staying aware of the Kardashians star set her physical make up for anyone to see at Paris Style Week in 2016.",
      },
      {
        id: "14",
        title: "Iggy Azalea",
        image:
          "https://static1.purepeople.com.br/articles/9/21/95/69/@/2516344-o-look-de-iggy-azalea-ainda-apresentava-580x0-4.jpg",
        description:
          "The \"Extravagant\" rapper and her cautiously arranged outfit look impeccable at the 2013 MTV EMAs.",
      },
      {
        id: "15",
        title: "Sara Sampaio",
        image:
          "https://assets.vogue.in/photos/6278942a18cc2b9e81de41c2/master/w_1920%2Cc_limit/GettyImages-1395062956.jpg",
        description:
          'The staggering Portuguese model ventured out at Style for Help Cannes 2018 during the 71st yearly Cannes Film Celebration.',
      },
      {
        id: "16",
        title: "Chantel Jeffries",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2018-12/chantel_jeffries.jpg?width=640&quality=80",
        description:
          'The 2018 Cannes Film Celebration warmed up with the assistance of the shocking DJ.',
      },
      {
        id: "17",
        title: "Winnie Harlow",
        image:
          "https://www.etonline.com/sites/default/files/styles/640xh/public/images/2019-11/winnie_harlow_-_ps_edit.jpg?width=640&quality=80",
        description:
          'The supermodel presented in a transparent red dress at the 2019 Cannes Film Celebration debut of Some time ago... In Hollywood.',
      }
    ],
  },
  {
    id: "4",
    title: "47 Crazy Celebrity Outfits We'll Never Forget",
    date: "13 September 2024",
    image:
      "https://billionaireweb.s3.eu-north-1.amazonaws.com/Mask+group+(1).png",
    description:
      "We love a superstar that won't hesitate to face challenges, attempt recent fads, or simply be truly beyond preposterous. It could not necessarily land them on best-dressed list, but rather it sure makes briefly. Navigate for the most out of control honorary pathway looks we will always remember.",
    description2: "",
    blogDetails: [
      {
        id: "1",
        title: "Kiernan Shipka",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170574813-66df66343901f.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description: "In Elie Saab on September 6.",
      },
      {
        id: "2",
        title: "Grace Vanderwaal",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2171101708-66e0844060bf0.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "3",
        title: "Leila George",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/leila-george-attends-the-premiere-of-disclaimer-during-the-news-photo-1725989992.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "4",
        title: "Zoe Saldaña",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2171012458-66df5dfb0af87.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "5",
        title: "Cate Blanchett",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2171033344-66df5fe8ec6a2.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "6",
        title: "Gabrielle Union and Dwyane Wade",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2171003432-66df5e554435b.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "7",
        title: "Jennifer Coolidge",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170999910-66df5e39efb65.jpg?crop=0.9995002498750624xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "8",
        title: "Cate Blanchett",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170881443-66df600b82b2d.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "9",
        title: "Angelina Jolie",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170892769-66df5efb33de0.jpg?crop=0.9998000399920016xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "10",
        title: "Salma Hayek",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170891923-66df04a86651c.jpg?crop=0.9996666666666666xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "11",
        title: "Lupita Nyong’o",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170849636-66df634704ee3.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "12",
        title: "Cate Blanchett",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170832770-66df5fb95d6ac.jpg?crop=1xw:0.99975xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "13",
        title: "Karla Sofía Gascón, Zoe Saldaña, and Selena Gomez",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170889929-66df5f6fe3932.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "14",
        title: "Salma Hayek",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/salma-hayek-pinault-speaks-onstage-during-the-tiff-tribute-news-photo-1725915016.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          "",
      },
      {
        id: "15",
        title: "Lupita Nyong’o",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170886163-66df629ee2e58.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "16",
        title: "Amy Adams",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/amy-adams-attends-the-tiff-tribute-awards-during-the-2024-news-photo-1725915259.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "17",
        title: "Sandra Oh",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170263747-66df66a643bb6.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "18",
        title: "Elizabeth Olsen",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/elizabeth-olsen-attends-the-premiere-of-the-assessment-news-photo-1725915304.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "19",
        title: "Alicia Vikander",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170885579-66df62dd6d952.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "20",
        title: "Stephanie Hsu",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170850924-66df6316116b4.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "21",
        title: "Mikey Madison",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170883778-66df643da549d.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "22",
        title: "Daisy Edgar-Jones",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170097000-66df6168b5996.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "23",
        title: "Jacob Elordi",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170097011-66df65d2ded8c.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "24",
        title: "Sydney Sweeney",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170118081-66df619045a7b.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "26",
        title: "Amy Adams",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170115965-66df6048e174d.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
      {
        id: "27",
        title: "Ana de Armas",
        image:
          "https://hips.hearstapps.com/hmg-prod/images/gettyimages-2170096970-66df6383dd413.jpg?crop=1xw:1xh;center,top&resize=980:*",
        description:
          '',
      },
    ],
  },
  {
    id: "5",
    title: "Rare Photos of Famous People That We’ve Never Seen Before",
    date: "13 September 2024",
    image:
      "https://billionaireweb.s3.eu-north-1.amazonaws.com/image+59.png",
    description:
      "Famous people frequently become related with a specific picture that sticks with them over the long run. Unfortunately, a significant number of us battle to recollect how these famous figures showed up in their childhood or different jobs.",
    description2: "",
    blogDetails: [
      {
        id: "1",
        title: "Maggie Smith",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/f03/6a2/f695e859d68496991de7a58557.jpg.webp",
        description: "",
      },
      {
        id: "2",
        title: "Twiggy",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/574/e3b/4df40a50efa6fb5729fce65487.jpg.webp",
        description:
          "",
      },
      {
        id: "3",
        title: "Marilyn Monroe",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/955/883/3958275984946e4d3ffc5b4fa1.jpg.webp",
        description:
          "",
      },
      {
        id: "4",
        title: "Helen Mirren",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/955/a10/03bede557ca9c4100a3e183d6e.jpg.webp",
        description:
          "",
      },
      {
        id: "5",
        title: "Charlie Chaplin",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/9dc/cb0/643ed05d108131de7b98109c7b.jpg.webp",
        description:
          "",
      },
      {
        id: "6",
        title: "Karl Lagerfeld",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/4d7/dbe/643a285e65a2391961ed24deb6.jpg.webp",
        description:
          "",
      },
      {
        id: "7",
        title: "Albert Einstein",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/6e8/d2e/c074805c4eabc72fe44133135c.jpg.webp",
        description:
          "",
      },
      {
        id: "8",
        title: "Ernest Hemingway",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/9f2/185/f5891c511193240a96401ff73c.jpg.webp",
        description:
          "",
      },
      {
        id: "9",
        title: "Vincent van Gogh",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/f35/b79/942f565801a88809ebe9d5b897.jpg.webp",
        description:
          "",
      },
      {
        id: "10",
        title: "Sophia Loren",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/b68/9c1/06f7b15c189b8fca5f3a6392b3.jpg.webp",
        description:
          "",
      },
      {
        id: "11",
        title: "David Suchet",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/59d/d01/d2210a5cb0a8fbb0d9e5ff4646.jpg.webp",
        description:
          "",
      },
      {
        id: "12",
        title: "Elvis Presley",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/05d/d4e/60aabc5798bfc05b3cbce2156f.jpg.webp",
        description:
          "",
      },
      {
        id: "13",
        title: "Cher",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/344/7e5/9ca9df5063a1f1ad34a0757d2a.jpg.webp",
        description:
          "",
      },
      {
        id: "14",
        title: "Steve Jobs",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/ffc/893/92419759f88bbfbb2228bcb052.jpg.webp",
        description:
          "",
      },
      {
        id: "15",
        title: "Peter Falk",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/f03/766/87e10751b88a365e9d41b44fa8.jpg.webp",
        description:
          '',
      },
    ],
  },
  {
    id: "6",
    title: "The Most Scandalous Affairs In History",
    date: "13 September 2024",
    image:
      "https://billionaireweb.s3.eu-north-1.amazonaws.com/Mask+group+(2).png",
    description:
      "Numerous famous couples have fallen in love and tied the knot throughout the years. But if there's one thing Hollywood has taught us, it's that happily ever after isn't always the outcome of a relationship (and occasionally, celebrity affairs steal the show).",
    description2: `Therefore, despite the fact that fame and wealth can bring with them many comforts, long days on set or in other locations have been known to spark a variety of scandals over the years. We would also be lying if we claimed that we weren't frequently enthralled with the gripping details of celebrity relationship breakdowns.`,
    blogDetails: [
      {
        id: "1",
        title: "Dave Grohl and an anonymous woman",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/2024/09/dave-grohl-family-wife-cheating-baby-1024x1024.jpg",
        description: `Say what you will about Foo Fighters singer Dave Grohl's bold and honest move, there is something refreshingly honest about his recent admission that he welcomed a child with a woman who was not his wife. "I've recently become the father of a new baby daughter, born outside of my marriage. I plan to be a loving and supportive parent to her. I love my wife and my children, and I am doing everything I can to regain their trust and earn their forgiveness," Grohl wrote.`,
      },
      {
        id: "2",
        title: "Ashton Kutcher, Sara Leal and Demi Moore",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77378/1569368903056_gettyimages-953411400.jpg",
        description:
          `Moore's 2019 biography Inside Out states that she confronted Kutcher after learning of the alleged infidelity through a Google alert, and that she "admitted it right away." Their marriage collapsed as a result of this high-profile affair, and they divorced legally in 2013.`,
      },
      {
        id: "3",
        title: "Marilyn Monroe and John F. Kennedy",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77361/1511244513102_celebrity-affairs.jpg",
        description:
          `One of the most well-known celebrity scandals in contemporary history, including both Hollywood and politics, is this one. When JFK was still married to Jackie Kennedy, there were rumors that the two had an affair. These rumors were stoked even more by Marilyn Monroe's seductive rendition of "Happy Birthday" to the president. Rumors also circulated that Attorney General Robert F. Kennedy, JFK's brother, and Monroe were having an affair.`,
      },
      {
        id: "4",
        title: "David Beckham and Rebecca Loos",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77362/1511244582668_celebrity-affairs-1.jpg",
        description:
          `Beckham released the following statement as a result of this: "The plain reality is that I am extremely happily married. My wife is amazing, and I have two really exceptional children. Nothing that a third party might do would alter these realities. The fashion designer said that there had been "bumps along the road" in his relationship with former Spice Girl Victoria Beckham, despite the fact that this has strained their connection. However, the truth is that we've emerged from everything happier and stronger.`
      },
      {
        id: "5",
        title: "Elizabeth Taylor and Eddie Fisher",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77363/1511244633606_celebrity-affairs-2.jpg",
        description:
          `The Elizabeth Taylor and Eddie Fisher affair was one of the major celebrity scandals of the 1950s. After providing consolation to Taylor following the death of her husband Mike Todd, Fisher—who was then married to Debbie Reynolds—became enamored with the actress. In 1959, he was divorced from Reynolds and wed Taylor. But just five years later, Taylor divorced Fisher in favor of Richard Burton, her co-star in Cleopatra. 1964 saw their marriage.`
      },
      {
        id: "6",
        title: "Brad Pitt and Angelina Jolie",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77355/gettyimages-87884924.jpg",
        description:
          `When love blossomed on the set of Mr. & Mrs. Smith, Brad Pitt was married to Jennifer Aniston, his co-star Angelina Jolie. 2005 saw Aniston file for divorce, and in 2008, Jolie made headlines when she revealed in an interview that she and Pitt were excited for their kids to attend Mr. & Mrs. Smith because, as she put it, "not many people get to see a movie where their parents fell in love." 2016 saw the famous couple's breakup following a ten-year romance.`
      },
      {
        id: "7",
        title: "Billy Bob Thornton, Laura Dern and Angelina Jolie",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77364/1592356323700_gettyimages-84387270.jpg",
        description:
          `Prior to her relationship with Brad Pitt, Angelina Jolie was entangled in a romantic triangle of sorts with Laura Dern, star of Big Little Lies, and actor Billy Bob Thornton, to whom Jolie eventually got married. In the late 1990s, Dern and Thornton had been dating for around two years when their relationship came to an abrupt stop. Prior to officially calling it quits on his relationship with the Marriage Story star, Thornton allegedly left Dern to wed Jolie.`
      },
      {
        id: "8",
        title: "Humphrey Bogart and Lauren Bacall",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77365/gettyimages-3208710.jpg",
        description:
          `When Humphrey Bogart was filming To Have and Have Not in 1944, he met Lauren Bacall. She was only 19 years old, and he was 44 and married at the time. Despite their 25-year age difference, the two Hollywood celebrities started dating.`
      },
      {
        id: "9",
        title: "Prince Charles, Camilla Parker Bowles and Princess Diana",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77366/1592356361481_gettyimages-52118493-1.jpg",
        description:
          `It was the scandal that rocked the monarchy and made international tabloid headlines. After marrying Princess Diana in 1981, Prince Charles had been with her for five years when he started seeing Camilla, his previous lover, in secret. At the time, Camilla was also married.`
      },
      {
        id: "10",
        title: "Princess Diana, James Hewitt and Prince Charles",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77367/1592357429152_1511244582668celebrity-affairs-11.jpg",
        description:
          `James Hewitt, a British cavalry commander and polo player, met Princess Diana at a reception in 1986 and reportedly volunteered to help her overcome her fear of horses, starting the celebrity affairs of these royals. Lessons on horseback riding eventually resulted in a romance. In a 1995 interview, Diana acknowledged having an affair with Hewitt, stating, "Yes, I adored him." I did fall in love with him, yes. But I felt really disappointed.`
      },
      {
        id: "11",
        title: "Hugh Grant and Elizabeth Hurley",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77368/1511244728232_celebrity-affairs-3.jpg",
        description:
          `It doesn't get much more dramatic than this in celebrity relationships. Hurley was in a relationship with Grant when he was arrested in 1995 in Los Angeles for misdemeanor indecent conduct in a public place with Hollywood prostitute Divine Brown. The performer paid a fine and entered a no-contest plea. A few days later, he made an appearance on The Tonight Show, where he confessed to his transgression, saving his career and winning the public's forgiveness. Hurley initially pardoned Grant, but the two parted ways in 2000.`
      },
      {
        id: "12",
        title: "Frank Sinatra and Ava Gardner",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/2020/10/1592358224033_gettyimages-545968023.jpg",
        description:
          `Jazz crooner Frank Sinatra, who was married to actress Ava Gardner while still married to his wife Nancy, with whom he shared three children, is regarded as one of the most notorious Hollywood couples of the 1940s and 1950s. Eventually, he moved on from Nancy to marry Gardner in 1951. But the drama didn't end there. There was more to Sinatra's celebrity relationships—of which he had plenty throughout his life. Gardner and the singer separated in 1957 as a result of the singer's adultery, demonstrating that not all high-profile relationships last forever.`
      },
      {
        id: "13",
        title: "Jude Law and Sienna Miller",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77370/1511244752548_celebrity-affairs-6.jpg",
        description:
          `Jude Law acknowledged cheating on his fiancée Sienna Miller in 2005 by using his kids' babysitter. Law quickly released an apology to the public, saying, "I'm deeply ashamed and upset that I've hurt Sienna."`
      },
      {
        id: "14",
        title: "Sophia Loren and Cary Grant",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77371/1592358821144_gettyimages-99045546.jpg",
        description:
          `Italian siren and Hollywood hottie Grant first appeared on cinema together in 1958's Houseboat. Actress Betsy Drake, who was cast opposite her husband in the movie Houseboat, created the original script for the picture. At the time, Cary Grant's wife. According to the account, Grant, who was 54 at the time, had a fresh script written to replace his wife in the role after Loren, who was 23, started having an affair. Loren subsequently left Grant to wed Carlo Ponti, and Drake never got credit for writing the film. The Italian actress first acknowledged the romance in 2014, although she maintains that it was "unconsummated."`
      },
      {
        id: "15",
        title: "Arnold Schwarzenegger, Maria Shriver and Brigitte Nielsen",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77372/1511244772996_celebrity-affairs-4.jpg",
        description:
          `In a 2011 biography, Danish actress Brigitte Nielsen disclosed that she and Arnold Schwarzenegger had an affair at the time he was married to Maria Shriver. After confessing to the affair, Schwarzenegger's 25-year marriage to the newswoman and Kennedy family's daughter ended.`
      },
    ],
  },
  {
    id: "7",
    title: "Do you Want to know about latest street style?",
    date: "13 September 2024",
    category: "life style",
    image:
      "https://billionaireweb.s3.eu-north-1.amazonaws.com/street-style.png",
    description:
      "Numerous famous couples have fallen in love and tied the knot throughout the years. But if there's one thing Hollywood has taught us, it's that happily ever after isn't always the outcome of a relationship (and occasionally, celebrity affairs steal the show).",
    description2: `Therefore, despite the fact that fame and wealth can bring with them many comforts, long days on set or in other locations have been known to spark a variety of scandals over the years. We would also be lying if we claimed that we weren't frequently enthralled with the gripping details of celebrity relationship breakdowns.`,
    categoriesBlogs: [
      {
        id: "1",
        title: "Dave Grohl and an anonymous woman",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/2024/09/dave-grohl-family-wife-cheating-baby-1024x1024.jpg",
        description: `Say what you will about Foo Fighters singer Dave Grohl's bold and honest move, there is something refreshingly honest about his recent admission that he welcomed a child with a woman who was not his wife. "I've recently become the father of a new baby daughter, born outside of my marriage. I plan to be a loving and supportive parent to her. I love my wife and my children, and I am doing everything I can to regain their trust and earn their forgiveness," Grohl wrote.`,
      },
      {
        id: "2",
        title: "Ashton Kutcher, Sara Leal and Demi Moore",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77378/1569368903056_gettyimages-953411400.jpg",
        description:
          `Moore's 2019 biography Inside Out states that she confronted Kutcher after learning of the alleged infidelity through a Google alert, and that she "admitted it right away." Their marriage collapsed as a result of this high-profile affair, and they divorced legally in 2013.`,
      },
      {
        id: "3",
        title: "Marilyn Monroe and John F. Kennedy",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77361/1511244513102_celebrity-affairs.jpg",
        description:
          `One of the most well-known celebrity scandals in contemporary history, including both Hollywood and politics, is this one. When JFK was still married to Jackie Kennedy, there were rumors that the two had an affair. These rumors were stoked even more by Marilyn Monroe's seductive rendition of "Happy Birthday" to the president. Rumors also circulated that Attorney General Robert F. Kennedy, JFK's brother, and Monroe were having an affair.`,
      },
      {
        id: "4",
        title: "David Beckham and Rebecca Loos",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77362/1511244582668_celebrity-affairs-1.jpg",
        description:
          `Beckham released the following statement as a result of this: "The plain reality is that I am extremely happily married. My wife is amazing, and I have two really exceptional children. Nothing that a third party might do would alter these realities. The fashion designer said that there had been "bumps along the road" in his relationship with former Spice Girl Victoria Beckham, despite the fact that this has strained their connection. However, the truth is that we've emerged from everything happier and stronger.`
      },
      {
        id: "5",
        title: "Elizabeth Taylor and Eddie Fisher",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77363/1511244633606_celebrity-affairs-2.jpg",
        description:
          `The Elizabeth Taylor and Eddie Fisher affair was one of the major celebrity scandals of the 1950s. After providing consolation to Taylor following the death of her husband Mike Todd, Fisher—who was then married to Debbie Reynolds—became enamored with the actress. In 1959, he was divorced from Reynolds and wed Taylor. But just five years later, Taylor divorced Fisher in favor of Richard Burton, her co-star in Cleopatra. 1964 saw their marriage.`
      },
      {
        id: "6",
        title: "Brad Pitt and Angelina Jolie",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77355/gettyimages-87884924.jpg",
        description:
          `When love blossomed on the set of Mr. & Mrs. Smith, Brad Pitt was married to Jennifer Aniston, his co-star Angelina Jolie. 2005 saw Aniston file for divorce, and in 2008, Jolie made headlines when she revealed in an interview that she and Pitt were excited for their kids to attend Mr. & Mrs. Smith because, as she put it, "not many people get to see a movie where their parents fell in love." 2016 saw the famous couple's breakup following a ten-year romance.`
      },
      {
        id: "7",
        title: "Billy Bob Thornton, Laura Dern and Angelina Jolie",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77364/1592356323700_gettyimages-84387270.jpg",
        description:
          `Prior to her relationship with Brad Pitt, Angelina Jolie was entangled in a romantic triangle of sorts with Laura Dern, star of Big Little Lies, and actor Billy Bob Thornton, to whom Jolie eventually got married. In the late 1990s, Dern and Thornton had been dating for around two years when their relationship came to an abrupt stop. Prior to officially calling it quits on his relationship with the Marriage Story star, Thornton allegedly left Dern to wed Jolie.`
      },
      {
        id: "8",
        title: "Humphrey Bogart and Lauren Bacall",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77365/gettyimages-3208710.jpg",
        description:
          `When Humphrey Bogart was filming To Have and Have Not in 1944, he met Lauren Bacall. She was only 19 years old, and he was 44 and married at the time. Despite their 25-year age difference, the two Hollywood celebrities started dating.`
      },
      {
        id: "9",
        title: "Prince Charles, Camilla Parker Bowles and Princess Diana",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77366/1592356361481_gettyimages-52118493-1.jpg",
        description:
          `It was the scandal that rocked the monarchy and made international tabloid headlines. After marrying Princess Diana in 1981, Prince Charles had been with her for five years when he started seeing Camilla, his previous lover, in secret. At the time, Camilla was also married.`
      },
      {
        id: "10",
        title: "Princess Diana, James Hewitt and Prince Charles",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77367/1592357429152_1511244582668celebrity-affairs-11.jpg",
        description:
          `James Hewitt, a British cavalry commander and polo player, met Princess Diana at a reception in 1986 and reportedly volunteered to help her overcome her fear of horses, starting the celebrity affairs of these royals. Lessons on horseback riding eventually resulted in a romance. In a 1995 interview, Diana acknowledged having an affair with Hewitt, stating, "Yes, I adored him." I did fall in love with him, yes. But I felt really disappointed.`
      },
      {
        id: "11",
        title: "Hugh Grant and Elizabeth Hurley",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77368/1511244728232_celebrity-affairs-3.jpg",
        description:
          `It doesn't get much more dramatic than this in celebrity relationships. Hurley was in a relationship with Grant when he was arrested in 1995 in Los Angeles for misdemeanor indecent conduct in a public place with Hollywood prostitute Divine Brown. The performer paid a fine and entered a no-contest plea. A few days later, he made an appearance on The Tonight Show, where he confessed to his transgression, saving his career and winning the public's forgiveness. Hurley initially pardoned Grant, but the two parted ways in 2000.`
      },
      {
        id: "12",
        title: "Frank Sinatra and Ava Gardner",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/2020/10/1592358224033_gettyimages-545968023.jpg",
        description:
          `Jazz crooner Frank Sinatra, who was married to actress Ava Gardner while still married to his wife Nancy, with whom he shared three children, is regarded as one of the most notorious Hollywood couples of the 1940s and 1950s. Eventually, he moved on from Nancy to marry Gardner in 1951. But the drama didn't end there. There was more to Sinatra's celebrity relationships—of which he had plenty throughout his life. Gardner and the singer separated in 1957 as a result of the singer's adultery, demonstrating that not all high-profile relationships last forever.`
      },
      {
        id: "13",
        title: "Jude Law and Sienna Miller",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77370/1511244752548_celebrity-affairs-6.jpg",
        description:
          `Jude Law acknowledged cheating on his fiancée Sienna Miller in 2005 by using his kids' babysitter. Law quickly released an apology to the public, saying, "I'm deeply ashamed and upset that I've hurt Sienna."`
      },
      {
        id: "14",
        title: "Sophia Loren and Cary Grant",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77371/1592358821144_gettyimages-99045546.jpg",
        description:
          `Italian siren and Hollywood hottie Grant first appeared on cinema together in 1958's Houseboat. Actress Betsy Drake, who was cast opposite her husband in the movie Houseboat, created the original script for the picture. At the time, Cary Grant's wife. According to the account, Grant, who was 54 at the time, had a fresh script written to replace his wife in the role after Loren, who was 23, started having an affair. Loren subsequently left Grant to wed Carlo Ponti, and Drake never got credit for writing the film. The Italian actress first acknowledged the romance in 2014, although she maintains that it was "unconsummated."`
      },
      {
        id: "15",
        title: "Arnold Schwarzenegger, Maria Shriver and Brigitte Nielsen",
        image:
          "https://www.marieclaire.com.au/wp-content/uploads/sites/5/media/77372/1511244772996_celebrity-affairs-4.jpg",
        description:
          `In a 2011 biography, Danish actress Brigitte Nielsen disclosed that she and Arnold Schwarzenegger had an affair at the time he was married to Maria Shriver. After confessing to the affair, Schwarzenegger's 25-year marriage to the newswoman and Kennedy family's daughter ended.`
      },
    ],
  },
  {
    id: "8",
    title: "The best Street style from new york fashion week spring",
    date: "13 September 2024",
    category: "life style",
    image:
      "https://billionaireweb.s3.eu-north-1.amazonaws.com/week-spring.png",
    description:
      "Famous people frequently become related with a specific picture that sticks with them over the long run. Unfortunately, a significant number of us battle to recollect how these famous figures showed up in their childhood or different jobs.",
    description2: "",
    categoriesBlogs: [
      {
        id: "1",
        title: "Maggie Smith",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/f03/6a2/f695e859d68496991de7a58557.jpg.webp",
        description: "",
      },
      {
        id: "2",
        title: "Twiggy",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/574/e3b/4df40a50efa6fb5729fce65487.jpg.webp",
        description:
          "",
      },
      {
        id: "3",
        title: "Marilyn Monroe",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/955/883/3958275984946e4d3ffc5b4fa1.jpg.webp",
        description:
          "",
      },
      {
        id: "4",
        title: "Helen Mirren",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/955/a10/03bede557ca9c4100a3e183d6e.jpg.webp",
        description:
          "",
      },
      {
        id: "5",
        title: "Charlie Chaplin",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/9dc/cb0/643ed05d108131de7b98109c7b.jpg.webp",
        description:
          "",
      },
      {
        id: "6",
        title: "Karl Lagerfeld",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/4d7/dbe/643a285e65a2391961ed24deb6.jpg.webp",
        description:
          "",
      },
      {
        id: "7",
        title: "Albert Einstein",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/6e8/d2e/c074805c4eabc72fe44133135c.jpg.webp",
        description:
          "",
      },
      {
        id: "8",
        title: "Ernest Hemingway",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/9f2/185/f5891c511193240a96401ff73c.jpg.webp",
        description:
          "",
      },
      {
        id: "9",
        title: "Vincent van Gogh",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/f35/b79/942f565801a88809ebe9d5b897.jpg.webp",
        description:
          "",
      },
      {
        id: "10",
        title: "Sophia Loren",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/b68/9c1/06f7b15c189b8fca5f3a6392b3.jpg.webp",
        description:
          "",
      },
      {
        id: "11",
        title: "David Suchet",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/59d/d01/d2210a5cb0a8fbb0d9e5ff4646.jpg.webp",
        description:
          "",
      },
      {
        id: "12",
        title: "Elvis Presley",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/05d/d4e/60aabc5798bfc05b3cbce2156f.jpg.webp",
        description:
          "",
      },
      {
        id: "13",
        title: "Cher",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/344/7e5/9ca9df5063a1f1ad34a0757d2a.jpg.webp",
        description:
          "",
      },
      {
        id: "14",
        title: "Steve Jobs",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/ffc/893/92419759f88bbfbb2228bcb052.jpg.webp",
        description:
          "",
      },
      {
        id: "15",
        title: "Peter Falk",
        image:
          "https://wl-brightside.cf.tsp.li/resize/728x/webp/f03/766/87e10751b88a365e9d41b44fa8.jpg.webp",
        description:
          '',
      },
    ],
  },
];
