import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa';
import Base_Url from '../api/baseUrl';

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};
const Modal = ({ isOpen, onClose }) => {
    const [blogData, setBlogData] = useState([])
    const page = 0;
    const limit = 100;
    const allBlogData = async () => {
        try {
            const response = await axios.get(`${Base_Url}api/get-blog?limit=${limit}&page=${page + 1}`);
            const fetchedData = response?.data?.data || [];
            const shuffledData = shuffleArray([...fetchedData]);
            const randomRecords = shuffledData.slice(0,6);
            setBlogData(randomRecords);
        } catch (error) {
            console.error("Error fetching blog data:", error);
        }
    };
    useEffect(() => {
        if(isOpen){
        allBlogData()
        }
    }, [isOpen])
    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);
    if (!isOpen) return null;
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg w-full max-w-5xl relative max-h-[95vh] overflow-y-auto m-2">
                <button
                    className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
                    onClick={onClose}
                >
                    <FaTimes className="w-6 h-6" />
                </button>
                <h2 className="text-3xl font-semibold mb-4 text-[#000] flex justify-center">Related Blogs</h2>
                <div className='grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4'>
                    {blogData && blogData?.map((x, index) => {
                        return <>
                         <a href={`/blog?blog=${x?.slug}`} className='no-underline'>
                            <div key={index} className='overflow-hidden' onClick={() => {
                                let clickCount = localStorage.getItem("clickCount") || 0;
                                let click = Number(clickCount);
                                localStorage.setItem("clickCount", click + 1);
                            }}>
                                <div className='h-[220px]'>
                                    <img src={x?.image} alt={`Blog ${index}`} className='w-full h-full object-cover' />
                                </div>
                                    <h4 className='text-[#000] font-semibold mt-2 text-[18px] leading-[29px] cursor-pointer' >{x?.title}</h4>
                            </div>
                            </a>
                        </>
                    })}
                </div>
            </div>
        </div>
    );
};

export default Modal