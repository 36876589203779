import React from "react";
import pufferBag from "../assests/image 127.jpg";
import product1 from "../assests/Group 406.png";
import product2 from "../assests/Group 407.png";
import product3 from "../assests/Group 433.png";
import Mask1 from "../assests/mask1.png";
import Mask2 from "../assests/mask2.png";
import Mask3 from "../assests/mask3.png";
import Mask4 from "../assests/mask4.png";
import Mask5 from "../assests/mask5.png";
import maskProduct1 from "../assests/Group 405.png";
import maskProduct2 from "../assests/Group 407 (1).png";
import maskProduct3 from "../assests/Group 406 (4).png";
import maskProduct4 from "../assests/Group 405 (2).png";
import maskProduct5 from "../assests/Group 407 (2).png";
import maskProduct6 from "../assests/Group 406 (5).png";
import maskProduct7 from "../assests/Group 405 (3).png";
import maskProduct8 from "../assests/Group 407 (3).png";
import maskProduct9 from "../assests/Group 406 (7).png";
import maskProduct10 from "../assests/Group 405 (4).png";
import maskProduct11 from "../assests/Group 407 (4).png";
import maskProduct12 from "../assests/Group 406 (8).png";
import maskProduct13 from "../assests/Group 405 (5).png";
import maskProduct14 from "../assests/group1.png";
import maskProduct15 from "../assests/Group 406 (9).png";

const MillionaireShopping = () => {
  return (
    <div>
      <div className="bg-white py-10 px-3">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
            <div className="">
              <img src={pufferBag} alt="Puffer Tote Bag" />
            </div>

            <div className="text-center">
              <h2
                className="font-bold mb-4"
                style={{
                  fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
                }}
              >
                Puffer Tote Bags You’re Going To Obsess Over
              </h2>
              <p className="text-[18px] text-gray-600">
                With All The Hype Around Puffer Styles For The Fall And Winter
                Seasons, Why Not Incorporate The Quilted Look Into More Than
                Just A Winter Jacket Or Vest With A Chic Tote Bag? Here, Find
                The Best Puffer Tote Bags That Are Both Cool And Functional.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 items-center mt-28">
            <div className="">
              <img src={product1} alt="Vee Collective Porter Tote" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Vee Collective Porter Tote
              </p>
            </div>
            <div>
              <img src={product2} alt="Bottega Veneta Arco Puffy Tote" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Bottega Veneta Arco Puffy Tote
              </p>
            </div>
            <div>
              <img src={product3} alt="Coach Mini Pillow Tote Bag" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Coach Mini Pillow Tote Bag
              </p>
            </div>
            <div className="">
              <img src={product1} alt="Vee Collective Porter Tote" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Vee Collective Porter Tote
              </p>
            </div>
            <div>
              <img src={product2} alt="Bottega Veneta Arco Puffy Tote" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Bottega Veneta Arco Puffy Tote
              </p>
            </div>
            <div>
              <img src={product3} alt="Coach Mini Pillow Tote Bag" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Coach Mini Pillow Tote Bag
              </p>
            </div>
            <div className="">
              <img src={product1} alt="Vee Collective Porter Tote" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Vee Collective Porter Tote
              </p>
            </div>
            <div>
              <img src={product2} alt="Bottega Veneta Arco Puffy Tote" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Bottega Veneta Arco Puffy Tote
              </p>
            </div>
            <div>
              <img src={product3} alt="Coach Mini Pillow Tote Bag" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Coach Mini Pillow Tote Bag
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center mt-28">
            <div className="">
              <img src={Mask1} alt="The Best Boob Type" />
            </div>

            <div className="text-center">
              <h2
                className="font-bold mb-4"
                style={{
                  fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
                }}
              >
                The Best Boob Tapes to Lift You Up (and Keep You There)
              </h2>
              <p className="text-[18px] text-gray-600">
                Some say that the only comfortable bra is no bra at all. And
                while that might be true, braless looks come with their own set
                of drawbacks. To seamlessly flaunt all those sheer and plunging
                styles that have ruled this year’s fashion trends, keeping a
                roll of the best boob tape ready is critical when you’re in
                search of that elusive, no-show effect.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 items-center mt-28">
            <div className="">
              <img src={maskProduct1} alt="Booby Tape Original Breast Tape" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Booby Tape Original Breast Tape
              </p>
            </div>
            <div>
              <img
                src={maskProduct2}
                alt="MAGIC Bodyfashion Invisible Clear Breast Tape"
              />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                MAGIC Bodyfashion Invisible Clear Breast Tape
              </p>
            </div>
            <div>
              <img src={maskProduct3} alt="KMBoobies Boob Tape" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                KMBoobies Boob Tape
              </p>
            </div>
            <div className="">
              <img src={maskProduct1} alt="Booby Tape Original Breast Tape" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Booby Tape Original Breast Tape
              </p>
            </div>
            <div>
              <img
                src={maskProduct2}
                alt="MAGIC Bodyfashion Invisible Clear Breast Tape"
              />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                MAGIC Bodyfashion Invisible Clear Breast Tape
              </p>
            </div>
            <div>
              <img src={maskProduct3} alt="KMBoobies Boob Tape" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                KMBoobies Boob Tape
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center mt-28">
            <div className="">
              <img src={Mask2} alt="Perfect Stocking Stuffers " />
            </div>

            <div className="text-center">
              <h2
                className="font-bold mb-4"
                style={{
                  fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
                }}
              >
                Perfect Stocking Stuffers for Your Wife (or Anyone, Really)
              </h2>
              <p className="text-[18px] text-gray-600">
                Right off the bat, let me say that it is not too early to start
                shopping for Christmas. It’ll be here before you know it. As
                much as everyone likes to boast their love of the holidays for
                all the family time and meaningful yuletide hullabaloo, everyone
                knows the best part is actually the presents. (What? I’m just
                being honest.) Narrowing it down even further, my favorite part
                of the holidays is the stocking stuffers.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 items-center mt-28">
            <div className="">
              <img src={maskProduct4} alt="Chanel Chance Perfumed Hand Creams" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Chanel Chance Perfumed Hand Creams
              </p>
            </div>
            <div>
              <img
                src={maskProduct5}
                alt="Maison Francis Kurkdjian Baccarat Rouge 540 Body Oil"
              />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Maison Francis Kurkdjian Baccarat Rouge 540 Body Oil
              </p>
            </div>
            <div>
              <img src={maskProduct6} alt="New Balance 550 Sneakers" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                New Balance 550 Sneakers
              </p>
            </div>
            <div className="">
              <img src={maskProduct4} alt="Chanel Chance Perfumed Hand Creams" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Chanel Chance Perfumed Hand Creams
              </p>
            </div>
            <div>
              <img
                src={maskProduct5}
                alt="Maison Francis Kurkdjian Baccarat Rouge 540 Body Oil"
              />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Maison Francis Kurkdjian Baccarat Rouge 540 Body Oil
              </p>
            </div>
            <div>
              <img src={maskProduct6} alt="New Balance 550 Sneakers" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                New Balance 550 Sneakers
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center mt-28">
            <div className="">
              <img src={Mask3} alt="Perfect Stocking Stuffers " />
            </div>

            <div className="text-center">
              <h2
                className="font-bold mb-4"
                style={{
                  fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
                }}
              >
                These Are the Must-Have New Balance Styles to Sport This Year
              </h2>
              <p className="text-[18px] text-gray-600">
              If you had asked me five years ago if I would ever say, “The only sneakers you need are the best New Balance shoes,” well, I would have said no. These were the sneakers of my dad (as lovely as he is). And then, slowly but surely—thanks to collaborations with ultra-cool brands like Aimé Leon Dore, Ganni, and others—New Balance became one of the most sought-after sneaker brands.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 items-center mt-28">
            <div className="">
              <img src={maskProduct7} alt="Chanel Chance Perfumed Hand Creams" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Chanel Chance Perfumed Hand Creams
              </p>
            </div>
            <div>
              <img
                src={maskProduct8}
                alt="New Balance Fresh Foam X 1080v13"
              />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                New Balance Fresh Foam X 1080v13
              </p>
            </div>
            <div>
              <img src={maskProduct9} alt="Paravel Cabana Passport Case" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Paravel Cabana Passport Case
              </p>
            </div>
            <div className="">
              <img src={maskProduct7} alt="Chanel Chance Perfumed Hand Creams" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Chanel Chance Perfumed Hand Creams
              </p>
            </div>
            <div>
              <img
                src={maskProduct8}
                alt="New Balance Fresh Foam X 1080v13"
              />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                New Balance Fresh Foam X 1080v13
              </p>
            </div>
            <div>
              <img src={maskProduct9} alt="Paravel Cabana Passport Case" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Paravel Cabana Passport Case
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center mt-28">
            <div className="">
              <img src={Mask4} alt="Perfect Stocking Stuffers " />
            </div>

            <div className="text-center">
              <h2
                className="font-bold mb-4"
                style={{
                  fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
                }}
              >
                The Launch: Fashion News to Know This Month
              </h2>
              <p className="text-[18px] text-gray-600">
              It’s Month, and with that comes the start of  Fashion week and the official countdown to fall. As you start to plan out your transitional wardrobe, browse the latest fashion launches to outfit you (and keep you sartorially informed) through it all. From Louis Vuitton’s latest fine jewelry collection to Rimowa’s new bag release, here’s what you need to know—and shop—in This month.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 items-center mt-28">
            <div className="">
              <img src={maskProduct10} alt="Exclusive collection" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Exclusive collection
              </p>
            </div>
            <div>
              <img
                src={maskProduct11}
                alt="The YSSO Homer Gold-Plated Earrings"
              />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Exclusive footwear collaboration
              </p>
            </div>
            <div>
              <img src={maskProduct12} alt="Limited-edition knitwear collaboration" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Limited-edition knitwear collaboration
              </p>
            </div>
            <div className="">
              <img src={maskProduct10} alt="Exclusive collection" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Exclusive collection
              </p>
            </div>
            <div>
              <img src={maskProduct12} alt="Limited-edition knitwear collaboration" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Limited-edition knitwear collaboration
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 items-center mt-28">
            <div className="">
              <img src={Mask5} alt="Perfect Stocking Stuffers " />
            </div>

            <div className="text-center">
              <h2
                className="font-bold mb-4"
                style={{
                  fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
                }}
              >
                Statement Earrings to Spice Up Your Jewelry Box
              </h2>
              <p className="text-[18px] text-gray-600">
              Tired of the usual suspects when it comes to accessorizing? The best statement earrings are the perfect shock to your sartorial senses, and something tells me that’s just what you need. Be it a bold occasion or a quick trip to the pharmacy, there’s never a wrong time to inject some fun into your looks. 
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 items-center mt-28">
            <div className="">
              <img src={maskProduct13} alt="The YSSO Homer Gold-Plated Earrings" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                The YSSO Homer Gold-Plated Earrings
              </p>
            </div>
            <div>
              <img
                src={maskProduct14}
                alt="Annele Raspberry Jelly Earrings"
              />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Annele Raspberry Jelly Earrings
              </p>
            </div>
            <div>
              <img src={maskProduct15} alt="Sophie Buhai Angelika Silver Jasper Earrings" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Sophie Buhai Angelika Silver Jasper Earrings
              </p>
            </div>
            <div className="">
              <img src={maskProduct13} alt="The YSSO Homer Gold-Plated Earrings" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                The YSSO Homer Gold-Plated Earrings
              </p>
            </div>
            <div>
              <img src={maskProduct14} alt="Annele Raspberry Jelly Earrings" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Annele Raspberry Jelly Earrings
              </p>
            </div>
            <div>
              <img src={maskProduct15} alt="Sophie Buhai Angelika Silver Jasper Earrings" />
              <p
                className="text-center mt-1"
                style={{
                  fontSize: "clamp(1rem, 2vw + 1rem, 1.2rem)",
                }}
              >
                Sophie Buhai Angelika Silver Jasper Earrings
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MillionaireShopping;
