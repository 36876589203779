import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../Pages/Home'
import SuccessStory from '../Pages/Billionaire/Success_story'
import BlogPage from '../Pages/Blog-page'
import Login from '../Pages/Auth/Login'
import Register from '../Pages/Auth/Register'
import Layout from '../Components/Layout'
import ViewIdea from '../Components/View-idea/view-idea'
import BillionaireData from '../Pages/Billionaire/Billionaire-data'
import AboutUs from '../Pages/about-us'
import SingleViewIdea from '../Components/View-idea/single-view-idea'
import BlogAllData from '../Pages/BlogSubPage/blog-all-data'
import PrivacyPolicy from '../Pages/privacy-policy/Privacy-policy'
import TermsConditions from '../Pages/Terms-conditions/Terms-condition'
import ViewFinancialIdea from '../Components/View-idea/view financial-idea'
import SuccessAllStep from '../Components/SuccessSteps/success-all-step'
import NotFound from '../Pages/NotFound'
import Disclaimer from '../Pages/Disclaimer/disclaimer'
import Books from '../Pages/Books/books'
import MillionaireTips from '../Pages/MillionaireTips/Millionaire-tips'
import RealEstate from '../Pages/MillionaireTips/Real-Estate'
import StrategicInvesting from '../Pages/MillionaireTips/Strategic-Investing'
import CelebrityDetails from '../Pages/CelebrityDetails/celebrity-details'
import Celebrity from '../Pages/celebrity'
import CelebrityData from '../Pages/celebrity-data'
import SingleBlogDatails from '../Pages/single-blog-details.js'
import MillionaireShopping from '../Pages/millionaireShopping.js'
import ViewPerfumeData from '../Pages/viewPerfumeData.js'
import LifeStyle from '../Pages/LifeStyle/index.js'
import LifeStyleView from '../Pages/LifeStyle/lifeStyle-view.js'

const AllRoutes = () => {
  return (
    <BrowserRouter>
      <React.Suspense>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path='/millionaire-lifestyle-tips' element={<MillionaireTips />} />
            <Route path='/real-estate-insights' element={<RealEstate />} />
            <Route path='/investment-strategies' element={<StrategicInvesting/>}/>
            <Route path='/millionaires' element={<SuccessStory />} />
            <Route path='/millionaire' element={<BillionaireData />} />
            <Route path='/celebrities' element={<Celebrity/>}/>
            <Route path='/celebrities-data' element={<CelebrityData/>}/>
            <Route path='/celebrities-details' element={<CelebrityDetails />} />
            <Route path='/single-blog' element={<SingleBlogDatails/>}/>
            <Route path='/blogs' element={<BlogPage />} />
            <Route path='/blog' element={<BlogAllData />} />
            <Route path='/success-step' element={<SuccessAllStep />}/>
            <Route path='/life-style' element={<LifeStyle/>}/>
            <Route path='/view-life-style' element={<LifeStyleView/>}/>
            <Route path='/financial-ideas' element={<ViewIdea />} />
            <Route path='/view-financial-idea' element={<ViewFinancialIdea />} />
            <Route path='/about-us' element={<AboutUs />} />
            <Route path='/books' element={<Books />} />
            <Route path='/financial-idea' element={<SingleViewIdea />} />
            <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            <Route path='/terms-conditions' element={<TermsConditions />} />
            <Route path='/disclaimer' element={<Disclaimer />} />
            <Route path='/millionaire-shopping' element={<MillionaireShopping />} />
            <Route path='/view-perfume-data' element={<ViewPerfumeData />} />
            <Route path='*' element={<NotFound />} />
          </Route>

          <Route path='/auth'>
            <Route path='sign-in' element={<Login />} />
            <Route path='sign-up' element={<Register />} />

          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  )
}

export default AllRoutes