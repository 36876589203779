import React from 'react'
import { FaArrowRightLong } from 'react-icons/fa6'

const BlogPost = ({ blogData, loading }) => {
   
    return (
        <div>
            <div className='relative mt-20 my-10'>
                <div className='mx-auto'>
                    <div className='flex justify-center text-center'>
                        <div className=''>
                            <h1 className='text-[#000] text-[40px] font-bold max-[768px]:text-[32px] mb-0'>Recent Blog Post</h1>
                        </div>
                    </div>
                    <div className='blog-div mt-1'>
                        {
                            loading ? <div className='flex justify-center'>
                                <div className="loader mt-5 !w-[50px]"></div>
                            </div> :
                                <div className='grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 md:mt-2 gap-5'>
                                    {
                                        blogData?.map((x, i) => {
                                            return <div className=' mt-4' key={i} onClick={() => {
                                                let clickCount = localStorage.getItem("clickCount") || 0;
                                                let click = Number(clickCount);
                                                localStorage.setItem("clickCount", click + 1);
                                            }}>
                                                <a href={`/blog?blog=${x?.slug}`} className='no-underline'>
                                                    <div className='bg-[#fff] cursor-pointer p-3 rounded-lg border border-[#C8C8C8] h-full' style={{ boxShadow: "0px 3.72px 3.72px 0px #00000040" }}>
                                                        <div className='h-[240px] max-[768px]:h-[250px]'>
                                                            <img src={x?.image} alt={x?.title} className='!h-full w-full object-cover rounded-lg' />
                                                        </div>
                                                        <div className='mt-4 px-2'
                                                            style={{
                                                                maxHeight: "calc(100% - 295px)",
                                                                overflowY: "clip"
                                                            }}>
                                                            <h4 className='font-bold mt-3 text-[22px] max-[1024px]:text-[18px] line-clamp-2 leading-[30px]'>{x?.title}</h4>
                                                            <p className='mt-3 text-[17px] line-clamp-2' dangerouslySetInnerHTML={{ __html: x?.description }}></p>
                                                        </div>
                                                        <div className='flex justify-between items-center mt-4 px-2'>
                                                            <p className='mb-0 mt-2 me-3'>{new Date(x?.created_at).toLocaleDateString('en-US', {
                                                                day: '2-digit',
                                                                month: 'long',
                                                                year: 'numeric'
                                                            })}
                                                            </p>
                                                            <div className='flex justify-center items-center h-12 w-12 rounded-full bg-[#F06939]'>
                                                                <FaArrowRightLong className='text-[#fff]' />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </a>

                                            </div>
                                        })
                                    }
                                </div>
                        }
                        <div className='flex justify-center mt-10'>
                            <a href='/blogs' className='no-underline'>
                                <button className='py-4 px-16 bg-[#F06939] font-semibold text-white rounded-md'>VIEW ALL</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <Modal isOpen={isModalOpen} onClose={handleCloseModal} /> */}
        </div>
    )
}

export default BlogPost