import React from 'react'
import Slider from 'react-slick'

const HappyCus = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
                initialSlide: 2,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ],
    };
    const customerData = [
        {
            image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/wealthman.webp",
            desc: "Alex is always looking for innovative business ideas and investment opportunities to grow his wealth. He's tech-savvy and enjoys networking with other like-minded individuals in the industry.",
            cus_name: "— Alex Wealthman"
        },
        {
            image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/sophia.webp",
            desc: "Sophia is experienced in various asset classes, from stocks to real estate and cryptocurrencies. She's always seeking new opportunities to diversify her investment portfolio and maximize returns.",
            cus_name: "— Sophia Investment"
        },
        {
            image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/clare_gupta.webp",
            desc: "Marcus is a serial entrepreneur who thrives on taking risks and exploring new ventures. He's founded multiple successful startups and is known for his innovative ideas and strategic business acumen",
            cus_name: "— Clare Gupta"
        },
        {
            image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/olivia.webp",
            desc: "Olivia is a high-net-worth individual who enjoys the finer things in life. She's well-traveled, sophisticated, and enjoys indulging in luxury experiences and products. She's always on the lookout for exclusive opportunities and upscale lifestyle offerings.",
            cus_name: "— Olivia Fortune"
        },
        {
            image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Ethan+Prosper.webp",
            desc: "Ethan ambitious, driven, and focused on advancing his career and increasing his earning potential. He's interested in learning about salary negotiation tactics, career development strategies, and financial planning tips for professionals.",
            cus_name: "— Ethan Prosper"
        },
        {
            image: "https://billionaireweb.s3.eu-north-1.amazonaws.com/Isabella.webp",
            desc: "Isabella is a financial advisor with expertise in wealth management and investment planning. She's dedicated to helping her clients achieve their financial goals and secure their financial futures",
            cus_name: "— Isabella Capital"
        }
    ]
    return (
        <div>
            <div className='bg-[#fafafa] py-[80px] max-[1024px]:py-[40px] mt-20 px-5'>
                <div className='container max-w-7xl mx-auto'>
                    <div className='flex justify-center'>
                        <h1 className='text-[40px] font-bold text-[#000] max-[768px]:text-[34px]'>Happy Customers</h1>
                    </div>
                    <div className='mt-4'>
                        <div className='row'>
                            <Slider {...settings} className="p-0 top-position1 banner-style2" style={{ overflow: "hidden" }}>
                                {customerData?.map((a, i) => {
                                    return <div className=''  key={i}>
                                        <div className='md:p-3 p-6'>
                                            <div className='w-[70px] h-[70px]'>
                                            <img src={a?.image} className='h-full w-full rounded-full' alt={a?.cus_name} />
                                            </div>
                                            <p className='mt-3 me-3 italic text-black text-lg leading-8 line-clamp-5'>{a?.desc}
                                            </p>
                                            <p className='italic mt-2 '>{a?.cus_name}</p>
                                        </div>
                                    </div>
                                })}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HappyCus