import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState } from 'react'
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import { toast } from 'react-toastify';
import * as Yup from "yup"
import Base_Url from '../../api/baseUrl';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const onSubmit = async (e) => {
        setLoading(true)
        try {
            const res = await axios.post(`${Base_Url}api/login`, values)
            setLoading(false)
            if (res) {
                localStorage.setItem("user", JSON.stringify(res?.data?.data))
                localStorage.setItem("token", res?.data?.data?.token)
                navigate("/")
                toast("Login Successfully")
            }
        } catch (error) {
            toast.error(error?.response?.data?.message)
            setLoading(false)
        }
    }
    const initialValues = {
        email: "",
        password: ""
    }
    const validationSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email').required('Email is Required'),
        password: Yup.string().required("Password is Required")
    })
    const { values, errors, touched, handleBlur, handleSubmit, handleChange } = useFormik({
        initialValues,
        validationSchema, onSubmit
    })
    return (
        <div className='feedback-main-div'>
            <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-[rgba(0,0,0,.4)]">
                <div className="max-w-md w-full space-y-8 card py-12 px-4 max-[768px]:py-7" style={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    backgroundColor: "rgba(0,0,0,.3)",
                    color: "#fff"
                }}>
                    <div>
                        <h2 className="mt-6 text-center md:text-3xl text-xl font-bold  max-[768px]:mt-2">Sign In To Your Account</h2>
                    </div>
                    <form className="space-y-6 rounded-md" onSubmit={handleSubmit}>
                        <div className="">
                            <div>
                                <input
                                    name="email"
                                    type="text"
                                    className="form-input w-full p-2 border-2 border-[#ccc] rounded-sm bg-transparent"
                                    placeholder="Email Address"
                                    onBlur={handleBlur}
                                    value={values?.email}
                                    onChange={handleChange}
                                />
                                {touched?.email && errors?.email && <h6 className='error text-[#ff0000] mt-2 font-medium ms-1'>{errors?.email}</h6>}
                            </div>
                            <div className='mt-3 relative'>
                                <input
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    className="form-input w-full p-2 border-2 border-[#ccc] rounded-sm bg-transparent"
                                    placeholder="Password"
                                    onBlur={handleBlur}
                                    value={values?.password}
                                    onChange={handleChange}
                                />
                                <p className='absolute cursor-pointer right-[12px] top-[16px]' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />} </p>

                                {touched?.password && errors?.password && <h6 className='error text-[#ff0000] mt-2 font-medium ms-1'>{errors?.password}</h6>}
                            </div>
                        </div>
                        <button
                            type="submit"
                            className="w-full flex justify-center py-3 px-4 border border-transparent text-lg font-semibold rounded-md text-black bg-[#f5f5f5] hover:bg-[#f5f5f5] max-[768px]:py-2"
                        >
                            {loading ? <div className="loader"></div> : "Sign In"}
                        </button>
                        <div className='flex justify-center text-lg'>Are You New?&nbsp; &nbsp; <a href='/auth/sign-up' className='text-[#fff] underline cursor-pointer font-semibold' > Sign Up Now</a> </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Login