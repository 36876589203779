import React from 'react'
import { HiLightBulb } from 'react-icons/hi'
import { RiMoneyDollarBoxFill } from 'react-icons/ri' 
import { PiChartBarFill } from 'react-icons/pi'

const MillionaireLifeStyle = () => {
  return (
    <div className='flex justify-center px-5 mb-5'>
      <div className='container max-w-7xl'>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-[-50px]">
          <a href='/millionaire-lifestyle-tips' className="bg-white border flex items-center border-gray-300 rounded-lg px-4 lg:px-6 lg:py-8 py-4 shadow-lg md:mx-2 mt-3 lg:mt-0 cursor-pointer no-underline">
            <div className="text-3xl lg:p-4 p-3 bg-[#F06939] rounded-full mb-4"><HiLightBulb className='text-white' /></div>
            <div className='ms-4'>
              <h3 className="lg:text-[21px] md:text-[20px] text-[18px] font-bold mb-1">Millionaire Lifestyle Tips</h3>
              <p className="lg:text-[18px] text-[16px] text-[#6c757d] font-medium">Articles And Guides On How Millionaires Live, Including Their Daily Routines, Habits</p>
            </div>
          </a>
          <a href='/real-estate-insights' className="bg-white border flex items-center border-gray-300 rounded-lg px-4 lg:px-6 lg:py-8 py-4 shadow-lg  md:mx-2 mt-3 lg:mt-0 cursor-pointer no-underline">
            <div className="text-3xl lg:p-4 p-3 bg-[#F06939] rounded-full mb-4"><RiMoneyDollarBoxFill className='text-white' /></div>
            <div className='ms-4'>
              <h3 className="lg:text-[21px] md:text-[20px] text-[18px] font-bold mb-1">Real Estate Insights</h3>
              <p className="lg:text-[18px] text-[16px] text-[#6c757d] font-medium">Explore key trends and strategies for smart real estate investment decisions.</p>
            </div>
          </a>
          <a href='/investment-strategies' className="bg-white border flex items-center border-gray-300 rounded-lg px-4 lg:px-6 lg:py-8 py-4 shadow-lg  md:mx-2 mt-3 lg:mt-0 cursor-pointer no-underline">
            <div className="text-3xl lg:p-4 p-3 bg-[#F06939] rounded-full mb-4"><PiChartBarFill className='text-white' /></div>
            <div className='ms-4'>
              <h3 className="lg:text-[21px] md:text-[20px] text-[18px] font-bold mb-1">Investment Strategies</h3>
              <p className="lg:text-[18px] text-[16px] text-[#6c757d] font-medium">Interviews reveal strategies from financial advisors and successful entrepreneurs alike.</p>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default MillionaireLifeStyle