import React, { useState } from 'react'
import { useFormik } from 'formik';
import * as Yup from "yup"
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import axios from 'axios';
import { toast } from 'react-toastify';
import Base_Url from '../../api/baseUrl';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const initialValues = {
        first_name: "",
        last_name: "",
        email: "",
        mobile: "",
        password: ""
    }
    const onSubmit = async (e) => {
        try {
            setLoading(true)
            const res = await axios.post(`${Base_Url}api/register`, values)
            setLoading(false)
            if (res) {
                navigate("/auth/sign-in")
                toast("Register Successfully")
            }
        } catch (error) {
            setLoading(false)
            toast(error?.response?.data?.message)
        }
    }
    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required("First Name is Required"),
        last_name: Yup.string().required("Last Name is Required"),
        mobile: Yup.string().matches(/^\d{10}$/, "Mobile number must be exactly 10 digits")
            .required("Phone number is required"),
        email: Yup.string().email('Invalid email').required('Email is Required'),
        password: Yup.string().required("Password is Required")
    })
    const { values, errors, touched, handleBlur, handleSubmit, handleChange } = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })
    return (
        <div className='feedback-main-div'>
            <div className="min-h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 bg-[rgba(0,0,0,.4)] max-[768px]:py-7">
                <div className="max-w-md w-full space-y-8 card p-4" style={{
                    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", backgroundColor: "rgba(0,0,0,.3)",
                    color: "#fff"
                }}>
                    <div>
                        <h2 className="mt-6 text-center md:text-3xl text-xl font-bold  max-[768px]:mt-2">Sign Up To Your Account</h2>
                    </div>
                    <form className="space-y-6 rounded-md " onSubmit={handleSubmit}>
                        <div className="">
                            <div>
                                <input
                                    name="first_name"
                                    type="text"
                                    className="form-input w-full p-2 border-2 border-[#ccc] rounded-sm bg-transparent"
                                    placeholder="First Name"
                                    onBlur={handleBlur}
                                    value={values?.first_name}
                                    onChange={handleChange}
                                />
                                {touched?.first_name && errors?.first_name && <h6 className='error text-[#ff0000] mt-1 font-medium ms-1'>{errors?.first_name}</h6>}
                            </div>
                            <div className='mt-3'>
                                <input
                                    name="last_name"
                                    type="text"
                                    className="form-input w-full p-2 border-2 border-[#ccc] rounded-sm bg-transparent"
                                    placeholder="Last Name"
                                    onBlur={handleBlur}
                                    value={values?.last_name}
                                    onChange={handleChange}
                                />
                                {touched?.last_name && errors?.last_name && <h6 className='error text-[#ff0000] mt-1 font-medium ms-1'>{errors?.last_name}</h6>}
                            </div>
                            <div className='mt-3'>
                                <input
                                    name="email"
                                    type="email"
                                    className="form-input w-full p-2 border-2 border-[#ccc] rounded-sm bg-transparent"
                                    placeholder="Email Address"
                                    onBlur={handleBlur}
                                    value={values?.email}
                                    onChange={handleChange}
                                />
                                {touched?.email && errors?.email && <h6 className='error text-[#ff0000] mt-1 font-medium ms-1'>{errors?.email}</h6>}
                            </div>
                            <div className='mt-3'>
                                <input
                                    name="mobile"
                                    type="text"
                                    className="form-input w-full p-2 border-2 border-[#ccc] rounded-sm bg-transparent"
                                    placeholder="Mobile Number"
                                    maxLength={10}
                                    onBlur={handleBlur}
                                    value={(values?.mobile).toString()}
                                    onChange={handleChange}
                                />
                                {touched?.mobile && errors?.mobile && <h6 className='error text-[#ff0000] mt-1 font-medium ms-1'>{errors?.mobile}</h6>}
                            </div>
                            <div className='mt-3 relative'>
                                <input
                                    name="password"
                                    type={showPassword ? "text" : "password"}
                                    className="form-input w-full p-2 border-2 border-[#ccc] rounded-sm bg-transparent"
                                    placeholder="Password"
                                    onBlur={handleBlur}
                                    value={values?.password}
                                    onChange={handleChange}
                                />
                                <p className='absolute right-[12px] top-[16px] cursor-pointer' onClick={() => setShowPassword(!showPassword)}>{showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />} </p>
                                {touched?.password && errors?.password && <h6 className='error text-[#ff0000] mt-1 font-medium ms-1'>{errors?.password}</h6>}

                            </div>
                        </div>
                        <div>
                            <button
                                type="submit"
                                className="w-full flex justify-center py-3 px-4 border border-transparent text-lg font-semibold rounded-md text-black bg-[#f5f5f5] hover:bg-[#f5f5f5]"
                            >
                                {loading ? <div className="loader"></div> : "Sign Up"}
                            </button>
                        </div>
                        <div className='flex justify-center text-lg max-[768px]:flex-col max-[768px]:text-center'>Already Have An Account? <a href='/auth/sign-in' className='underline cursor-pointer font-semibold text-[#fff]'> &nbsp;Sign In Now</a> </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Register