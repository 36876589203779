import React, { useEffect, useState } from "react";

const AdResponsive01 = () => {
  useEffect(() => {
    const loadGoogleAdsScript = () => {
      const script = document.createElement("script");
      script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      script.async = true;
      script.onload = initializeGoogleAds; // Call initializeGoogleAds after script is loaded
      document.head.appendChild(script);
    };

    const initializeGoogleAds = () => {
      if (window.googletag && typeof window.googletag === "object") {
        window.googletag.cmd.push(() => {
          // Define ad slots and display ads here
          let adSlot = window.googletag.defineSlot(
            "/23055822891/millionair_res1",
            [
              [200, 200],
              [250, 360],
              [200, 446],
              [300, 250],
              [250, 250],
              [336, 280],
            ],
            "div-gpt-ad-1721020436899-0"
          );
          adSlot.addService(window.googletag.pubads());

          window.googletag.enableServices();
          window.googletag.display("div-gpt-ad-1721020436899-0");
        });
      } else {
        console.error(
          "googletag is not defined. Google Ad Manager library might not be loaded."
        );
      }
    };

    loadGoogleAdsScript(); // Call function to load GAM script
  }, []);

  return (
    <>
      <p style={{ textAlign: "center", fontSize: "10px" }}>ADVERTISEMENT</p>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: "2px",
        }}
      >
        <div
          id="div-gpt-ad-1721020436899-0"
          style={{ minWidth: "200px", minHeight: "200px", backgroundColor: "#E9EAEC" }}
        ></div>
      </div>
      <p style={{ textAlign: "center", fontSize: "10px" }}>ADVERTISEMENT</p>
    </>
  );
};

export default AdResponsive01;
