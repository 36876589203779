import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { saveAs } from 'file-saver';
import Base_Url from '../../api/baseUrl';
import axios from 'axios';
import BackToTop from '../BackToTop/backToTop';

const SingleViewIdea = () => {
    const location = useLocation();
    const { slug } = useParams();
    const querySlug = new URLSearchParams(location.search).get('idea');
    const [singleData, setsingleData] = useState({})
    const [singleIdea, setSingleIdea] = useState(null)
    const [loading, setLoading] = useState(false)
    const [notFound, setNotFound] = useState(false)
    const handleDownload = async () => {
        const pdfBlob = await fetch(`${Base_Url}images/` + singleData?.files).then((res) => res.blob());
        saveAs(pdfBlob, 'document.pdf');
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [])
    useEffect(() => {
        const fetchSlug = slug || querySlug;
        setSingleIdea(fetchSlug)
        if (!querySlug) {
            window.location.reload('/financial-ideas')
        }
    }, [slug, querySlug]);
    useEffect(() => {
        if (singleIdea) {
            const getOneIdea = async () => {
                setLoading(true);
                try {
                    const res = await axios.get(`${Base_Url}api/idea/${singleIdea}`);
                    setLoading(false);
                    if (res) {
                        setsingleData(res?.data?.data);
                    }
                } catch (error) {
                    setLoading(false);
                    setNotFound(true);
                }
            };
    
            getOneIdea();
        }
    }, [singleIdea]);
    return (
        <div className=''>
            {
                !notFound ?
                    <>
                        {loading ? <div className='flex justify-center'>
                            <div className="loader my-5 !w-[40px]"></div>
                        </div> :
                            <div className="container max-w-7xl mx-auto md:px-0 px-4">
                                <div className='mt-[45px] max-[768px]:mt-7'>
                                    <div className='flex justify-center mb-6'>
                                        <h1 className='flex justify-center text-[30px] text-[#000] font-semibold w-[80%] text-center max-[1024px]:w-full max-[1024px]:text-[30px]'>{singleData?.topic}</h1>
                                    </div>
                                    <div className={`justify-center text-center items-center grid ${singleData?.image_url?.length === 1 ? 'grid-cols-1' :
                                            singleData?.image_url?.length === 2 ? 'grid-cols-2' : 'lg:grid-cols-3 md:grid-cols-2 grid-cols-1'} md:gap-5 gap-0`}>
                                        {singleData?.image_url?.map((x) => {
                                            return (
                                                <div className='p-3 flex justify-center'>
                                                    <img src={x} className='h-[220px]' alt='' />
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className='flex justify-center mt-5'>
                                        <div className=' max-[1024px]:px-4'>
                                            <p className='text-justify md:text-[18px] text-[17px]'>{singleData?.description}</p>
                                            {singleData?.files &&
                                                <div className='flex justify-center'>
                                                    <img src="https://billionaireweb.s3.eu-north-1.amazonaws.com/file+(5).webp" onClick={handleDownload} className='h-[130px]' alt='download' />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        }  </> :
                    <div className='h-[80vh] flex justify-center flex-col text-center max-[1024px]:h-[67vh]'>
                        <h1 className='text-[120px] text-black max-[768px]:text-[94px] max-[768px]:mb-0'>404</h1>

                        <h2 className='text-4xl mb-1 text-black font-semibold max-[768px]:text-[25px]'>Ooops... No Financial Idea found!</h2>
                        <div className='flex justify-center mt-3'>
                            <a href='/' className='no-underline'>
                                <button className='text-xl underline font-medium bg-[#F06939] text-white w-[220px] rounded-md h-[60px] mt-2'>Go Back</button>
                            </a>
                        </div>
                    </div>
            }
            <BackToTop/>
        </div>
    )
}

export default SingleViewIdea