/*global googletag*/

import { useEffect } from "react";

const GoogleAdsScriptCard = () => {
  useEffect(() => {
    const loadGoogleAdsScript = () => {
      const script = document.createElement("script");
      script.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";
      script.async = true;
      script.onload = () => {
        initializeGoogleAds();
      };
      document.head.appendChild(script);
    };

    const initializeGoogleAds = () => {
      if (window.googletag && typeof window.googletag === "object") {
        window.googletag.cmd.push(() => {
          // Your Google Ads initialization code goes here
          let interstitialSlot;
          let rewardedSlot;
          let rewardPayload;

          const loadRewardedAd = () => {
            rewardedSlot = googletag.defineOutOfPageSlot(
              "/23055822891/millionair_res",
              googletag.enums.OutOfPageFormat.REWARDED
            );

            if (rewardedSlot) {
              rewardedSlot.addService(googletag.pubads());

              googletag.pubads().addEventListener("rewardedSlotReady", (event) => {
                event.makeRewardedVisible();
              });

              googletag.pubads().addEventListener("rewardedSlotClosed", () => {
                if (rewardPayload) {
                  rewardPayload = null;
                } else {
                  console.log("Rewarded ad was closed without granting a reward.");
                }

                if (rewardedSlot) {
                  googletag.destroySlots([rewardedSlot]);
                }
              });

              googletag.pubads().addEventListener("rewardedSlotGranted", (event) => {
                rewardPayload = event.payload;
                console.log("Reward granted.");
              });

              googletag.pubads().addEventListener("slotRenderEnded", (event) => {
                if (event.slot === rewardedSlot && event.isEmpty) {
                  console.log("No ad returned for rewarded ad slot.");
                }
              });

              googletag.enableServices();
              googletag.display(rewardedSlot);
            } else {
              console.log("Rewarded ads are not supported on this page.");
            }
          };

          interstitialSlot = googletag.defineOutOfPageSlot(
            "/23055822891/mill_inter",
            googletag.enums.OutOfPageFormat.INTERSTITIAL
          );

          if (interstitialSlot) {
            interstitialSlot.addService(googletag.pubads()).setConfig({
              interstitial: {
                triggers: {
                  navBar: true,
                  unhideWindow: true,
                },
              },
            });

            console.log("Interstitial is loading...");

            googletag.pubads().addEventListener("slotOnload", (event) => {
              if (interstitialSlot === event.slot) {
                console.log("Interstitial is loaded.");
                googletag.pubads().refresh([interstitialSlot]);
              }
            });

            googletag.pubads().addEventListener("slotRenderEnded", (event) => {
              if (event.slot === interstitialSlot && event.isEmpty) {
                console.log("No ad returned for interstitial ad slot.");
                loadRewardedAd();
              }
            });

            googletag.pubads().addEventListener("slotVisibilityChanged", (event) => {
              if (event.slot === interstitialSlot && event.inViewPercentage === 0) {
                console.log("Interstitial ad failed to display.");
                loadRewardedAd();
              }
            });

            googletag.enableServices();
            googletag.display(interstitialSlot);
          } else {
            console.log("Interstitial ads are not supported on this page.");
            loadRewardedAd();
          }
        });
      } else {
        console.error("googletag is not defined. Google Ad Manager library might not be loaded.");
      }
    };

    loadGoogleAdsScript();
  }, []);

  return null;
};

export default GoogleAdsScriptCard;
