import { ToastContainer } from "react-toastify";
import "./App.css";
import AllRoutes from "./routes/AllRoutes";
import { useEffect } from "react";

function App() {
  // const reloadAds = () => {
  //   if (window.googletag && window.googletag.cmd) {
  //     window.googletag.cmd.push(function () {
  //       window.googletag.display("div-gpt-ad-1721020421262-0");
  //       window.googletag.display("div-gpt-ad-1721020424421-0");
  //       window.googletag.display("div-gpt-ad-1721020427956-0");
  //       window.googletag.display("div-gpt-ad-1721020430525-0");
  //       window.googletag.display("div-gpt-ad-1721020433830-0");
  //       window.googletag.display("div-gpt-ad-1721020436899-0");
  //       window.googletag.display("div-gpt-ad-1721020439738-0");
  //       window.googletag.display("div-gpt-ad-1721020442767-0");
  //       window.googletag.display("div-gpt-ad-1722943946813-0");
  //       window.googletag.display("div-gpt-ad-1722943950051-0");
  //       window.googletag.display("div-gpt-ad-1722943951488-0");
  //       window.googletag.display("div-gpt-ad-1722943952843-0");
  //       window.googletag.display("div-gpt-ad-1722943954567-0");
  //     });
  //   }
  //   if (window.googletag && window.googletag.pubads) {
  //     try {
  //       window.googletag.pubads().refresh();
  //     } catch (error) {
  //       console.error("Failed to refresh ads:", error);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   reloadAds();

  //   const interval = setInterval(reloadAds, 30000);
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  useEffect(() => {
    // The provided code snippet
    (function(_window, _document) {
      var OB_ADV_ID = '00d929906df2c35176b6485002b4ebd144';
      if (_window.obApi) {
        var toArray = function(object) {
          return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
        };
        _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
        return;
      }
      var api = _window.obApi = function() {
        api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
      };
      api.version = '1.1';
      api.loaded = true;
      api.marketerId = OB_ADV_ID;
      api.queue = [];
      var tag = _document.createElement('script');
      tag.async = true;
      tag.src = '//amplify.outbrain.com/cp/obtp.js';
      tag.type = 'text/javascript';
      var script = _document.getElementsByTagName('script')[0];
      script.parentNode.insertBefore(tag, script);
    })(window, document);

    // Use window.obApi to avoid undefined reference
    if (window.obApi) {
      window.obApi('track', 'PAGE_VIEW');
      window.obApi('track', 'PV5');
    }

    (function (_window, _document) {
      var OB_ADV_ID = '00d929906df2c35176b6485002b4ebd144';
      if (_window.obApi) {
        var toArray = function (object) {
          return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
        };
        _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
        return;
      }
      var api = _window.obApi = function () {
        api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
      };
      api.version = '1.1';
      api.loaded = true;
      api.marketerId = OB_ADV_ID;
      api.queue = [];
      var tag = _document.createElement('script');
      tag.async = true;
      tag.src = '//amplify.outbrain.com/cp/obtp.js';
      tag.type = 'text/javascript';
      var script = _document.getElementsByTagName('script')[0];
      script.parentNode.insertBefore(tag, script);
    })(window, document);

    // Call to track the event
    if (window.obApi) {
      window.obApi('track', 'PV5');
    }
  }, []);

  return (
    <div className="App">
      <AllRoutes />
      <ToastContainer />
    </div>
  );
}

export default App;
