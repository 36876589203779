import React, { useEffect } from 'react'
import { FaTimes } from 'react-icons/fa';

const LinkModel = ({ isOpen, onClose }) => {
    useEffect(() => {
        document.body.style.overflow = isOpen ? 'hidden' : 'auto';
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [isOpen]);
    if (!isOpen) return null;
    return (
        <div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white md:p-8 p-4 rounded-lg w-full max-w-xl relative max-h-[95vh] overflow-y-auto m-2">
                    <button
                        className="absolute md:top-7 top-5 right-5 text-gray-600 hover:text-gray-900"
                        onClick={onClose}
                    >
                        <FaTimes className="w-6 h-6" />
                    </button>
                   <h2 className="text-2xl font-semibold mb-4 text-[#000] flex justify-center">Explore Insights</h2>
                    <div className='mt-3'>
                        <p className='md:text-[17px] text-[15px] mb-4'>We have carefully compiled data from a variety of trusted sources to provide you with comprehensive and accurate insights. For those interested in detailed information on specific indices or net worth, we recommend visiting Bloomberg's website. As a leading authority in financial data, Bloomberg offers in-depth analysis and real-time updates on market trends, investments, and financial performance.</p>
                        <p className='md:text-[17px] text-[15px] mb-4'>
                        Consulting Bloomberg will allow you to verify the information, cross-check details, and stay informed about the latest financial developments. Whether you are conducting research, making investment decisions, or seeking to stay updated, Bloomberg’s resources will enhance your understanding and provide you with the clarity you need.</p>
                    </div>
                    <div className='flex justify-center gap-7'>
                        <button className='px-7 py-2 bg-[#F06939]  w-1/2 text-[#fff] rounded-sm font-semibold border-2 border-[#F06939] cursor-pointer' onClick={() => window.open("https://www.bloomberg.com/billionaires/")}>Yes</button>
                        <button className='px-7 py-2 w-1/2 text-[#F06939] border-2 border-[#F06939] rounded-sm font-semibold cursor-pointer' onClick={onClose}>No</button>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default LinkModel