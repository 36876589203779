import React, { useEffect, useState } from 'react'
import axios from "axios"
import ReactPaginate from 'react-paginate'
import Base_Url from '../../api/baseUrl'
import BackToTop from '../BackToTop/backToTop'
import IdeaForm from '../Idea-form/idea-form'

const ViewIdea = () => {
    const [viewData, setViewData] = useState([])
    const [page, setPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [loading, setLoading] = useState(false)
    const limit = 3;
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [isModelOpen, setIsModelOpen] = useState(false)

    const updateScreenSize = () => {
        setIsSmallScreen(window.innerWidth < 640);
    };

    useEffect(() => {
        updateScreenSize();
        window.addEventListener("resize", updateScreenSize);

        return () => window.removeEventListener("resize", updateScreenSize);
    }, []);
    useEffect(() => {
        const ideaView = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${Base_Url}api/getidea?limit=${limit}&page=${page + 1}`);
                setLoading(false);
                setViewData(response?.data?.data);
                setPageCount(response?.data?.totalPage);
            } catch (error) {
                setLoading(false);
                console.error("Failed to fetch ideas:", error);
            }
        };
        ideaView();
    }, [page, limit]);


    return (
        <div className='sm:px-5 px-3'>
            <div className='container max-w-7xl mx-auto '>
                <h1 className='flex justify-center mt-2 text-[#000] my-4 pt-5 text-[40px] font-bold max-[768px]:text-[30px] text-center'>View All Financial Idea</h1>
                <div className='flex justify-end'>
                    <button className='py-3 px-8 font-semibold bg-[#F06939] text-[#fff] text-[17px] rounded-md' onClick={() => setIsModelOpen(true)}>Share Your Idea</button>
                </div>
                {/* <AdResponsive /> */}
                <card>
                    {
                        loading ? <div className='flex justify-center'>
                            <div className="loader my-5 !w-[40px]"></div>
                        </div> :
                            <>
                                {viewData && viewData?.map((x, i) => {
                                    return <div className="border border-gray-400 rounded-md mt-3" key={i}>
                                        <a href={`/financial-idea?idea=${x?.id}`} className='no-underline'>

                                            <div className='grid lg:grid-cols-5 md:grid-cols-3 grid-cols-1 md:gap-5 lg:gap-7'>
                                                <div className='lg:col-span-2 col-span-1'>
                                                    <div className='image-div !pe-0 sm:!pe-3 max-[1024px]:flex max-[1024px]:justify-center lg:h-[300px] h-[200px]'>
                                                        <img src={x?.image_url[0]} className='h-full w-full' alt='' />
                                                    </div>
                                                </div>
                                                <div className='lg:col-span-3 col-span-2 py-4 pe-5 max-[768px]:p-2'>
                                                    <h1 className='text-[#000] md:text-[28px] text-[20px] font-semibold'> {x?.topic}</h1>
                                                    <p className='max-[768px]:text-justify text-[16px] lg:line-clamp-5 mt-3 lg:text-[18px] md:line-clamp-4 line-clamp-3'>  {x?.description
                                                    }</p>
                                                    <div className='flex justify-end max-[900px]:justify-center'>
                                                        <button className='bg-[#F06939] text-white py-2 mt-6 px-5 rounded-md font-semibold'>View All Detail</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                })
                                }
                            </>
                    }
                </card>
                {!loading && viewData.length > 0 &&
                    <div className='flex justify-center mt-4 my-3 max-[768px]:mt-0'>
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageCount}
                            marginPagesDisplayed={isSmallScreen ? 1 : 2}
                            pageRangeDisplayed={isSmallScreen ? 1 : 3}
                            onPageChange={(e) => {
                                setPage(e.selected)
                                window.scrollTo({ top: 0, behavior: 'smooth' });
                            }}
                            containerClassName={'pagination'}
                            subContainerClassName={'pages pagination'}
                            activeClassName={'active'}
                            forcePage={page}

                        />
                    </div>
                }
            </div>
            <BackToTop />
            {
                isModelOpen &&
                <IdeaForm isOpen={isModelOpen} onClose={() => setIsModelOpen(false)} />
            }
        </div>
    )
}

export default ViewIdea