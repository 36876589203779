import React, { useEffect, useState } from "react";
import BackToTop from "../Components/BackToTop/backToTop";
import AdResponsive06 from "../Components/ads/AdResponsive06";
import CelebrityEvents from "../Components/CelebrityEvents/CelebrityEvents";
import LongAdCard from "../Components/ads/LongAdCard";
import LongAdCard02 from "../Components/ads/LongAdCard02";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";
import Base_Url from "../api/baseUrl";
import axios from "axios";

const Celebrity = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [celebrityData, setCelebrityData] = useState();
  const [celebrity, setcelebrity] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchCelebrityData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_Url}api/get-category`);
        setLoading(false);
        setCelebrityData(response?.data?.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog data:", error);
      }
    };
    fetchCelebrityData();
  }, []);

  useEffect(() => {
    const fetchOtherCelebrityData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${Base_Url}api/get-celebrity?page=1&limit=50`
        );
        setLoading(false);
        setcelebrity(response?.data?.data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog data:", error);
      }
    };
    fetchOtherCelebrityData();
  }, []);

  return (
    <>
      <LeftSideAd />
      <RightSideAd />
      {loading ? (
        <div className="flex justify-center">
          <div className="loader my-5 !w-[40px]"></div>
        </div>
      ) : (
        <div className="px-4">
          <div className="container max-w-7xl mx-auto pt-[2rem] lg:px-0">
            <CelebrityEvents celebrityData={celebrityData} />
            <div className="my-10">
              <AdResponsive06 />
            </div>
            <div>
              <div className="">
                <h3
                  className="text-center md:text-[42px] text-[35px] font-semibold  border-b border-gray-500"
                  style={{ fontFamily: "math" }}
                >
                  Celebrity Insider
                </h3>
              </div>
              <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 mt-3 grid-cols-1 gap-4">
                {celebrity?.map((data, index) => (
                  <React.Fragment key={index}>
                    <div className="cursor-pointer md:mt-4">
                      <a href={`/celebrities-data?id=${data?.id}`}>
                        <div className="w-full h-[300px]">
                          <img
                            src={data?.image}
                            alt=""
                            className="w-full h-full"
                          />
                        </div>
                        <h3 className="pt-2 text-[17px] font-semibold line-clamp-2">
                          {data?.title}
                        </h3>
                      </a>
                    </div>
                    {index === 3 && (
                      <div className="lg:col-span-4 md:col-span-3 sm:col-span-2 col-span-1 mt-4">
                        <LongAdCard />
                      </div>
                    )}
                    {index === 7 && (
                      <div className="lg:col-span-4 md:col-span-3 sm:col-span-2 col-span-1 mt-4">
                        <LongAdCard02 />
                      </div>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <BackToTop />
        </div>
      )}
    </>
  );
};

export default Celebrity;
