// App.js
import React from "react";
import layer from "../assests/Layer_1.png";
import mainPerfume from "../assests/perfume_main.png";
import perfume from "../assests/Group 451.png";
import perfumeData from "../assests/1.png";

const ViewPerfumeData = () => {
  return (
    <div className="container mx-auto px-4">
      <header className="text-center py-8">
        <h1
          className="font-bold"
          style={{
            fontSize: "clamp(1.5rem, 2vw + 1rem, 2.5rem)",
          }}
        >
          Sophisticated Vanilla Perfumes That Won't Make <br /> You Smell Like A
          Bakery
        </h1>
        <div className="mt-2 flex justify-center mx-auto">
          <img src={layer} alt="" />
        </div>
      </header>

      <section className="text-center py-4">
        <img src={mainPerfume} alt="" />
        <p
          className="mt-4 max-w-[80%] mx-auto text-[#252525]"
          style={{
            fontSize: "clamp(1.1rem, 2vw + 1rem, 1rem)",
          }}
        >
          Now that the weather is starting to cool down, it’s time to
          swap summer scents for fall perfumes. When it comes to picking a fall
          perfume, we have one word on our minds: cozy. Wearing a cozy scent
          makes you feel like you’re wrapped up in a cashmere blanket or wearing
          your favorite warm sweater next to a crackling fireplace. One of the
          coziest scent notes you can choose? Vanilla.
          <br />
          <br />
          Don’t worry—a vanilla scent doesn’t have to be cloyingly sweet, like
          the vanilla-tinged fragrances of our youth. In fact, many new
          generation vanillas are smoky, woodsy, or even fresh. Vanilla can add
          a creamy depth to fragrances that makes them much more wearable. Our
          favorite vanilla scents are sexy, easy to wear, and the source of
          endless compliments. Keep reading for the vanilla perfumes that ELLE
          editors and rave reviewers love to wear.
        </p>
      </section>

      <div className="flex flex-col md:flex-row items-start space-y-8 md:space-y-0 md:space-x-8 mt-10">
        <div className="flex flex-col space-y-8">
          <div className="flex flex-col md:flex-row items-start space-x-6">
            <img src={perfume} alt="Bond No. 9 New York Forever" />
            <div className="space-y-4 md:text-start md:mt-0 text-center mt-4">
              <h2 className="text-2xl font-semibold">
                Bond No. 9 New York Forever
              </h2>
              <p
                className=" text-[#252525]"
                style={{
                  fontSize: "clamp(1.1rem, 2vw + 0.5rem, 1rem)",
                }}
              >
                New York City Is A Melange Of A Lot Of Pleasant And Unpleasant
                Smells. However, In Bond No. 9's World, The City Is A Golden
                Medley Of Dewy Florals And Vanilla. Key Notes: Cinnamon Bark,
                Patchouli, Vanilla, Amber, Labdanum, Moss Size: 3.3 Fl Oz
              </p>
              <p
                style={{
                  fontSize: "clamp(1.1rem, 2vw + 1rem, 1rem)",
                }}
              >
                Bond No. 9 Rating: <span className="font-bold">5/5 Stars</span>
              </p>
              <p
                className=" italic text-[#252525]"
                style={{
                  fontSize: "clamp(1.1rem, 2vw + 1rem, 1rem)",
                }}
              >
                An ELLE Editor Says: “Fragrance Is My Favorite Form Of Escapism.
                Bond No. 9 Does A Great Job Of Giving Every Neck Of New York
                City Its Own Distinct Smell. New York Forever Bursts With
                Honeyed Florals And A Sweet Vanilla Dip That Makes You Forget
                About The City’s Other Scents.”
              </p>
            </div>
          </div>

          <div className="flex flex-col md:flex-row items-start space-x-6">
            <img src={perfume} alt="Bond No. 9 New York Forever" />
            <div className="space-y-4">
              <h2 className="text-2xl font-semibold">
                Bond No. 9 New York Forever
              </h2>
              <p
                className=" text-[#252525]"
                style={{
                  fontSize: "clamp(1.1rem, 2vw + 1rem, 1rem)",
                }}
              >
                New York City Is A Melange Of A Lot Of Pleasant And Unpleasant
                Smells. However, In Bond No. 9's World, The City Is A Golden
                Medley Of Dewy Florals And Vanilla. Key Notes: Cinnamon Bark,
                Patchouli, Vanilla, Amber, Labdanum, Moss Size: 3.3 Fl Oz
              </p>
              <p
                style={{
                  fontSize: "clamp(1.1rem, 2vw + 1rem, 1rem)",
                }}
              >
                Bond No. 9 Rating: <span className="font-bold">5/5 Stars</span>
              </p>
              <p
                className=" italic text-[#252525]"
                style={{
                  fontSize: "clamp(1.1rem, 2vw + 1rem, 1rem)",
                }}
              >
                An ELLE Editor Says: “Fragrance Is My Favorite Form Of Escapism.
                Bond No. 9 Does A Great Job Of Giving Every Neck Of New York
                City Its Own Distinct Smell. New York Forever Bursts With
                Honeyed Florals And A Sweet Vanilla Dip That Makes You Forget
                About The City’s Other Scents.”
              </p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <h3
          className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
          style={{
            fontFamily: "Playfair Display",
            fontSize: "clamp(2rem, 4vw, 3rem)",
          }}
        >
          Perfumes & Fragrances
        </h3>
      </div>
      <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-20 mb-12">
              {Array(8)
                .fill()
                .map((data, index) => {
                  return (
                    <div className="">
                      <div className="h-auto mx-auto lg:w-[calc(100%-50px)] w-full">
                        <img src={perfumeData} alt="" className="h-full w-full" />
                      </div>
                      <p className="mx-auto font-medium text-[17px] lg:w-[calc(100%-50px)] w-full mt-2">
                      Taylor Swift's Top Perfume Unveiled at the 2024 VMAs!
                      </p>
                    </div>
                  );
                })}
            </div>
    </div>
  );
};

export default ViewPerfumeData;
