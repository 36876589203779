import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Base_Url from '../../api/baseUrl';
import AdResponsive03 from '../../Components/ads/AdResponsive03';
import GoogleAdsScript from '../../Components/ads/GoogleAdsScript';
import BackToTop from '../../Components/BackToTop/backToTop';
import SubBlog from '../../Components/HomeSubPage/Sub-blog';

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
};
const BlogAllData = () => {
    const page = 0;
    const limit = 100;
    const location = useLocation()
    const [notFound, setNotFound] = useState(false)
    const [loading, setLoading] = useState(false)
    const [blog, setBlog] = useState({});
    const [singleBlog, setSingleBlog] = useState(null);
    const [adDisplayed, setAdDisplayed] = useState(false);
    const [remainingItems, setRemainingItems] = useState([])

    const { slug } = useParams();
    const querySlug = new URLSearchParams(location.search).get('blog');
    useEffect(() => {
        const fetchSlug = slug || querySlug;
        setSingleBlog(fetchSlug)
        if (!querySlug) {
            window.location.reload('/blogs')
        }
    }, [slug, querySlug]);
    useEffect(() => {
        const getOneBlog = async () => {
            setLoading(true);
            try {
                const res = await axios.get(`${Base_Url}api/blog/${singleBlog}`);
                setLoading(false);

                if (res) {
                    setBlog(res?.data?.data);
                    localStorage.setItem('returningFromBlog', 'true');
                }
            } catch (error) {
                setLoading(false);
                setNotFound(true);
            }
        };
        if (singleBlog) {
            getOneBlog();
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });

        let clickCount = localStorage.getItem("clickCount") || "0";
        let click = Number(clickCount);
        if (click === 3) {
            setTimeout(() => {
                setAdDisplayed(true);
            }, 1000);
        }

        if (click >= 3) {
            localStorage.setItem("clickCount", "0");
        }
    }, [singleBlog]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        const allBlogData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${Base_Url}api/get-blog?limit=${limit}&page=${page + 1}`)
                setLoading(false)
                if (response) {
                    const filteredBlogs = response?.data?.data?.filter(blog => blog?.slug !== singleBlog);
                    const shuffledBlogs = shuffleArray(filteredBlogs)?.slice(0, 10);
                    setRemainingItems(shuffledBlogs);
                }
            } catch (error) {
                setLoading(false);
                console.error("Error fetching blog data:", error);
            }
        };
        allBlogData();
        ;
    }, [singleBlog])


    return (
        <div className='container max-w-[1500px] mx-auto px-4'>
            {adDisplayed && <GoogleAdsScript />}
            {
                !notFound ?
                    <>
                        {loading ? <div className='flex justify-center'>
                            <div className="loader my-5 !w-[40px]"></div>
                        </div> :
                            <div className=''>
                                <div className='flex justify-center pt-5'>
                                    <div className='text-center my-3 h-full w-[75%] max-[1024px]:w-full'>
                                        <h1 className='text-[#000] px-[20px] text-3xl leading-snug font-semibold max-[1024px]:text-center max-[1024px]:!leading-[60px] max-[1024px]:px-0 max-[768px]:text-2xl max-[768px]:!leading-normal'>{blog?.title}</h1>
                                    </div>
                                </div>
                                <div className='flex justify-center lg:h-[600px] my-5 lg:w-[75%] w-full mx-auto'>
                                    <img src={blog?.image} className='h-full w-full object-cover' alt={blog?.image} />
                                </div>
                                <AdResponsive03 />
                                <div className='flex gap-5'>
                                    <div className='lg:w-[70%] w-full'>
                                        <p className='max-[1024px]:mt-5 md:text-[19px] text-[16px] max-[768px]:text-justify' dangerouslySetInnerHTML={{ __html: blog?.description }}></p>
                                    </div>
                                    <div className='lg:w-[30%] lg:block hidden'>
                                        <div className="shadow-2xl rounded-lg border border-[#cacaca]">
                                            <SubBlog blogData={remainingItems} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </> :
                    <div className='h-[80vh] flex justify-center flex-col text-center max-[1024px]:h-[67vh]'>
                        <h1 className='text-[120px] text-black max-[768px]:text-[94px] max-[768px]:mb-0'>404</h1>
                        <h2 className='text-4xl mb-1 text-black font-semibold max-[768px]:text-[25px]'>Ooops... No Blog found!</h2>
                        <div className='flex justify-center mt-3'>
                            <a href='/' className='no-underline'>
                                <button className='text-xl underline font-medium  bg-[#F06939] text-white w-[220px] rounded-md h-[60px] mt-2'>Go Back</button>
                            </a>
                        </div>
                    </div>
            }
            <BackToTop />
        </div>

    )
}

export default BlogAllData