import React from "react";
import layer from "../../assests/Layer_1.png";
import styleImg from "../../assests/image 88.png";
import trendImg from "../../assests/trendImg.png";
import puffer from "../../assests/puffer-tote.png";
import streetStyle from "../../assests/street-style.png";
import weekSpring from "../../assests/week-spring.png";
import perfume from "../../assests/perfume.png";
import health from "../../assests/health.png";
import relatedFashion from "../../assests/relatedFashion.png";
import { otherCelebrityBlogs } from "../CelebrityDetails/data";

const LifeStyle = () => {
  return (
    <div>
      <div>
        <h3
          className="text-[40px] text-center mt-6 font-semibold"
          style={{ fontSize: "clamp(1.5rem, 4vw, 2.8rem)" }}
        >
          Most Stylish Millionaires Ever
        </h3>
        <div className="flex justify-center my-4">
          <img
            src={layer}
            alt=""
            style={{ width: "clamp(150px, 50%, 250px)" }}
          />
        </div>
        <img
          src={styleImg}
          alt=""
          className="h-[350px] md::h-full object-cover"
        />
        <div className="container max-w-7xl mx-auto px-4">
          <div
            className="cursor-pointer"
            onClick={() => (window.location.href = `/view-life-style`)}
          >
            <h3
              className="lg:max-w-[50%] mx-auto text-[40px] text-center mt-10 mb-2 font-semibold"
              style={{
                fontFamily: "Playfair Display",
                fontSize: "clamp(1.5rem, 4vw, 2.9rem)",
              }}
            >
              6 Hair Trends that Must-Try According to Expert
            </h3>
            <img src={trendImg} alt="" />
          </div>
          <div className="">
            <h3
              className="py-3 border-t border-b border-[#C7CACC] text-[45px] text-center mt-10 mb-8 font-semibold"
              style={{
                fontFamily: "Playfair Display",
                fontSize: "clamp(1.5rem, 4vw, 3rem)",
              }}
            >
              Millionaires Fashion Trends
            </h3>
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-5 mt-5">
              {Array(9)
                .fill()
                .map((data, index) => {
                  return (
                    <div className="flex items-center max-[500px]:block">
                      <div
                        className="h-auto max-[500px]:!w-full"
                        style={{ width: "200px" }}
                      >
                        <img src={puffer} alt="" className="h-full w-full" />
                      </div>
                      <p className="ms-4 font-medium md:text-[17px] text-[16px] mt-1 sm:mt-0 sm:w-[calc(100%-230px)] max-[500px]:ms-1">
                        Puffer Tote Bags You'll Love
                      </p>
                    </div>
                  );
                })}
            </div>
            <div>
              <h3
                className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
                style={{
                  fontFamily: "Playfair Display",
                  fontSize: "clamp(1.5rem, 4vw, 3rem)",
                }}
              >
                Street Style
              </h3>
              <div
                className="mt-4 lg:max-w-[70%] mx-auto"
                style={{ fontFamily: "Playfair Display" }}
              >
                {otherCelebrityBlogs
                  ?.filter((celebrity) => celebrity?.category === "life style")
                  ?.map((celebrity, index) => (
                    <React.Fragment key={index}>
                      <div
                        className="cursor-pointer mt-10"
                        onClick={() =>
                          (window.location.href = `/single-blog?blogId=${celebrity.id}`)
                        }
                      >
                        <img src={celebrity.image} alt="" className="mx-auto" />
                        <h3 className="md:text-[30px] text-[22px] text-center">
                          {celebrity.title}
                        </h3>
                      </div>
                    </React.Fragment>
                  ))}

                {/* <div className="mt-5">
                  <img src={weekSpring} alt="" className="mx-auto" />
                  <h3 className="md:text-[30px] text-[22px] text-center mt-3">
                    The best Street style from new york fashion week spring{" "}
                  </h3>
                </div> */}
              </div>
            </div>
            <div>
              <h3
                className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
                style={{
                  fontFamily: "Playfair Display",
                  fontSize: "clamp(2rem, 4vw, 3rem)",
                }}
              >
                Perfumes & Fragrances
              </h3>
              <div>
                <img src={perfume} alt="" className="mx-auto" />
                <h3
                  className="text-[30px] text-center lg:max-w-[70%] mt-2 mx-auto"
                  style={{
                    fontFamily: "Playfair Display",
                    fontSize: "clamp(16px, 4vw, 30px)",
                  }}
                >
                  Sophisticated Vanilla Perfumes That Won't Make You Smell Like
                  a Bakery
                </h3>
              </div>
            </div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-4 mt-20 mb-12">
              {Array(8)
                .fill()
                .map((data, index) => {
                  return (
                    <div className="">
                      <div className="h-auto mx-auto lg:w-[calc(100%-50px)] w-full">
                        <img src={puffer} alt="" className="h-full w-full" />
                      </div>
                      <p className="mx-auto font-medium text-[17px] lg:w-[calc(100%-50px)] w-full mt-2">
                        Puffer Tote Bags You'll Love
                      </p>
                    </div>
                  );
                })}
            </div>
            <div>
              <h3
                className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
                style={{
                  fontFamily: "Playfair Display",
                  fontSize: "clamp(2rem, 4vw, 3rem)",
                }}
              >
                Health & Fitness
              </h3>
              <div className="flex">
                <div className="lg:w-[70%] w-full">
                  <img src={health} alt="" />
                  <h3
                    className="md:text-[30px] text-[22px] text-center mt-3 leading-[37px]"
                    style={{
                      fontFamily: "Playfair Display",
                    }}
                  >
                    This Summer, You Don’t Have to Go to France to Experience
                    the Dior Spa
                  </h3>
                </div>
                <div className="lg:w-[30%] hidden lg:block ms-8">
                  {Array(4)
                    .fill()
                    .map((_, index) => {
                      return (
                        <div className="flex items-center mt-3">
                          <div className="h-[100px] w-[120px]">
                            <img
                              src={relatedFashion}
                              alt=""
                              className="h-full w-full"
                            />
                          </div>
                          <h3 className="ms-4">
                            Nico Parker Is the New Face of Lancôme
                          </h3>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LifeStyle;
