import React from 'react';

const LifeSuccess = ({ data }) => {
    return (
        <div className='container max-w-7xl mx-auto px-7'>
            <h3 className='md:text-[37px] text-[27px] pb-2 font-semibold mt-8 border-b border-[#cacaca]'>{data?.title}</h3>
            <div className='grid md:grid-cols-2 grid-cols-1 gap-6'>
                <div className='md:order-2 order-1 mt-5'>
                    <img src={data?.image} alt='' className='max-w-full min-h-full object-cover' />
                </div>
                <div className='md:order-1 order-2 flex flex-col'>
                    {
                        data?.firstDesc?.map((item, index) => {
                            return <div key={index} className='mt-5'>
                                <b className='md:text-[20px] text-[17px]'>{item?.title}</b>
                                <p className='lg:text-[17px] md:text-[15px] text-[15px] mt-1'>{item?.description}</p>
                            </div>
                        })
                    }
                </div>
            </div>
            {
                data?.secondDesc?.map((item, index) => {
                    return <div className='mt-5' key={index}>
                        <b className='md:text-[20px] text-[17px]'>{item?.title}</b>
                        <p className='lg:text-[17px] md:text-[15px] text-[15px] mt-1'>{item?.description}</p>
                    </div>
                })
            }
        </div>
    );
};

export default LifeSuccess;
