import React, { useEffect, useState } from "react";
import Banner from "../Components/HomeSubPage/Banner";
import Welcome from "../Components/HomeSubPage/Welcome";
import Club from "../Components/HomeSubPage/Club";
import BlogPost from "../Components/HomeSubPage/Blog-Post";
import HappyCus from "../Components/HomeSubPage/Happy_cus";
import YourIdea from "../Components/HomeSubPage/YourIdea";
import ViewIdea from "../Components/HomeSubPage/Idea-View";
import AdResponsive from "../Components/ads/AdResponsive";
import AdResponsive01 from "../Components/ads/AdResponsive01";
// import AdResponsive02 from "../Components/ads/AdResponsive02";
import AdResponsive03 from "../Components/ads/AdResponsive03";
import AdResponsive06 from "../Components/ads/AdResponsive06";
import AdResponsive08 from "../Components/ads/AdResponsive08";
import MillionaireLifeStyle from "../Components/HomeSubPage/millionaire-life-style";
import FAQ from "../Components/HomeSubPage/FAQ";
import BackToTop from "../Components/BackToTop/backToTop";
import SubBlog from "../Components/HomeSubPage/Sub-blog";
import axios from "axios";
import Base_Url from "../api/baseUrl";
import FinanceSteps from "../Components/HomeSubPage/FinanceSteps";
import { BiCoinStack } from 'react-icons/bi'
import { FaBullseye, FaChartBar, FaHandsHelping, FaMoneyBill, FaSyncAlt } from 'react-icons/fa'
import { MdWallet } from 'react-icons/md'
import { TbMoneybag } from 'react-icons/tb'
import { IoIosWallet } from 'react-icons/io';


const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
const Home = () => {
  const stepData = [
    {
      icon: <TbMoneybag className='text-[#F06939] ' />,
      title: "Stay Away From Debt",
      description: "There’s this idea floating around our culture that you have to take big risks to become wealthy......",
      link: "stay-away-from-debt"
    },
    {
      icon: <FaChartBar className='text-[#F06939] ' />,
      title: "Invest Early & Consistently",
      description: "The earlier you start investing, the more likely you are to become a rich.....",
      link: "invest-early"
    }, {
      icon: <MdWallet className='text-[#F06939] ' />,
      title: "Save 15% of Your Income",
      description: "The personal savings rate is the percentage of income left over after people spend money and pay taxes.....",
      link: "save-income"
    }, {
      icon: <BiCoinStack className='text-[#F06939] ' />,
      title: "Make More Money",
      description: "This may be easier said than done. But if you don't make enough to stash 15% of your income.....",
      link: "make-more-money"
    }, {
      icon: <IoIosWallet className='text-[#F06939] ' />,
      title: "Cut Unnecessary Expenses",
      description: "As you work toward becoming a rich, make sure you’re spending your money on purpose and with a purpose.....",
      link: "cut-unnecessary-expenses"
    }, {
      icon: <FaHandsHelping className='text-[#F06939] ' />,
      title: "Get Help If You Need It",
      description: "That's due to the fact that you know you'll need a substantial amount of money when you no longer work.....",
      link: "get-help"
    },
    {
      icon: <FaBullseye className='text-[#F06939] ' />,
      title: "Goal Front And Center",
      description: "The steps to becoming a rich are the opposite of how most people act, which means you’ll see friends and family going places.....",
      link: "goal-front-and-center",
    },
    {
      icon: <FaMoneyBill className='text-[#F06939] ' />,
      title: "Investment Professional",
      description: " If you needed to have heart surgery, would you try to operate on yourself? Of course not.....",
      link: "investment-professional"
    },
    {
      icon: <FaSyncAlt className='text-[#F06939]' />,
      title: "Put Your Plan on Repeat",
      description: "To become a rich, you need to let time and compound growth work their magic. It’s a beautiful thing.....",
      link: "put-your-plan"
    }
  ]
  const [remainingItems, setRemainingItems] = useState([])
  const [sliceData, setSliceData] = useState([])
  const [loading, setLoading] = useState(false)
  const page = 0;
  const limit = 100;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    const allBlogData = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${Base_Url}api/get-blog?limit=${limit}&page=${page + 1}`)
        setLoading(false)
        if (response) {
          const firstThreeItems = response?.data?.data.slice(0, 6);
          setSliceData(firstThreeItems)
          const remainingItems = response?.data?.data.slice(6);
          const fetchedData = remainingItems || [];
          const shuffledData = shuffleArray([...fetchedData]);
          const randomRecords = shuffledData.slice(0, 15);
          setRemainingItems(randomRecords);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching blog data:", error);
      }
    };
    allBlogData();
  }, []);
  return (
    <>
      <section>
        <Banner />
        <MillionaireLifeStyle />
        <AdResponsive />
      </section>
      <div className="container lg:flex mx-auto max-w-[1700px] gap-4 px-7">
        <div className="lg:w-[75%]">
          <section>
            <Welcome />
            <AdResponsive01 />
          </section>
          <section>
            <BlogPost blogData={sliceData} loading={loading} />
          </section>
        </div>
        <div className="lg:w-[25%] h-full lg:block hidden">
            <div className="shadow-2xl rounded-lg border border-[#cacaca] my-16">
              <SubBlog blogData={remainingItems} />
            </div>
            <AdResponsive03 />
            {/* <div className="shadow-2xl rounded-lg h-full border border-[#cacaca] mt-10">
              <div className="h-[300px] w-full bg-[#fff]">
                ads
              </div>
            </div> */}
        </div>
      </div>
      {/* <div className="container flex mx-auto max-w-[1700px] gap-4">
      <div className="w-[75%]"> 
       <section>
        <BlogPost />
      </section>
      </div>
      <div className="w-[25%] my-20 shadow-2xl">
        <section>
          <SubBlog/>
        </section>
      </div>
      </div> */}
      <section>
        <div className="lg:hidden block">
          <AdResponsive03 />
        </div>
        <HappyCus />
      </section>
      <section>
        <AdResponsive06 />
        <ViewIdea />
      </section>
      {/* <div className="container lg:flex mx-auto max-w-[1700px] gap-4 px-7">
        <div className="lg:w-[75%]">
          <section>
        <YourIdea />
          </section>
        </div>
        <div className="lg:w-[25%] my-14 lg:block hidden">
          <section>
            <div className="shadow-2xl rounded-lg border border-[#cacaca] my-16">
              <FinanceSteps stepData={stepData} />
            </div>
          </section>
        </div>
      </div> */}
      <section>
        <YourIdea />
      </section>
      <section>
        <AdResponsive08 />
        <Club stepData={stepData} />

        {/* <AdResponsive02 /> */}
        {/* <section>
            {/* <AdResponsive08 />
            <FAQ />
          </section> */}
      </section>
      <div className="container lg:flex mx-auto max-w-[1700px] gap-4 px-7">
        <div className="lg:w-[75%]">
          <section>
            {/* <AdResponsive08 /> */}
            <FAQ />
          </section>
        </div>
        <div className="lg:w-[25%] my-14 lg:block hidden">
          <section>
            <div className="shadow-2xl rounded-lg border border-[#cacaca] my-16">
              <FinanceSteps stepData={stepData} />
            </div>
          </section>
        </div>
      </div>
      <BackToTop />
    </>
  );
};

export default Home;
