import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// import { otherCelebrityBlogs } from "../CelebrityDetails/data";
import AdResponsive05 from "../../Components/ads/AdResponsive05";
import LongAd from "../../Components/ads/LongAd";
import axios from "axios";
import Base_Url from "../../api/baseUrl";
import { otherCelebrityBlogs } from "../CelebrityDetails/data";
import LeftSideAd from "../../Components/ads/LeftSideAd";
import RightSideAd from "../../Components/ads/RightSideAd";
import AdResponsive01 from "../../Components/ads/AdResponsive01";
import AdResponsive02 from "../../Components/ads/AdResponsive02";
import AdResponsive03 from "../../Components/ads/AdResponsive03";
import AdResponsive04 from "../../Components/ads/AdResponsive04";
import AdResponsive07 from "../../Components/ads/AdResponsive07";
import AdResponsive06 from "../../Components/ads/AdResponsive06";
import AdResponsive08 from "../../Components/ads/AdResponsive08";
import AdResponsive from "../../Components/ads/AdResponsive";

const SingleBlogDatails = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const location = useLocation();
  const blogId = new URLSearchParams(location.search).get("blogId");
  const [blogData, setBlogData] = useState({});

  const adsArray = [
    <AdResponsive />,
    <AdResponsive01 />,
    <AdResponsive02 />,
    <AdResponsive03 />,
    <AdResponsive04 />,
    <AdResponsive06 />,
    <AdResponsive07 />,
    <AdResponsive08 />,
  ];

  useEffect(() => {
    const getAllItemsById = async () => {
      try {
        if (blogId == 7 || blogId == 8) {
          const filterData = otherCelebrityBlogs?.filter(
            (item) => !item.id?.toString().indexOf(blogId.toString())
          );
          setBlogData(filterData[0]);
        } else {
          const response = await axios.get(`${Base_Url}api/category/${blogId}`);
          console.log(response);
          setBlogData(response?.data?.data);
        }
      } catch (error) {}
    };
    getAllItemsById();
  }, [blogId]);

  return (
    <>
      <LeftSideAd />
      <RightSideAd />
      <div className="bg-black text-white pb-[20px]">
        <div className="container mx-auto px-10 lg:px-0">
          <h3
            className="text-center md:text-[38px] text-[30px] font-semibold border-b border-gray-500 pt-8 pb-5 lg:w-[80%] mx-auto"
            style={{ fontFamily: "math" }}
          >
            {blogData?.title}
          </h3>
          <p className="text-center mt-5 lg:max-w-[70%] mx-auto">
            {blogData?.description}
          </p>

          <div className="mt-6 lg:max-w-[70%] mx-auto">
            <LongAd />
            <p className="text-end text-[14px] md:text-[18px] mb-1  mt-10">
              Updated:{" "}
              {new Date(blogData?.created_at)?.toLocaleDateString("en-US", {
                day: "2-digit",
                month: "long",
                year: "numeric",
              })}
            </p>
            <div className="h-[300px] md:h-full mt-1">
              <img
                src={blogData?.image}
                alt={blogData?.title}
                className="h-full object-cover w-[100%]"
              />
            </div>
            <p className="text-center mt-5 my-5 text-sm lg:text-md">
              {blogData?.description2}
            </p>
          </div>
          <AdResponsive05 />
          <div className="">
            <div className="md:px-2 lg:px-4 xl:max-w-[70%] mx-auto">
              {blogData?.categoriesBlogs?.length > 0 ? (
                blogData?.categoriesBlogs?.map((data, index) => {
                  const adComponent = adsArray[index % adsArray.length];
                  return (
                    <>
                      <div key={index} className="md:flex md:mt-20 mt-10">
                        <div className="h-[calc(100vh-10rem)] md:h-full relative md:unset md:flex items-center">
                          <div className="w-full h-full">
                            <img
                              src={data.image}
                              className="w-full h-full"
                              alt=""
                            />
                          </div>
                          <div
                            className="md:mt-0 mt-3 absolute md:relative p-4 md:bottom-4 bottom-0 md:left-4 text-white md:ms-6 w-full"
                            style={{
                              boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.5)",
                              backgroundColor: "rgba(0, 0, 0, 0.5)",
                            }}
                          >
                            <h3
                              className="mb-1 text-[20px] lg:text-[30px]"
                              style={{ fontFamily: "math" }}
                            >
                              {data.title}
                            </h3>
                            <p className="mt-2 md:max-w-[26rem] text-sm">
                              {data.description}
                            </p>
                          </div>
                        </div>
                      </div>
                      {index % 2 !== 0 && (
                        <div key={`ad-${index}`} className="my-10">{adComponent}</div>
                      )}
                    </>
                  );
                })
              ) : (
                <p>No blog details available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleBlogDatails;
